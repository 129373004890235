export enum PersistedDataTypes {
  GET_APPOINTMENT_PERSISTED_DATA = `@persisteddata/GET_APPOINTMENT_PERSISTED_DATA`,
  SET_APPOINTMENT_PERSISTED_DATA = `@persisteddata/SET_APPOINTMENT_PERSISTED_DATA`,
}

export interface IAppointmentPersistedData {
  date: string
  protocolId: string
}

export interface PersistedDataState {
  readonly appointmentPersistedData?: IAppointmentPersistedData
}
