export enum PathParamsTypes {
  GET_APPOINTMENT_PATH_PARAMS = `@pathparams/GET_APPOINTMENT_PATH_PARAMS`,
  SET_APPOINTMENT_PATH_PARAMS = `@pathparams/SET_APPOINTMENT_PATH_PARAMS`,
  GET_VIDEOCALL_REDIRECT_PARAMS = `@pathparams/GET_VIDEOCALL_REDIRECT_PARAMS`,
  SET_VIDEOCALL_REDIRECT_PARAMS = `@pathparams/SET_VIDEOCALL_REDIRECT_PARAMS`,
  GET_PREFIX_PATH_PARAMS = `@pathparams/GET_PREFIX_PARAMS`,
  SET_PREFIX_PATH_PARAMS = `@pathparams/SET_PREFIX_PARAMS`,
  GET_THEME_PARAMS = `@pathparams/GET_THEME_PARAMS`,
  SET_THEME_PARAMS = `@pathparams/SET_THEME_PARAMS`,
  GET_UTM_CAMPAIGN_PARAMS = `@pathparams/GET_UTM_CAMPAIGN_PARAMS`,
  SET_UTM_CAMPAIGN_PARAMS = `@pathparams/SET_UTM_CAMPAIGN_PARAMS`,
}

export interface IVideoCallRedirectParams {
  videocall?: string
  doctorName?: string
  doctorPhoto?: string
}

export interface PathParamsState {
  readonly videocallRedirectParams: IVideoCallRedirectParams
  readonly appointmentPathParams: string
  readonly prefixPathParams: string
  readonly themePathParams: string
  readonly utmCampaignParams: string
}
