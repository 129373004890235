import { Reducer } from 'redux'
import { persistReducer } from 'redux-persist'
import { persistConfig } from 'src/store/ducks/utils/encryptTransform'

import { PersistedDataState, PersistedDataTypes } from './types'

const INITIAL_STATE: PersistedDataState = {
  appointmentPersistedData: undefined,
}

export const reducer: Reducer<PersistedDataState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PersistedDataTypes.SET_APPOINTMENT_PERSISTED_DATA:
      return { ...state, appointmentPersistedData: action.payload }
    default:
      return state
  }
}

export const persistedPersistedData = persistReducer<PersistedDataState>(
  persistConfig('persistedData', undefined, undefined),
  reducer,
)
