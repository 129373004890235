import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, HoursAvailable } from 'src/components'
import { CardSchedule } from 'src/components/CardSchedule/CardSchedule'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { IDataAndTimeAvailableRequest, ISchedules } from 'src/store/ducks/scheduling/types'
import { H2, PSmall } from 'src/styles/commons'
import { invertDate, removeHoursFromDate } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import {
  InterUICalendar,
  InterUIContainer,
  InterUILoading,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const Reschedule: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const dateAndTimeAvailable = useSelector(SchedulingActions.getDateAndTimeAvailable)
  const isLoading = useSelector(LoadingActions.get)

  const scheduleDateInitial = new Date(invertDate(removeHoursFromDate(appointment.appointmentDate)))

  const today = new Date()
  const currentMonth = today.getMonth() + 1
  const currentYear = today.getFullYear()

  const [availableDates, setAvailableDates] = useState([''])
  const [availableDatesAndTime, setAvailableDatesAndTimes] = useState<ISchedules[]>([])
  const [hoursAvailable, setHoursAvailable] = useState<string[]>([])
  const [selectedHour, setSelectedHour] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)
  const [prevRange, setPrevRange] = useState<number>()
  const [nextRange, setNextRange] = useState<number>()

  /**
  Efeito responsável setar o range da navegação dos meses.
  */
  useEffect(() => {
    const scheduleDate = new Date(invertDate(removeHoursFromDate(appointment.appointmentDate)))

    if (scheduleDate.getFullYear() !== currentYear) {
      const resp = currentMonth - (scheduleDate.getMonth() + 1)

      switch (resp) {
        case 11:
          setPrevRange(1)
          setNextRange(1)
          break
        case 10:
          setPrevRange(2)
          setNextRange(0)
          break
        default:
          break
      }
    } else {
      const result = scheduleDate.getMonth() + 1 - currentMonth

      switch (result) {
        case 0:
          setPrevRange(0)
          setNextRange(2)
          break
        case 1:
          setPrevRange(1)
          setNextRange(1)
          break
        case 2:
          setPrevRange(2)
          setNextRange(0)
          break
        default:
          break
      }
    }
  }, [appointment, currentMonth, currentYear])

  /**
  Efeito responsável setar os dias e horários disponíveis do calendário.
  */
  useEffect(() => {
    const dateAvailable: string[] = []

    if (dateAndTimeAvailable.length > 0) {
      dateAndTimeAvailable.map((obj) => dateAvailable.push(obj.date))

      setAvailableDates(dateAvailable)
      setAvailableDatesAndTimes(dateAndTimeAvailable)
    }
  }, [dateAndTimeAvailable])

  /**
  Efeito responsável por exibir os horários disponíveis do calendário.
  Primeiro IF: exibir sempre os horários do dia agendado.
  Segundo IF: exibir os horários da data selecionada.
  */
  useEffect(() => {
    const scheduleDate = removeHoursFromDate(appointment.appointmentDate)
    setSelectedHour('')
    setHoursAvailable([])

    if (selectedDate === '' && scheduleDate) {
      availableDatesAndTime
        .filter((obj) => obj.date === scheduleDate)
        .map((data) => setHoursAvailable(data.times))
    } else {
      availableDatesAndTime
        .filter((obj) => obj.date === selectedDate)
        .map((data) => setHoursAvailable(data.times))
    }
  }, [appointment.appointmentDate, availableDatesAndTime, selectedDate, selectedMonth])

  /**
  Função responsável por controlar os dias disponíveis de
  acordo com a navegação dos meses.
  @param month O mês de referencia.
  */
  const handleNavigation = (date: Date) => {
    setHoursAvailable([])
    setSelectedMonth(date.getMonth() + 1)
    setSelectedDate('')

    const formattedDayMonth = `${`0${date.getMonth() + 1}`.slice(-2)}`
    const lastDay = `${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`
    const todayDay = `${`0${new Date().getDate()}`.slice(-2)}`

    if (date.getMonth() + 1 === currentMonth) {
      const request: IDataAndTimeAvailableRequest = {
        professionalId: appointment.professional.id,
        startDate: `${todayDay}/${formattedDayMonth}/${date.getFullYear()}`,
        endDate: `${lastDay}/${formattedDayMonth}/${date.getFullYear()}`,
        type: appointment.professional.type,
      }
      dispatch(SchedulingActions.getDateAndTimeAvailableRequest(request))
    } else {
      const request: IDataAndTimeAvailableRequest = {
        professionalId: appointment.professional.id,
        startDate: `01/${formattedDayMonth}/${date.getFullYear()}`,
        endDate: `${lastDay}/${formattedDayMonth}/${date.getFullYear()}`,
        type: appointment.professional.type,
      }
      dispatch(SchedulingActions.getDateAndTimeAvailableRequest(request))
    }
  }

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_RESCHEDULING,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  /**
  Função responsável por redirecionar a tela do resumo do reagendamento.
  */
  const onContinue = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_REAGENDCONS_T_CONTINUAR.name, {
      content_id: appointment.professional.specialty,
      content_name: appointment.professional.name,
      data: selectedDate,
      hora: selectedHour,
    })
    dispatch(SchedulingActions.setSelectedDateTime(`${selectedDate} ${selectedHour}`))
    history.push(TypesRoutes.RESCHEDULE_DETAILS)
  }

  const stickyFooter = (
    <Button
      data-testid="button-continue"
      margin="24px"
      disabled={selectedDate === '' || selectedHour === ''}
      onClick={onContinue}
    >
      Continuar
    </Button>
  )

  const loading = (
    <div style={{ height: '212px', display: 'flex', alignItems: 'center' }}>
      <InterUILoading size="ld" />
    </div>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="16px 24px 24px">
        <CardSchedule
          appointmentDetails={appointment}
          showStatus={!appointment.status}
          data-testid={`card-schedule-${appointment}`}
        />
        <H2 margin="24px 0 16px">Escolha uma nova data e horário para sua consulta</H2>
        {prevRange !== undefined && nextRange !== undefined && (
          <InterUICalendar
            availableDates={availableDates}
            prevRange={prevRange}
            nextRange={nextRange}
            date={scheduleDateInitial}
            scheduledDate={(date) => setSelectedDate(date)}
            handleNavigationClick={(date) => handleNavigation(date)}
            isLoading={isLoading}
            loading={loading}
          />
        )}

        {!isLoading && (
          <>
            <PSmall margin="24px 0 8px">Horários disponíveis</PSmall>
            <InterUISeparator
              height="1px"
              margin="0 0 16px"
              style={{ backgroundColor: theme.colors.neutral.grayscale.A200 }}
            />
            {hoursAvailable.length > 0 ? (
              <HoursAvailable
                selectedHour={selectedHour}
                hoursAvailable={hoursAvailable}
                onSelectedHour={(hour) => setSelectedHour(hour)}
              />
            ) : (
              <PSmall>Selecione uma data disponível</PSmall>
            )}
          </>
        )}
      </InterUIContainer>
    </InterUIContainer>
  )
}
