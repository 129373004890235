import { Reducer } from 'redux'

import { TabState, TabTypes } from './types'

const INITIAL_STATE: TabState = {
  selectedTab: 'Clínica',
}

export const reducer: Reducer<TabState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TabTypes.CLINIC:
      return { ...state, selectedTab: 'Clínica' }
    case TabTypes.SCHEDULE:
      return { ...state, selectedTab: 'Agenda' }
    case TabTypes.PROFILE:
      return { ...state, selectedTab: 'Perfil' }
    default:
      return state
  }
}

export default reducer
