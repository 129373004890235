import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { BaseResponseBack } from 'src/config/bridge/BaseService'
import { browserTransactionId } from 'src/mocks/Checkout'
import EmergencyCareService from 'src/services/EmergencyCareService'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { MedicinesActions } from 'src/store/ducks/medicines/actions'
import { PersistedDataActions } from 'src/store/ducks/persistedData/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { SymptomsActions } from 'src/store/ducks/symptoms/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { isStringNotEmpty } from 'src/utils/commons'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { ErrorActions } from '../error/actions'
import { SchedulingActions } from '../scheduling/actions'
import { HealthcareActions } from './actions'
import {
  HealthcareTypes,
  ICancelAttendanceRequest,
  ICreateAttendanceRequest,
  ICreateAttendanceResponse,
  IVideoCall,
} from './types'

function* createAttendance() {
  try {
    yield* put(LoadingActions.show())
    yield* put(HealthcareActions.setProtocolId({} as ICreateAttendanceResponse))

    const subscription = yield* select(SubscriptionActions.getSubscription)
    const selectedSymptoms = yield* select(SymptomsActions.getSelectedSymptoms)
    const selectedOtherSymptoms = yield* select(SymptomsActions.getSelectedOtherSymptoms)
    const selectedMedicines = yield* select(MedicinesActions.getSelectedMedicines)
    const transactionId = yield* select(CheckoutActions.getTransactionId)

    const symptoms: string[] = []
    const medicines: number[] = []

    selectedSymptoms.map((resp) => symptoms.push(resp.description))
    selectedMedicines.map((resp) => medicines.push(resp.id))

    if (selectedOtherSymptoms) symptoms.push(selectedOtherSymptoms)

    const createAttendanceRequest: ICreateAttendanceRequest = {
      patientId: subscription.patientId,
      symptoms,
      medicines,
      professionalType: 'DOCTOR',
      isFreeAppointment: subscription.hasFreeAppointment,
    }

    const response = yield* call(
      EmergencyCareService.createAttendance,
      createAttendanceRequest,
      BaseBridge.isBrowser() ? browserTransactionId.processed : transactionId,
    )

    const data = response.object as ICreateAttendanceResponse

    yield* put(HealthcareActions.setProtocolId(data))

    const dataToPersisted = {
      protocolId: data.protocolId,
      date: new Date().toDateString(),
    }

    yield* put(PersistedDataActions.setAppointmentPersistedData(dataToPersisted))

    if (data) {
      yield* put(HealthcareActions.getAttendanceRequest())
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.createAttendance', undefined, true)
  }
}

function* getAttendance({ payload }: AnyAction) {
  try {
    const spontaneousDemand = yield* select(HealthcareActions.getAttendance)
    const subscription = yield* select(SubscriptionActions.getSubscription)

    if (!spontaneousDemand?.attendance) yield* put(LoadingActions.show())

    const response = yield* call(EmergencyCareService.getAttendance, subscription.patientId)

    if (response.status !== 204) {
      yield* put(HealthcareActions.setAttendance(response.object))

      if (response.object.videocall) {
        yield* put(HealthcareActions.setVideoCallInfos(response.object.videocall))
      }

      redirectThroughSaga(payload)
    } else {
      const errorDetails = {
        title: 'Erro ao buscar atendimento',
        subTitle: 'Não foi possível localizar o atendimento, por favor, tente novamente mais tarde',
        disabledButton: true,
      }

      yield* put(ErrorActions.show(errorDetails))
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.getAttendance', undefined, true)
  }
}

function* cancelAttendance({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { reason } = payload

    const subscription = yield* select(SubscriptionActions.getSubscription)
    const spontaneousDemand = yield* select(HealthcareActions.getAttendance)

    const cancelAttendanceRequest: ICancelAttendanceRequest = {
      patientId: subscription.patientId,
      protocolId: spontaneousDemand.attendance.protocolId,
      reason,
    }

    yield* call(EmergencyCareService.cancelAttendance, cancelAttendanceRequest)

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.cancelAttendance')
  }
}

function* getVideoCallUrl() {
  try {
    const { patientId } = yield* select(SubscriptionActions.getHolder)
    const { professional } = yield* select(SchedulingActions.getSelectedAppointment)

    if (patientId && isStringNotEmpty(patientId.toString())) {
      const response: BaseResponseBack<IVideoCall> = yield* call(
        EmergencyCareService.getVideoCallUrl,
        patientId,
        professional.type,
      )

      if (response.status !== 204 && response.status !== 404) {
        yield* put(
          HealthcareActions.setVideoCallInfos((response as BaseResponseBack<IVideoCall>).object),
        )
      } else {
        yield* put(HealthcareActions.setVideoCallInfos(undefined))
      }
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.getVideoCallUrlRequest')
  }
}

function* redirectToCancel({ payload }: AnyAction) {
  try {
    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.redirectToCancel')
  }
}

function* verifyAttendanceStatus({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    let status: 'FREE' | 'PENDING' | 'N.A.' = 'N.A.'

    const appointmentPersisted = yield* select(PersistedDataActions.getAppointmentPersistedData)
    const subscription = yield* select(SubscriptionActions.getSubscription)

    yield* put(SpecialtiesActions.getSpecialtiesRequest())

    const now = new Date().toDateString()

    if (subscription.hasFreeAppointment) {
      status = 'FREE'
    }

    if (appointmentPersisted && now === appointmentPersisted.date) {
      try {
        const response = yield* call(EmergencyCareService.getAttendance, subscription.patientId)

        yield* put(HealthcareActions.setAttendance(response.object))

        if (response?.object?.videocall) {
          yield* put(HealthcareActions.setVideoCallInfos(response.object.videocall))
        }

        const spontaneousDemands = yield* select(HealthcareActions.getAttendance)

        status =
          spontaneousDemands?.attendance?.protocolId === appointmentPersisted?.protocolId
            ? 'PENDING'
            : status
      } catch (error) {
        if ((error as { status: number }).status !== 404) {
          Promise.reject(error)
        }
      }
    }

    yield* put(HealthcareActions.setAttendanceStatus(status))

    yield* put(LoadingActions.hide())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HealthcareActions.verifyAttendanceStatus')
  }
}

export default function* watchHealthcare() {
  yield* takeLatest(HealthcareTypes.CREATE_ATTENDANCE, createAttendance)
  yield* takeLatest(HealthcareTypes.GET_ATTENDANCE_REQUEST, getAttendance)
  yield* takeLatest(HealthcareTypes.CANCEL_ATTENDANCE, cancelAttendance)
  yield* takeLatest(HealthcareTypes.GET_VIDEO_CALL_URL_REQUEST, getVideoCallUrl)
  yield* takeLatest(HealthcareTypes.SET_REDIRECT_TO_CANCEL_ATTENDANCE, redirectToCancel)
  yield* takeLatest(HealthcareTypes.VERIFY_ATTENDANCE_STATUS, verifyAttendanceStatus)
}
