import { Reducer } from 'redux'

import {
  HealthcareState,
  HealthcareTypes,
  ISpontaneousDemands,
  ICreateAttendanceResponse,
  RedirectToCancel,
} from './types'

const INITIAL_STATE: HealthcareState = {
  protocolId: {} as ICreateAttendanceResponse,
  spontaneousDemands: {} as ISpontaneousDemands,
  videoCall: undefined,
  isAppointmentReady: false,
  redirectToCancel: {} as RedirectToCancel,
  attendanceStatus: 'N.A.',
}

const reducer: Reducer<HealthcareState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HealthcareTypes.SET_QUEUE_ID:
      return { ...state, protocolId: action.payload }
    case HealthcareTypes.SET_ATTENDANCE:
      return { ...state, spontaneousDemands: action.payload }
    case HealthcareTypes.SET_VIDEO_CALL_URL:
      return { ...state, videoCall: action.payload }
    case HealthcareTypes.SET_APPOINTMENT_READY:
      return { ...state, isAppointmentReady: action.payload }
    case HealthcareTypes.SET_REDIRECT_TO_CANCEL_ATTENDANCE:
      return { ...state, redirectToCancel: action.payload }
    case HealthcareTypes.SET_ATTENDANCE_STATUS:
      return { ...state, attendanceStatus: action.payload }

    default:
      return state
  }
}

export default reducer
