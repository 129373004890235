export const TAGS = {
  C_DOUTORINTER_HOME_DC_ACESSO: { id: 0, name: 'C_DoutorInter_Home_DC_Acesso' },
  C_DOUTORINTER_HOME_T_ESPECIALIDADE: { id: 1, name: 'C_DoutorInter_Home_T_Especialidade' },
  C_DOUTORINTER_HOME_T_BABI: { id: 2, name: 'C_DoutorInter_Home_T_Babi' },
  C_DOUTORINTER_HOME_T_ASSINAR: { id: 3, name: 'C_DoutorInter_Home_T_Assinar' },
  C_DOUTORINTER_CONTRATACAO_T_DETALHES: { id: 4, name: 'C_DoutorInter_Contratacao_T_Detalhes' },

  C_DOUTORINTER_CONTRATACAO_T_PAGAMENTO: { id: 6, name: 'C_DoutorInter_Contratacao_T_Pagamento' },
  C_DOUTORINTER_CONTRATACAO_T_MANUAL: { id: 7, name: 'C_DoutorInter_Contratacao_T_Manual' },

  C_DOUTORINTER_CONTRATACAO_T_CONTRATAR: { id: 11, name: 'C_DoutorInter_Contratacao_T_Contratar' },

  C_DOUTORINTER_COMPROVANTE_DC_SUCESSO: { id: 13, name: 'C_DoutorInter_Comprovante_DC_Sucesso' },
  C_DOUTORINTER_COMPROVANTE_T_OPCAO: { id: 14, name: 'C_DoutorInter_Comprovante_T_Opcao' },

  C_DOUTORINTER_HOME_T_OPCOES: { id: 16, name: 'C_DoutorInter_Home_T_Opcoes' },
  C_DOUTORINTER_HOME_T_SAIBAMAIS: { id: 17, name: 'C_DoutorInter_Home_T_SaibaMais' },
  C_DOUTORINTER_HOME_T_CLINICAGERAL: { id: 18, name: 'C_DoutorInter_Home_T_ClinicaGeral' },

  C_DOUTORINTER_HOME_T_AGENDARCONSULTA: { id: 19, name: 'C_DoutorInter_Home_T_AgendarConsulta' },

  C_DOUTORINTER_HOME_T_FAQ: { id: 21, name: 'C_DoutorInter_Home_T_FAQ' },

  C_DOUTORINTER_HOME_T_CONSULTA: { id: 32, name: 'C_DoutorInter_Home_T_Consulta' },
  C_DOUTORINTER_SINTOMA_T_INFORMARSINTOMA: {
    id: 33,
    name: 'C_DoutorInter_Sintoma_T_InformarSintoma',
  },
  C_DOUTORINTER_SINTOMAS_T_CONTINUAR: {
    id: 34,
    name: 'C_DoutorInter_Sintomas_T_Continuar',
  },
  C_DOUTORINTER_OUTROSSINTOMAS_T_CONTINUAR: {
    id: 35,
    name: 'C_DoutorInter_OutrosSintomas_T_Continuar',
  },
  C_DOUTORINTER_MEDICAMENTO_T_NAOINFOMAR: {
    id: 36,
    name: 'C_DoutorInter_Medicamento_T_NaoInfomar',
  },
  C_DOUTORINTER_MEDICAMENTO_T_CONTINUAR: {
    id: 37,
    name: 'C_DoutorInter_Medicamento_T_Continuar',
  },

  C_DOUTORINTER_MEDICAMENTO_T_REMOVERMED: {
    id: 38,
    name: 'C_DoutorInter_Medicamento_T_RemoverMed',
  },

  C_DOUTORINTER_ONBOARDING_T_PULAR: {
    id: 41,
    name: 'C_DoutorInter_Onboarding_T_Pular',
  },
  C_DOUTORINTER_ONBOARDING_T_PROXIMO: {
    id: 42,
    name: 'C_DoutorInter_Onboarding_T_Proximo',
  },
  C_DOUTORINTER_TEMPODEESPERA_T_OPCAO: {
    id: 43,
    name: 'C_DoutorInter_TempoDeEspera_T_Opcao',
  },
  C_DOUTORINTER_FILADEESPERA_T_CANCELARPA: {
    id: 44,
    name: 'C_DoutorInter_FilaDeEspera_T_CancelarPA',
  },
  C_DOUTORINTER_PRECONSULTA_DC_ACESSO: {
    id: 45,
    name: 'C_DoutorInter_PreConsulta_DC_Acesso',
  },
  C_DOUTORINTER_PRECONSULTA_T_ENTRNACHAM: {
    id: 46,
    name: 'C_DoutorInter_PreConsulta_T_EntrNaCham',
  },
  C_DOUTORINTER_CANCELAMENTO_T_CANCELARPA: {
    id: 47,
    name: 'C_DoutorInter_Cancelamento_T_CancelarPA',
  },
  C_DOUTORINTER_CANCELMOTIVO_T_CANCELAR: {
    id: 48,
    name: 'C_DoutorInter_CancelMotivo_T_Cancelar',
  },
  C_DOUTORINTER_COMPROVANTECANCEL_T_OPCAO: {
    id: 49,
    name: 'C_DoutorInter_ComprovanteCancel_T_Opcao',
  },
  C_DOUTORINTER_CONSAGENDADA_T_OPCAO: {
    id: 56,
    name: 'C_DoutorInter_ConsAgendada_T_Opcao',
  },
  C_DOUTORINTER_REAGENDCONS_T_CONTINUAR: {
    id: 57,
    name: 'C_DoutorInter_ReagendCons_T_Continuar',
  },
  C_DOUTORINTER_RESUMREAGEND_T_CONFIRMAR: {
    id: 58,
    name: 'C_DoutorInter_ResumReagend_T_Confirmar',
  },
  C_DOUTORINTER_CANCELAMENT_T_CANCELAR: {
    id: 59,
    name: 'C_DoutorInter_Cancelament_T_Cancelar',
  },
  C_DOUTORINTER_COMPROVCANCEL_T_OPCAO: {
    id: 60,
    name: 'C_DoutorInter_ComprovCancel_T_Opcao',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_DC_CANCELAR: {
    id: 64,
    name: 'C_DoutorInter_Assinatura_Cancel_DC_Cancelar',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_MANTER: {
    id: 65,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Manter',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_CANCELAR: {
    id: 66,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Cancelar',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_MOTIVO: {
    id: 67,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Motivo',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_CONTINUAR: {
    id: 68,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Continuar',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_FINALIZAR: {
    id: 69,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Finalizar',
  },
  C_DOUTORINTER_REDE_CONVENIADA_T_MANUAL: {
    id: 70,
    name: 'C_DoutorInter_Rede_Conveniada_T_Manual',
  },
  C_DOUTORINTER_PAGAMENTOPA_T_SELECIONAR: {
    id: 71,
    name: 'C_DoutorInter_PagamentoPA_T_Selecionar',
  },
  C_DOUTORINTER_PAGAMENTOPA_T_SELECIONADO: {
    id: 72,
    name: 'C_DoutorInter_PagamentoPA_T_Selecionado',
  },
  C_DOUTORINTER_VIDEOCHAMADA_T_BOTTOMSHEET: {
    id: 73,
    name: 'C_DoutorInter_VideoChamada_T_BottomSheet',
  },
  C_DOUTORINTER_ANEXO_T_NAOINFOMAR: {
    id: 74,
    name: 'C_DoutorInter_Anexo_T_NaoInfomar',
  },
  C_DOUTORINTER_ANEXO_T_CONTINUAR: {
    id: 75,
    name: 'C_DoutorInter_Anexo_T_Continuar',
  },
  C_DOUTORINTER_RESETSESSION_T_RESET: {
    id: 76,
    name: 'C_DoutorInter_ResetSession_T_Reset',
  },
  C_DOUTORINTER_ASSINATURA_CANCEL_T_MULTA_PGTO: {
    id: 77,
    name: 'C_DoutorInter_Assinatura_Cancel_T_Multa_Pgto',
  },
  C_DOUTORINTER_CONSAGENDADA_PAGAMENTO_T_SELECIONAR: {
    id: 78,
    name: 'C_DoutorInter_ConsAgendada_Pagamento_T_Selecionar',
  },
  C_DOUTORINTER_CONSAGENDADA_PAGAMENTO_T_SELECIONADO: {
    id: 79,
    name: 'C_DoutorInter_ConsAgendada_Pagamento_T_Selecionado',
  },
}

export enum TaggingEnum {
  C_DOUTORINTER_PAYMENT_BUTTON = 'C_DoutorInter_Botao_Pagamento',
  C_DOUTORINTER_PAYMENT_OPEN_WALLET = 'C_DoutorInter_Open_Wallet',
  C_DOUTORINTER_PAYMENT_OPEN_WALLET_RESPONSE = 'C_DoutorInter_Open_Wallet_Response',
  C_DOUTORINTER_VIDEOCALL_URL = 'C_DoutorInter_Videocall_URL',
  C_DOUTORINTER_INVALID_VIDEOCALL_URL = 'C_DoutorInter_Invalid_Videocall_URL',
  C_DOUTORINTER_MAINTENANCE_ACCESS = 'C_DoutorInter_Maintenance_Access',
}
