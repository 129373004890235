export const reasonCancellation = [
  { reason: 'Tenho outro compromisso' },
  { reason: 'Marquei uma consulta presencial' },
  { reason: 'Agendei por engano' },
  { reason: 'Fiz um plano de saúde' },
  { reason: 'Encontrei uma consulta online mais barata' },
  { reason: 'Encontrei um horário antecipado' },
  { reason: 'Outros' },
]

export const reasonCancellationSubscription = [
  { reason: 'Não utilizo mais' },
  { reason: 'Problemas para agendar consultas' },
  { reason: 'Não gostei de fazer consultas online' },
  { reason: 'Não encontrei a especialidade que preciso' },
  { reason: 'Fiz um plano de saúde' },
  { reason: 'Encontrei uma consulta online mais barata' },
  { reason: 'Outros' },
]
