import styled from 'styled-components'

export const ContainerWaitingPayment = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`
export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary.A100};
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: 0 auto 24px;
`
