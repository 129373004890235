import React from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { ContainerFullPage } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { TAGS } from 'src/enums/TaggingEnum'

import { InterUIWizard } from '@interco/inter-ui-react-lib'

import { ContentWizard, Image } from './PrepareConsult.styles'

export const PrepareConsult: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const theme = useTheme()

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    hideHomeBtn: true,
    hideBackNavigationBtn: true,
  })

  const iconNetwork = (
    <Image>
      <Icons.Network width={32} height={32} color={theme.colors.primary.A500} />
    </Image>
  )
  const iconHeart = (
    <Image>
      <Icons.Heart width={32} height={32} color={theme.colors.primary.A500} />
    </Image>
  )
  const iconSmartphone = (
    <Image>
      <Icons.Smartphones width={32} height={32} color={theme.colors.primary.A500} />
    </Image>
  )

  const wizardData = [
    {
      icon: iconNetwork,
      title: 'Confira sua conexão',
      description: `Verifique a qualidade da sua internet e, se possível, utilize wi-fi.`,
    },
    {
      icon: iconHeart,
      title: 'Seja gentil',
      description: `Acompanhe com atenção todas orientações na sua consulta e use roupas adequadas.`,
    },
    {
      icon: iconSmartphone,
      title: 'Use câmera e microfone',
      description: `Permita acesso à câmera e ao microfone e use fones de ouvido durante a consulta.`,
    },
  ]

  const callBack = (button: string) => {
    button === 'Proximo'
      ? BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ONBOARDING_T_PROXIMO.name)
      : BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ONBOARDING_T_PULAR.name)
    dispatch(HealthcareActions.createAttendance())
    history.push(TypesRoutes.WAITING_TIME)
  }

  return (
    <ContainerFullPage height="0px">
      <ContentWizard>
        <InterUIWizard
          slides={wizardData}
          finishCb={() => callBack('Proximo')}
          skipCb={() => callBack('Pular')}
          type="moveOn"
          skipHorizontal="24px"
          skipVertical="16px"
        />
      </ContentWizard>
    </ContainerFullPage>
  )
}
