import InterWebviewBridge from '@interco/inter-webview-bridge'

interface ContractWalletRequest {
  transactionType: TransactionType
  walletLibraryPaymentMethodResult: OpenWalletResponseAndroid | OpenWalletResponseIOS
}

interface OpenWalletRequest {
  walletClientId: string
  amount: string
  additionalFields: Record<string, string>
  additionalQueryRequestParams: Record<string, string>
}
export class CheckoutBridge {
  /**
   * Método para abertura da escolha de forma de pagamento via bridge.
   * @param walletClientId identificador único do produto junto ao wallet.
   * @param amount valor em centavos.
   * @param additionalFields campo contendo informações adicionais, chave|valor
   * @returns informações referentes ao método de pagamento selecionado.
   */
  static async openWalletMethod(
    walletClientId: string,
    amount: string,
    additionalFields: Record<string, string>,
  ): Promise<OpenWalletResponseAndroid | OpenWalletResponseIOS> {
    const payload: OpenWalletRequest = {
      walletClientId,
      amount,
      additionalFields,
      additionalQueryRequestParams: additionalFields,
    }

    const iWb = InterWebviewBridge.getInstance()

    try {
      return await iWb.execute({
        action: 'openWallet',
        metadata: payload,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Método responsavel por finalizar a contratação via bridge.
   * @param openWalletResult dados do retorno do open wallet method.
   * @param transactionType indetificador do tipo de transação do produto.
   * @returns dados referentes a transação junto ao wallet.
   */
  static async contractWallet(
    openWalletResult: OpenWalletResponseAndroid | OpenWalletResponseIOS,
    transactionType: TransactionType,
  ): Promise<ContractWalletResponseAndroid | ContractWalletResponseIOS> {
    if (openWalletResult === undefined || openWalletResult === null)
      throw new Error('O campo openWalletResult não pode ser nulo.')

    const request: ContractWalletRequest = {
      transactionType,
      walletLibraryPaymentMethodResult: openWalletResult,
    }

    const iWb = InterWebviewBridge.getInstance()

    try {
      return await iWb.execute({
        action: 'contractWallet',
        metadata: request,
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export interface OpenWalletResponseAndroid {
  selectedPaymentMethod: WalletLibrarySelectedPaymentMethodResult
  additionalFields: Record<string, string>
  token?: string
  walletClientId: string
  checkoutData: {
    amount: number
    installments: number
    cardAccount?: string
    type: string
    id: string
    card?: {
      number?: string
      lastFourDigits?: string
      holderName?: string
    }
  }
  clearSaleSessionId: string
}

export interface OpenWalletResponseIOS {
  method: {
    title: string
    firstLine: string
    secondLine: string
    thirdLine: string
    type: string
  }
}

export interface ContractWalletResponseAndroid {
  walletLibraryCheckoutResult: {
    walletPaymentId: string
    walletClientId: string
    message: string
    additionalFields: Record<string, string>[]
  }
  error: string
  status: 'SUCCESS' | 'FAILURE'
}

export interface ContractWalletResponseIOS {
  response: {
    message: string
    additionalFields: Record<string, string>
    walletPaymentId: string
    clientTransactionId: string
  }
  error: string
  status: 'SUCCESS' | 'FAILURE'
}

export interface WalletLibraryInfoResult {
  image: string
  title: string
  subTitle: string
  details?: {
    title: string
    description: string
  }
}

export interface WalletLibrarySelectedPaymentMethodResult {
  id: string
  paymentMethodTitle: string
  type: string
  displayableType?: string
  cardToken?: string
  firstLine?: string
  icon?: string
  amount: string
  installment?: {
    title?: string
    number: string
    type: string
    amountInstallments: number
    description: string
    amountTotal: number
    descriptionDiscount: string
    header?: {
      amount: number
      amountHtml: string
      descriptionAmount: string
      descriptionBenefit?: string
    }
    details?: {
      notes: {
        title: string
        subtitle: string
      }
      additionalFields: Record<string, string>[]
    }
  }
  secondLine?: string
  thirdLine?: string
  walletLibraryPaymentMethodResultCheckoutData?: {
    type?: string
    id?: string
    card: {
      number?: string
      lastFourDigits?: string
      holderName?: string
    }
  }
  benefits?: {
    type: string
    color: string
    title?: string
    additionalBodyFields: Record<string, unknown>
    analyticsEvents: {
      name: string
      attributes: Record<string, unknown>
    }[]
    informativeBox?: WalletLibraryInfoResult
    informativePopup?: WalletLibraryInfoResult
    eventPopup?: WalletLibraryInfoResult
  }
  card?: {
    token?: string
    number: string
    lastFourDigits: string
    holderName?: string
    expiration?: string
    securityCode?: string
    holderDocument?: string
    brand?: string
  }
}

/**
 * Interface contendo os valores de identicação da transação para cada produto.
 * O campo 'value' deve conter o valor da transação em Reais.
 *
 * Exemplo:
 *    code: 'XX',
 *    checkType: X,
 *    description: 'AUTORIZACAO_XXXXXX',
 *    value: 4.9
 */
export interface TransactionType {
  code: string
  checkType: number
  description: string
  value: number
}

export default CheckoutBridge
