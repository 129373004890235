import styled from 'styled-components'

export const ContentWizard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  /**
  padding do interUiWizard: 40px
  altura dos botões na tela de waitingTime: 136px
  */
  height: calc(100% - 176px);
`
export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary.A100};
  width: 64px;
  height: 64px;
  margin: auto;
  border-radius: 50%;
`
