import React from 'react'

import { SkeletonBox, SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadSmallListDescription: React.FC = () => (
  <SkeletonBox>
    <SkeletonGrid>
      <InterUISkeleton width="24px" height="24px" />
      <InterUISkeleton width="calc(100% - 80px)" />
      <InterUISkeleton width="24px" height="24px" />
    </SkeletonGrid>
  </SkeletonBox>
)
