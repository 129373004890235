import React from 'react'

import { ContainerFullPage, H1, PMedium } from 'src/styles/commons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUILoadingDots } from '@interco/inter-ui-react-lib'

import { ContainerWaitingPayment, Image } from './WaitingPayment.style'

export const WaitingPayment: React.FC = () => {
  const theme = useTheme()

  const loading = (
    <InterUILoadingDots
      isLoading
      bgColor="transparent"
      colorDots={theme.colors.primary.A500}
      widthDot="8px"
      heightDot="8px"
      heightAnimation="-5px"
      dotLeft="20px"
      dotCenter="35px"
      dotRight="50px"
    />
  )

  return (
    <InterUIContainer margin="0 24px">
      <ContainerFullPage height="0px">
        <ContainerWaitingPayment>
          <Image>{loading}</Image>
          <H1 textAlign="center">Estamos quase lá!</H1>
          <PMedium scale={400} margin="8px 0 16px" textAlign="center">
            Aguarde só um momento enquanto o pagamento está sendo realizado
          </PMedium>
        </ContainerWaitingPayment>
      </ContainerFullPage>
    </InterUIContainer>
  )
}
