export enum SubscriptionTypes {
  CREATE_SUBSCRIPTION = `@subscription/CREATE_SUBSCRIPTION`,
  GET_SUBSCRIPTION_REQUEST = `@subscription/GET_SUBSCRIPTION_REQUEST`,
  GET_SUBSCRIPTION_STATUS_REQUEST = `@subscription/GET_SUBSCRIPTION_STATUS_REQUEST`,
  GET_SUBSCRIPTION = `@subscription/GET_SUBSCRIPTION`,
  SET_SUBSCRIPTION = `@subscription/SET_SUBSCRIPTION`,
  GET_HOLDER = `@subscription/GET_HOLDER`,
  SET_HOLDER = `@subscription/SET_HOLDER`,
  GET_PRICES = `@subscription/GET_PRICES`,
  SET_PRICES = `@subscription/SET_PRICES`,
  GET_PRICES_REQUEST = `@subscription/GET_PRICES_REQUEST`,
  GET_TRANSACTION_STATUS = `@subscription/GET_TRANSACTION_STATUS`,
  CANCEL_SUBSCRIPTION = `@subscription/CANCEL_SUBSCRIPTION`,
  GET_REASON_CANCEL_SUBSCRIPTION = `@subscription/GET_REASON_CANCEL_SUBSCRIPTION`,
  SET_REASON_CANCEL_SUBSCRIPTION = `@subscription/SET_REASON_CANCEL_SUBSCRIPTION`,
  ACTIVATE_SUBSCRIPTION = `@subscription/ACTIVATE_SUBSCRIPTION`,
  GET_CANCELLATION_FEE_REQUEST = `@subscription/GET_CANCELLATION_FEE_REQUEST`,
  GET_CANCELLATION_FEE = `@subscription/GET_CANCELLATION_FEE`,
  SET_CANCELLATION_FEE = `@subscription/SET_CANCELLATION_FEE`,
  GET_TERMS_AND_CONDITIONS = `@subscription/GET_TERMS_AND_CONDITIONS`,
}

export interface IHolder {
  name: string
  socialId?: string
  birthdate: string
  email: string
  phone: string

  patientId?: number
  holderPatientId?: number
  status?: string
}

export interface ISubscription {
  subscriptionId: string
  patientId: number
  socialId: string
  name: string
  birthdate: string
  email: string
  phone: string
  customerStatus: string
  subscriptionStatus: string
  paymentAmount: string
  paymentDueDate: string
  hasFreeAppointment: boolean
  termsAndConditions: {
    accepted: boolean
    acceptanceDateTime: string
  }
  creationDate: string
}

export interface IPrices {
  price: string
}

export interface ICreateSubscriptionRequest {
  name: string
  birthdate: string
  email: string
  phone: string
  paymentAmount: string
}

export interface ICreateSubscriptionResponse {
  subscriptionId: string
  name: string
  birthdate: string
  email: string
  phone: string
  customerStatus: string
  subscriptionStatus: string
  paymentAmount: string
  hasFreeAppointment: boolean
  termsAndConditions: {
    accepted: boolean
    acceptanceDateTime: string
  }
  creationDate: string
}

export interface ITransactionStatusRequest {
  transactionId: string
  onTransmission: (status: string) => void
}

export interface ISubscriptionStatusRequest {
  onTransmission: (status: string) => void
}

export interface ITransactionStatusResponse {
  transactionStatus: 'PROCESSING' | 'PROCESSED' | 'PENDING' | 'DENIED'
}

export interface IGetSubscriptionRequest {
  onTransmission: (status: string, acceptedTerms: boolean) => void
}

export interface ICancelSubscriptionRequest {
  reason: string
  withRefund: boolean
}

export interface ICancellationFeeResponse {
  subscriptionCancellationFee: ICancellationFee
}
export interface ICancellationFee {
  hasCancellationFee: boolean
  cancellationAmount: string
  withRefund: boolean
}

export interface SubscriptionState {
  readonly holder: IHolder
  readonly prices: IPrices
  readonly subscription: ISubscription
  readonly reasonCancellation: string
  readonly cancellationFee: ICancellationFee
}
