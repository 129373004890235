import { Reducer } from 'redux'
import { ThemesEnum } from 'src/enums/ThemesEnum'

import { InterTheme } from '@interco/inter-ui-react-lib'

import { ThemeState, ThemeTypes } from './types'

const INITIAL_STATE: ThemeState = {
  theme: InterTheme(ThemesEnum.LIGHT),
}

const reducer: Reducer<ThemeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ThemeTypes.SET_THEME:
      return { ...state, theme: action.payload }

    default:
      return state
  }
}

export default reducer
