export enum MedicinesTypes {
  GET_MEDICINES_LIST_REQUEST = `@medicines/GET_MEDICINES_LIST_REQUEST`,
  GET_MEDICINES_LIST = `@medicines/GET_MEDICINES_LIST`,
  SET_MEDICINES_LIST = `@medicines/SET_MEDICINES_LIST`,
  GET_SELECTED_MEDICINES = `@medicines/GET_SELECTED_MEDICINES`,
  SET_SELECTED_MEDICINES = `@medicines/SET_SELECTED_MEDICINES`,
}

export interface IMedicinesResponse {
  medicines: IMedicineFields[]
}

export interface IMedicineFields {
  id: number
  name: string
}

export interface IMedicine {
  id: number
  name: string
}

export interface ISearchMedicineRequest {
  medicine: string
}

export interface MedicinesState {
  readonly medicinesList: IMedicineFields[]
  readonly selectedMedicines: IMedicine[]
}
