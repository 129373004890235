import storage from 'redux-persist/lib/storage'
import { PersistConfig } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'

const encryptTransformation = encryptTransform({
  secretKey: 'dr_inter-cabralian-key',
})

export const persistConfig = <T>(
  key: string,
  whitelist?: string[],
  blacklist?: string[],
): PersistConfig<T> => ({
  key,
  storage,
  transforms: [encryptTransformation],
  whitelist,
  blacklist,
})
