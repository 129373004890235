import { Reducer } from 'redux'

import { DocumentState, DocumentTypes, RedirectToDocument, GenericDocumentResponse } from './types'

const INITIAL_STATE: DocumentState = {
  productManual: {} as GenericDocumentResponse,
  termsAndConditions: {} as GenericDocumentResponse,
  privacyPolicy: {} as GenericDocumentResponse,
  redirectToDocument: {} as RedirectToDocument,
}

const reducer: Reducer<DocumentState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DocumentTypes.SET_PRODUCT_MANUAL_PDF:
      return { ...state, productManual: action.payload }

    case DocumentTypes.SET_TERMS_AND_CONDITIONS_PDF:
      return { ...state, termsAndConditions: action.payload }

    case DocumentTypes.SET_PRIVACY_POLICY_PDF:
      return { ...state, privacyPolicy: action.payload }

    case DocumentTypes.SET_REDIRECT_TO_DOCUMENT:
      return { ...state, redirectToDocument: action.payload }

    case DocumentTypes.DELETE_DOCUMENT:
      return { ...state, productManual: {}, termsAndConditions: {} }

    default:
      return state
  }
}

export default reducer
