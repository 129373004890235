import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { H2, H3, PMedium, PSmall } from 'src/styles/commons'
import { isStringNotEmpty } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUILoadingDots } from '@interco/inter-ui-react-lib'

import {
  BoxBallon,
  ContainerImg,
  Doctor,
  DoctorImg,
  InformationBallon,
  PositionLoading,
} from './PreAppointment.styles'

interface PreAppointmentProps {
  goToAppointment: () => void
  canStart: (doctorName: string, doctorPhoto?: string) => void
}

export const PreAppointment: React.FC<PreAppointmentProps> = ({ goToAppointment, canStart }) => {
  const theme = useTheme()

  const isAppointmentReady = useSelector(HealthcareActions.isAppointmentReady)
  const appointmentType = useSelector(SchedulingActions.getAppointmentType)
  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const doctorDetails = useSelector(SchedulingActions.getDoctorDetails)
  const videoCallParams = useSelector(PathParamsActions.getVideoCallRedirectParams)
  const iconGeneralPractitioner = (
    <Icons.GeneralPractitioner width={72} height={72} color={theme.colors.primary.A500} />
  )

  const [ready, setReady] = useState(false)
  const [showButton, setShowButton] = useState(true)

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_PRECONSULTA_DC_ACESSO.name)
  }, [])

  useEffect(() => {
    if (videoCallParams.videocall) {
      setShowButton(false)
      setReady(true)
    }
  }, [videoCallParams.videocall])

  useEffect(() => {
    if (isAppointmentReady) {
      setReady(true)

      setTimeout(() => {
        goToAppointment()
      }, 1500)
    }
  }, [goToAppointment, isAppointmentReady])

  const getDoctorName = () => {
    let name: string

    if (videoCallParams.doctorName) {
      name = videoCallParams.doctorName
    } else if (appointmentType.type === 'SCHEDULED') {
      name = appointment.professional?.name
    } else {
      name = doctorDetails?.name
    }

    if (!name) {
      name = 'Médico(a) Conveniado(a)'
    } else {
      name = name.toLocaleLowerCase()
    }

    return name
  }

  const getDoctorPhoto = () => {
    let photo: string | undefined

    if (isStringNotEmpty(videoCallParams.doctorPhoto)) {
      photo = videoCallParams.doctorPhoto
    } else if (isStringNotEmpty(doctorDetails.photo)) {
      photo = doctorDetails.photo
    }

    return photo
  }

  const initLoadIframe = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_PRECONSULTA_T_ENTRNACHAM.name)

    setShowButton(false)
    canStart(getDoctorName(), getDoctorPhoto())
  }

  const stickyFooter = (
    <>
      {showButton && (
        <Button margin="24px" data-testid="join-call-button" onClick={initLoadIframe}>
          Entrar na consulta
        </Button>
      )}
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InformationBallon hidden={ready}>
        <BoxBallon>
          <PSmall marginBottom="4px" bold>
            {getDoctorName()}
          </PSmall>
          <PSmall margin="0px" scale={400}>
            {appointmentType.type === 'SCHEDULED'
              ? appointment.professional.specialty
              : doctorDetails.specialty || 'Clínico Geral'}
          </PSmall>
        </BoxBallon>
      </InformationBallon>

      <ContainerImg>
        <Doctor>
          {getDoctorPhoto() ? (
            <DoctorImg src={getDoctorPhoto()} alt="Doutor aguardando paciente" />
          ) : (
            iconGeneralPractitioner
          )}
        </Doctor>

        <PositionLoading>
          <InterUILoadingDots bgColor={theme.colors.primary.A100} isLoading={!ready} />
        </PositionLoading>
      </ContainerImg>

      <InterUIContainer>
        {ready ? (
          <H3 margin="24px auto 16px" textAlign="center">
            <span
              style={{
                textTransform: getDoctorName().includes('Conveniada') ? 'none' : 'capitalize',
              }}
            >
              {getDoctorName()}
            </span>
            {` está pronto para iniciar sua consulta`}
          </H3>
        ) : (
          <H2 margin="24px auto 16px" textAlign="center">
            Sua consulta começará em breve com a Conexa
          </H2>
        )}
        {!ready ? (
          <>
            <PMedium marginBottom="24px" scale={400} textAlign="center">
              Já recebemos suas informações.
            </PMedium>
          </>
        ) : (
          <PMedium scale={400} textAlign="center">
            Sua consulta vai começar agora...
          </PMedium>
        )}
      </InterUIContainer>
    </InterUIContainer>
  )
}
