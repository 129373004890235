import styled from 'styled-components'

export const ContentWaitingLoading = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > {
    :first-child {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: calc(100% - 150px);
      flex: none;

      > :first-child {
        margin: 0 auto 24px;
      }
    }

    :last-child {
      flex: none;
      margin: auto 0 24px;
    }
  }
`
