import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { IVideoCallRedirectParams, PathParamsTypes } from './types'

const getVideoCallRedirectParams = (state: RootState) => state.pathparams.videocallRedirectParams

const setVideoCallRedirectParams = (data: IVideoCallRedirectParams) =>
  action(PathParamsTypes.SET_VIDEOCALL_REDIRECT_PARAMS, data)

const getAppointmentPathParams = (state: RootState) => state.pathparams.appointmentPathParams

const setAppointmentPathParams = (data: string) =>
  action(PathParamsTypes.SET_APPOINTMENT_PATH_PARAMS, data)

const getPrefixPathParams = (state: RootState) => state.pathparams.prefixPathParams

const setPrefixPathParams = (data: string) => action(PathParamsTypes.SET_PREFIX_PATH_PARAMS, data)

const getThemePathParams = (state: RootState) => state.pathparams.themePathParams

const setThemePathParams = (data: string) => action(PathParamsTypes.SET_THEME_PARAMS, data)

const getUtmCampaignPathParams = (state: RootState) => state.pathparams.utmCampaignParams

const setUtmCampaignPathParams = (data: string) =>
  action(PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS, data)

export const PathParamsActions = {
  getAppointmentPathParams,
  setAppointmentPathParams,
  getVideoCallRedirectParams,
  setVideoCallRedirectParams,
  getPrefixPathParams,
  setPrefixPathParams,
  getThemePathParams,
  setThemePathParams,
  getUtmCampaignPathParams,
  setUtmCampaignPathParams,
}
