import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

export enum DocumentTypes {
  GET_TERMS_AND_CONDITIONS_PDF = `@documents/GET_TERMS_AND_CONDITIONS_PDF`,
  SET_TERMS_AND_CONDITIONS_PDF = `@documents/SET_TERMS_AND_CONDITIONS_PDF`,
  GET_PRODUCT_MANUAL_PDF = `@documents/GET_PRODUCT_MANUAL_PDF`,
  SET_PRODUCT_MANUAL_PDF = `@documents/SET_PRODUCT_MANUAL_PDF`,
  GET_PRIVACY_POLICY_PDF = `@documents/GET_PRIVACY_POLICY_PDF`,
  SET_PRIVACY_POLICY_PDF = `@documents/SET_PRIVACY_POLICY_PDF`,
  GET_REDIRECT_TO_DOCUMENT = `@subscription/GET_REDIRECT_TO_DOCUMENT`,
  SET_REDIRECT_TO_DOCUMENT = `@subscription/SET_REDIRECT_TO_DOCUMENT`,
  DELETE_DOCUMENT = `@subscription/DELETE_DOCUMENT`,
}

export interface GenericDocumentResponse {
  documentUrl?: string
}

export interface RedirectToDocument extends SagaWithHistory {
  type: string
}

export interface IDocumentCallback {
  callback: (url: string) => void
}

export interface DocumentState {
  readonly productManual?: GenericDocumentResponse
  readonly termsAndConditions?: GenericDocumentResponse
  readonly privacyPolicy?: GenericDocumentResponse
  readonly redirectToDocument?: RedirectToDocument
}
