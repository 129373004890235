import { RootState } from 'src/store'
import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'
import { action } from 'typesafe-actions'

import {
  IAppointment,
  IAppointmentDetails,
  IDataAndTimeAvailableRequest,
  IDoctorDetails,
  IDoctorDetailsRequest,
  IDoctorsRequest,
  IProfessional,
  IProfessionalsRequest,
  IRealizedAppointmentsRequest,
  ISchedules,
  ISpecialty,
  IType,
  SchedulingTypes,
} from './types'

const getSpecialtiesListRequest = (data?: SagaWithHistory) =>
  action(SchedulingTypes.GET_SPECIALTIES_LIST_REQUEST, data)

const getSpecialtiesList = (state: RootState) => state.scheduling.specialtiesList

const setSpecialtiesList = (data: ISpecialty[]) =>
  action(SchedulingTypes.SET_SPECIALTIES_LIST, data)

const getSelectedSpecialty = (state: RootState) => state.scheduling.selectedSpecialty

const setSelectedSpecialty = (data: ISpecialty) =>
  action(SchedulingTypes.SET_SELECTED_SPECIALTY, data)

const getDateAndTimeAvailableRequest = (data?: IDataAndTimeAvailableRequest) =>
  action(SchedulingTypes.GET_DATE_AND_TIME_AVAILABLE_REQUEST, data)

const getDateAndTimeAvailable = (state: RootState) => state.scheduling.dataAndTimeAvailable

const setDateAndTimeAvailable = (data: ISchedules[]) =>
  action(SchedulingTypes.SET_DATE_AND_TIME_AVAILABLE, data)

const getDoctorsListRequest = (data?: IDoctorsRequest) =>
  action(SchedulingTypes.GET_DOCTORS_LIST_REQUEST, data)

const getDoctorsList = (state: RootState) => state.scheduling.doctorsList

const setDoctorsList = (data: IProfessional[]) => action(SchedulingTypes.SET_DOCTORS_LIST, data)

const getProfessionalsListRequest = (data?: IProfessionalsRequest) =>
  action(SchedulingTypes.GET_PROFESSIONAL_LIST_REQUEST, data)

const getProfessionalsList = (state: RootState) => state.scheduling.professionalsList

const setProfessionalsList = (data: IProfessional[]) =>
  action(SchedulingTypes.SET_PROFESSIONAL_LIST, data)

const getDoctorDetailsRequest = (data?: IDoctorDetailsRequest) =>
  action(SchedulingTypes.GET_DOCTOR_DETAILS_REQUEST, data)

const getDoctorDetails = (state: RootState) => state.scheduling.doctorDetails

const setDoctorDetails = (data: IDoctorDetails) => action(SchedulingTypes.SET_DOCTOR_DETAILS, data)

const getSelectedProfessional = (state: RootState) => state.scheduling.selectedProfessional

const setSelectedProfessional = (data: IProfessional) =>
  action(SchedulingTypes.SET_SELECTED_PROFESSIONAL, data)

const getSelectedDateTime = (state: RootState) => state.scheduling.selectedDateTime

const setSelectedDateTime = (data: string) => action(SchedulingTypes.SET_SELECTED_DATE_TIME, data)

const getAppointmentListRequest = (data?: SagaWithHistory) =>
  action(SchedulingTypes.GET_APPOINTMENTS_LIST_REQUEST, data)

const getAppointmentList = (state: RootState) => state.scheduling.appointmentList

const setAppointmentList = (data: IAppointmentDetails[]) =>
  action(SchedulingTypes.SET_APPOINTMENTS_LIST, data)

const getSelectedAppointment = (state: RootState) => state.scheduling.selectedAppointment

const setSelectedAppointment = (data: IAppointmentDetails) =>
  action(SchedulingTypes.SET_SELECTED_APPOINTMENT, data)

const getProfessionalType = (state: RootState) => state.scheduling.professionalType

const setProfessionalType = (data: IType) => action(SchedulingTypes.SET_PROFESSIONAL_TYPE, data)

const createScheduling = (data?: SagaWithHistory) => action(SchedulingTypes.CREATE_SCHEDULING, data)

const cancelScheduling = (data?: SagaWithHistory) => action(SchedulingTypes.CANCEL_SCHEDULING, data)

const createRescheduling = (data?: SagaWithHistory) =>
  action(SchedulingTypes.CREATE_RESCHEDULING, data)

const getAppointmentType = (state: RootState) => state.scheduling.appointmentType

const setAppointmentType = (data: IType) => action(SchedulingTypes.SET_APPOINTMENT_TYPE, data)

const getRealizedAppointmentsRequest = (data?: IRealizedAppointmentsRequest) =>
  action(SchedulingTypes.GET_REALIZED_APPOINTMENTS_REQUEST, data)

const getRealizedAppointments = (state: RootState) => state.scheduling.realizedAppointments

const setRealizedAppointments = (data: IAppointment[]) =>
  action(SchedulingTypes.SET_REALIZED_APPOINTMENTS, data)

export const SchedulingActions = {
  getSpecialtiesListRequest,
  getSpecialtiesList,
  setSpecialtiesList,
  getSelectedSpecialty,
  setSelectedSpecialty,
  getDoctorDetailsRequest,
  getDoctorDetails,
  setDoctorDetails,
  getDateAndTimeAvailableRequest,
  getDateAndTimeAvailable,
  setDateAndTimeAvailable,
  getDoctorsListRequest,
  getDoctorsList,
  setDoctorsList,
  getProfessionalsListRequest,
  getProfessionalsList,
  setProfessionalsList,
  getSelectedProfessional,
  setSelectedProfessional,
  getSelectedDateTime,
  setSelectedDateTime,
  getAppointmentListRequest,
  getAppointmentList,
  setAppointmentList,
  getSelectedAppointment,
  setSelectedAppointment,
  getProfessionalType,
  setProfessionalType,
  createScheduling,
  cancelScheduling,
  createRescheduling,
  getAppointmentType,
  setAppointmentType,
  getRealizedAppointmentsRequest,
  getRealizedAppointments,
  setRealizedAppointments,
}
