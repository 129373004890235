import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SymptomsActions } from 'src/store/ducks/symptoms/actions'
import { H1, PLarge, PMedium } from 'src/styles/commons'
import { useTheme } from 'styled-components'

import { InterUIButton, InterUIContainer, InterUIInputGroup } from '@interco/inter-ui-react-lib'

export const OtherSymptoms: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const selectedOtherSymptoms = useSelector(SymptomsActions.getSelectedOtherSymptoms)

  const [text, setText] = useState('')
  const [textLength, setTextLength] = useState(0)

  useBasePage({
    navbarTitle: PageTitles.CONSULT_NOW,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    setText(selectedOtherSymptoms)
    setTextLength(selectedOtherSymptoms.length)
  }, [selectedOtherSymptoms])

  /**
   * Evento disparado ao clicar no botão continuar
   */
  const handleOnContinue = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_OUTROSSINTOMAS_T_CONTINUAR.name, {
      content_name: text.trim(),
    })
    dispatch(SymptomsActions.setSelectedOtherSymptoms(text.trim()))
    history.push(TypesRoutes.SYMPTOMS)
  }

  /**
   * Elemento a ser posicionado no rodapé.
   */
  const stickyFooter = (
    <InterUIButton onClick={handleOnContinue} disabled={textLength === 0}>
      Continuar
    </InterUIButton>
  )

  /**
   * Evento disparado ao digitar informações no input de textarea.
   * @param event ChangeEvent disparado.
   */
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target as HTMLTextAreaElement

    setText(value)
    setTextLength(value.length)
  }

  return (
    <>
      <InterUIContainer stickyFooter={stickyFooter}>
        <H1 marginBottom="16px">O que você está sentindo?</H1>
        <PLarge scale={400} marginBottom="24px">
          Descreva de forma clara o seu sintoma
        </PLarge>
        <InterUIInputGroup>
          <textarea
            id="textarea"
            placeholder="Escreva aqui"
            rows={6}
            value={text}
            maxLength={500}
            style={{ color: theme.colors.neutral.grayscale.A300 }}
            onChange={(event) => handleChange(event)}
          />
        </InterUIInputGroup>
        <PMedium scale={400} margin="4px 0px">{`${textLength}/500 caracteres`}</PMedium>
      </InterUIContainer>
    </>
  )
}
