import React, { useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { DocumentActions } from 'src/store/ducks/document/action'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const AccreditedNetwork: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconManual = <Icons.BookOpen color={theme.colors.neutral.grayscale.A500} />
  const iconSignOut = <Icons.SignOut color={theme.colors.base.white} />

  const [accreditedNetworkBS, setAccreditedNetworkBS] = useState(false)
  const [isPharmacy, setIsPharmacy] = useState(false)

  useBasePage({
    navbarTitle: PageTitles.ACCREDITED_NETWORK,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const redirectLExternalLink = () => {
    isPharmacy
      ? window.location.assign('https://www.paguemenos.com.br/nossas-lojas')
      : window.location.assign('https://dasa.com.br/somos-dasa#nossas-marcas')
  }

  const handleClickFind = (type: string) => {
    setAccreditedNetworkBS(true)
    type === 'pharmacy' ? setIsPharmacy(true) : setIsPharmacy(false)
  }

  const goToProductManual = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_REDE_CONVENIADA_T_MANUAL.name)

    dispatch(DocumentActions.getProductManualPDFRequest())
    dispatch(
      DocumentActions.setRedirectToDocument({
        history,
        pathname: TypesRoutes.DOCUMENT,
        type: 'MANUAL',
      }),
    )
  }

  const stickyFooter = (
    <Button
      margin="0 24px 24px"
      data-testid="test-button-back"
      onClick={() => history.push(TypesRoutes.HOME_DR_INTER)}
    >
      Voltar
    </Button>
  )

  return (
    <>
      <InterUIContainer margin="0px" stickyFooter={stickyFooter}>
        <InterUIContainer margin="24px 24px 32px">
          <H2>Encontre a rede conveniada mais próxima de você</H2>

          <InterUIContainer margin="24px 0 16px">
            <PMedium bold>Rede de farmácias credenciadas</PMedium>
            <PMedium marginBottom="16px" scale={400}>
              Encontre as farmácias conveniadas e garanta até
              <strong> 30% de desconto em medicamentos.</strong> Basta informar no momento da compra
              que é assinante do Doutor Inter.
            </PMedium>
            <Button
              variant="secondary"
              alignContent="space-between"
              data-testid="test-button-pharmacy"
              onClick={() => handleClickFind('pharmacy')}
            >
              <PMedium marginBottom="0px">Encontrar farmácias</PMedium> {iconArrow}
            </Button>
          </InterUIContainer>
          <InterUIContainer margin="24px 0 16px">
            <PMedium bold>Exames laboratoriais</PMedium>
            <PMedium marginBottom="16px" scale={400}>
              Conheça os laboratórios da rede conveniada e tenha até
              <strong> 15% de desconto em exames.</strong> Você só precisa informar durante o
              agendamento que é assinante do Doutor Inter.
            </PMedium>
            <Button
              variant="secondary"
              alignContent="space-between"
              data-testid="test-button-lab"
              onClick={() => handleClickFind('lab')}
            >
              <PMedium marginBottom="0px">Encontrar laboratórios</PMedium> {iconArrow}
            </Button>
          </InterUIContainer>
        </InterUIContainer>

        <InterUISeparator height="8px" />

        <InterUIContainer margin="32px 24px 24px">
          <InterUIListDescription
            data-testid="test-product-manual"
            margin="0 0 32px"
            iconLeft={iconManual}
            iconRight={iconArrow}
            alignAllItems="center"
            onClick={goToProductManual}
          >
            <PMedium marginBottom="0" bold>
              Manual do produto
            </PMedium>
            <PMedium marginBottom="0" scale={400}>
              Manual do Doutor Inter
            </PMedium>
          </InterUIListDescription>

          <InterUIAlert margin="24px 0 0">
            <PSmall bold scale={500} marginBottom="4px">
              Benefícios exclusivos para quem contrata
            </PSmall>
            <PSmall scale={500} marginBottom="0">
              As redes conveniadas devem ser usadas apenas pela pessoa titular do plano.
            </PSmall>
          </InterUIAlert>
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="test-accreditedNetwork-BS"
        title="Rede conveniada"
        toggle={accreditedNetworkBS}
        onHide={() => setAccreditedNetworkBS(false)}
      >
        <PMedium marginBottom="0" scale={400}>
          Vamos te redirecionar para uma página da web para que você tenha acesso às unidades da
          nossa rede conveniada.
        </PMedium>
        <Button
          margin="24px 0 0"
          alignContent="space-between"
          data-testid="test-redirect-BS"
          onClick={redirectLExternalLink}
        >
          <PMedium marginBottom="0px">Acessar site da rede conveniada</PMedium> {iconSignOut}
        </Button>
      </InterUIBottomSheet>
    </>
  )
}
