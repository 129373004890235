import styled from 'styled-components'

export const InfoReschedule = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
  margin-bottom: 8px;

  :last-child {
    margin-bottom: 0;
  }
`
