import { DefaultTheme } from 'styled-components'

export enum ThemeTypes {
  GET_THEME = `@user/GET_THEME`,
  SET_THEME = `@user/SET_THEME`,
}

export interface ThemeState {
  readonly theme: DefaultTheme
}
