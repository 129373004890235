import BaseService from 'src/config/bridge/BaseService'
import {
  ICancelAttendanceRequest,
  ICreateAttendanceRequest,
  ISpontaneousDemands,
  IVideoCall,
} from 'src/store/ducks/healthcare/types'
import { IMedicinesResponse } from 'src/store/ducks/medicines/types'
import { ISymptomsResponse } from 'src/store/ducks/symptoms/types'

export default class EmergencyCareService {
  static async getSymptomsList() {
    return BaseService.doExecute<ISymptomsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/symptoms`,
    })
  }

  static async getMedicinesList(name: string) {
    return BaseService.doExecute<IMedicinesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/medicines/${name}`,
    })
  }

  static async createAttendance(payload: ICreateAttendanceRequest, transactionId: string) {
    const headers = {
      'x-transaction-id': transactionId,
    }
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/spontaneous-demands`,
      data,
      headers,
    })
  }

  static async getAttendance(patientId: number) {
    const headers = {
      'x-patient-id': patientId.toString(),
    }

    return BaseService.doExecute<ISpontaneousDemands>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/spontaneous-demands`,
      headers,
    })
  }

  static async cancelAttendance(payload: ICancelAttendanceRequest) {
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/spontaneous-demands/cancel`,
      data,
    })
  }

  static async getVideoCallUrl(patientId: number, professionalType: string) {
    const headers = {
      'x-patient-id': patientId.toString(),
    }

    try {
      return await BaseService.doExecute<IVideoCall>({
        method: 'GET',
        endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/videocall?professionalType=${professionalType}`,
        headers,
      })
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        return {
          object: {} as IVideoCall,
          dateTime: (error as { dateTime: string }).dateTime,
          status: (error as { status: number }).status,
          message: 'O paciente não foi chamado ainda.',
        }
      }

      return Promise.reject(error)
    }
  }
}
