import React from 'react'

import { SkeletonBox, SkeletonContent, SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadScheduleCard: React.FC = () => (
  <SkeletonBox>
    <InterUISkeleton width="20%" marginBottom="16px" />
    <SkeletonGrid>
      <InterUISkeleton width="24px" height="24px" />
      <SkeletonContent>
        <InterUISkeleton width="50%" marginBottom="4px" />
        <InterUISkeleton />
      </SkeletonContent>
      <InterUISkeleton width="24px" height="24px" />
    </SkeletonGrid>

    <InterUISeparator variant="small" margin="24px 0" style={{ width: '100%', flex: 'auto' }} />

    <InterUISkeleton width="40%" marginBottom="8px" />
    <InterUISkeleton width="20%" />
  </SkeletonBox>
)
