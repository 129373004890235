import styled from 'styled-components'

export const ContentHelp = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 5px;
  margin: 4px 0 24px;
`

export const ContentTextValue = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
`
