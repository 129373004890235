import { action } from 'typesafe-actions'

import { TabTypes } from './types'
import { RootState } from '../..'

const get = (state: RootState) => state.tab.selectedTab

const clinic = () => action(TabTypes.CLINIC)

const schedule = () => action(TabTypes.SCHEDULE)

const profile = () => action(TabTypes.PROFILE)

export const TabActions = {
  get,
  clinic,
  schedule,
  profile,
}
