import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { BaseResponseBack } from 'src/config/bridge/BaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { SubscriptionStatusEnum } from 'src/enums/SubscriptionStatusEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import SubscriptionService from 'src/services/SubscriptionService'
import UserService from 'src/services/UserService'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { UserActions } from 'src/store/ducks/user/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { clearFormatting } from 'src/utils/commons'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'

import { SpecialtiesActions } from '../specialties/actions'
import { SubscriptionActions } from './actions'
import {
  ICancelSubscriptionRequest,
  ICreateSubscriptionRequest,
  IGetSubscriptionRequest,
  IHolder,
  ISubscription,
  ISubscriptionStatusRequest,
  ITransactionStatusRequest,
  ITransactionStatusResponse,
  SubscriptionTypes,
} from './types'

const DELAY_TIME = 5000

function* createSubscription({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const holder = yield* select(SubscriptionActions.getHolder)

    const paymentAmount = yield* select(SubscriptionActions.getPrices)

    delete holder.socialId
    delete holder.patientId
    delete holder.holderPatientId

    const createSubscriptionRequest: ICreateSubscriptionRequest = {
      paymentAmount: paymentAmount.price.trim(),
      ...holder,
    }

    yield* call(SubscriptionService.createSubscription, createSubscriptionRequest)

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const userInfo = yield* call(BaseBridge.getUserInfo)

    NewRelicUtils.noticeError(new Error(userInfo.phoneNumber), { errorCodeRef: 'User.phone 1' })

    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle: 'Não conseguimos realizar a sua assinatura. Por favor, tente novamente mais tarde.',
      route: TypesRoutes.SUBSCRIPTION,
    }

    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.createSubscription', errorDetails)
  }
}

function* cancelSubscription({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const reason = yield* select(SubscriptionActions.getReasonCancelSubscription)
    const transactionId = yield* select(CheckoutActions.getTransactionId)
    const { withRefund } = yield* select(SubscriptionActions.getCancellationFee)

    const iSafeRequest = {
      category: '16',
      checkType: '5',
      feature: 'AUTORIZACAO_SEGURO',
      value: 0,
    }

    if (!transactionId) {
      yield* call(BaseBridge.requestISafe, iSafeRequest)
    }

    const cancelSubscriptionRequest: ICancelSubscriptionRequest = {
      reason,
      withRefund,
    }

    yield* call(SubscriptionService.cancelSubscription, cancelSubscriptionRequest)

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    const errorDetails = {
      title: 'Erro ao cancelar sua assinatura',
      subTitle: 'Não conseguimos cancelar a sua assinatura. Por favor, tente novamente .',
      route: TypesRoutes.MY_SUBSCRIPTION_DETAILS,
    }

    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.cancelSubscription', errorDetails)
  }
}

function* getPrice() {
  try {
    yield* put(LoadingActions.show())

    const price = yield* call(SubscriptionService.getPrice)

    yield* put(SubscriptionActions.setPrices(price.object))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.getPrice')
  }
}

function* getSubscription({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { onTransmission } = (payload || {}) as IGetSubscriptionRequest

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const userInfoResponse = yield* call(UserService.getUserInfo, userInfoBridge.account)

    yield* put(UserActions.setUser(userInfoResponse.object))

    const holder: IHolder = {
      name: userInfoBridge.name,
      socialId: clearFormatting(userInfoBridge.cpf),
      birthdate: userInfoResponse.object.birthDate,
      email: userInfoBridge.email.toLocaleLowerCase(),
      phone: clearFormatting(userInfoBridge.phoneNumber),
    }

    const response = yield* call(SubscriptionService.getSubscription, userInfoBridge.cpf)

    let status: string | undefined
    let acceptedTerms: boolean | undefined

    if (response.status === 204) {
      status = 'Não há assinatura'
      holder.status = 'WITHOUT_SIGNATURE'
    } else {
      const data = (response as BaseResponseBack<ISubscription>).object as ISubscription

      holder.patientId = data.patientId

      yield* put(SubscriptionActions.setSubscription(data))

      status = data.subscriptionStatus
      acceptedTerms = data.termsAndConditions.accepted
    }

    yield* put(SubscriptionActions.setHolder(holder))

    if (status) {
      yield* call(onTransmission, status, acceptedTerms || false)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, a aplicação está indisponível. Por favor, tente novamente em alguns minutos.',
      route: TypesRoutes.SUBSCRIPTION,
      disabledButton: true,
    }

    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.getSubscription', errorDetails)
  }
}

function* termsAndConditions({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const holder = yield* select(SubscriptionActions.getHolder)

    if (!holder.patientId) {
      throw new Error('PatientId invalido.')
    }

    yield* call(SubscriptionService.termsAndConditions, holder.patientId)

    yield* put(LoadingActions.hide())

    if (payload) {
      yield* put(SpecialtiesActions.getSpecialtiesRequest())
      redirectThroughSaga(payload)
    }
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      route: TypesRoutes.REQUEST_GO_BACK,
      disabledButton: false,
    }

    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.termsAndConditions', errorDetails)
  }
}

function* getSubscriptionStatus({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const holder = yield* select(SubscriptionActions.getHolder)

    const { onTransmission } = (payload || {}) as ISubscriptionStatusRequest

    let response: BaseResponseBack<ISubscription>

    do {
      yield* delay(DELAY_TIME)
      response = yield* call(SubscriptionService.getSubscriptionStatus, userInfoBridge.cpf)
    } while (
      response.object.subscriptionStatus === SubscriptionStatusEnum.PENDENTE ||
      response.object.subscriptionStatus === SubscriptionStatusEnum.PROCESSANDO
    )

    holder.patientId = response.object.patientId

    yield* put(SubscriptionActions.setHolder(holder))

    yield* put(LoadingActions.hide())
    onTransmission(response.object.subscriptionStatus)
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      route: TypesRoutes.REQUEST_GO_BACK,
      disabledButton: false,
    }

    yield* errorHandlingSaga(
      error as Error,
      'SubscriptionActions.getSubscriptionStatus',
      errorDetails,
    )
  }
}

function* getTransactionStatus({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { transactionId, onTransmission } = (payload || {}) as ITransactionStatusRequest

    let response: BaseResponseBack<ITransactionStatusResponse>

    do {
      yield* delay(DELAY_TIME)
      response = yield* call(SubscriptionService.getTransactionStatus, transactionId)
    } while (response.object.transactionStatus === 'PROCESSING')

    yield* put(LoadingActions.hide())
    onTransmission(response.object.transactionStatus)
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle: 'Houve um erro na finalização da compra, por favor tente novamente mais tarde.',
      route: TypesRoutes.SUBSCRIPTION,
      disabledButton: true,
    }

    yield* errorHandlingSaga(
      error as Error,
      'SubscriptionActions.getTransactionStatus',
      errorDetails,
    )
  }
}

function* activateSubscription({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const holder = yield* select(SubscriptionActions.getHolder)

    if (!holder.patientId) {
      throw new Error('PatientId invalido.')
    }

    yield* call(SubscriptionService.activateSubscription, holder.patientId)

    yield* put(LoadingActions.hide())
    redirectThroughSaga(payload)
  } catch (error) {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'Houve um erro na ativação da sua assinatura, por favor entre em contato com nosso suporte.',
      disabledButton: true,
    }

    yield* errorHandlingSaga(
      error as Error,
      'SubscriptionActions.getTransactionStatus',
      errorDetails,
    )
  }
}

function* getCancellationFee({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(SubscriptionService.getCancellationFee)

    yield* put(SubscriptionActions.setCancellationFee(response.object.subscriptionCancellationFee))

    yield* put(LoadingActions.hide())
    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SubscriptionActions.getCancellationFee')
  }
}

export default function* watchSubscription() {
  yield* takeLatest(SubscriptionTypes.CREATE_SUBSCRIPTION, createSubscription)
  yield* takeLatest(SubscriptionTypes.CANCEL_SUBSCRIPTION, cancelSubscription)
  yield* takeLatest(SubscriptionTypes.GET_SUBSCRIPTION_REQUEST, getSubscription)
  yield* takeLatest(SubscriptionTypes.GET_TRANSACTION_STATUS, getTransactionStatus)
  yield* takeLatest(SubscriptionTypes.ACTIVATE_SUBSCRIPTION, activateSubscription)
  yield* takeLatest(SubscriptionTypes.GET_PRICES_REQUEST, getPrice)
  yield* takeLatest(SubscriptionTypes.GET_CANCELLATION_FEE_REQUEST, getCancellationFee)
  yield* takeLatest(SubscriptionTypes.GET_TERMS_AND_CONDITIONS, termsAndConditions)
  yield* takeLatest(SubscriptionTypes.GET_SUBSCRIPTION_STATUS_REQUEST, getSubscriptionStatus)
}
