import styled from 'styled-components'

export const AttachFileButton = styled.div`
  margin: 16px 0 0;
  min-width: 220px;
  height: 34px;
  padding: 8px 16px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.primary.A300};
  color: ${(props) => props.theme.colors.primary.A500};

  &:disabled {
    border-color: ${(props) => props.theme.colors.neutral.grayscale.A200};
    color: ${(props) => props.theme.colors.neutral.grayscale.A300};
  }
`
