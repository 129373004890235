import { PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIList, InterUITag } from '@interco/inter-ui-react-lib'

interface AttachedFileStatusProps {
  fileName: string
  fileStatus: 'SUCCESS' | 'FAIL'
}

export const AttachedFileStatus: React.FC<AttachedFileStatusProps> = ({ fileName, fileStatus }) => {
  const theme = useTheme()
  const iconSize = 16

  let tagParams = {
    text: 'Anexado',
    bgColor: theme.colors.feedback.success.A100,
    color: theme.colors.feedback.success.A500,
  }

  if (fileStatus !== 'SUCCESS') {
    tagParams = {
      text: 'Falha no anexo',
      bgColor: theme.colors.feedback.error.A100,
      color: theme.colors.feedback.error.A500,
    }
  }

  const attachmentIcon = (
    <Icons.Attachment
      color={theme.colors.neutral.grayscale.A400}
      width={iconSize}
      height={iconSize}
      style={{ minWidth: iconSize, minHeight: iconSize, marginRight: '8px' }}
    />
  )

  const statusTag = (
    <InterUITag backgroundColor={tagParams.bgColor} color={tagParams.color}>
      {tagParams.text}
    </InterUITag>
  )

  return (
    <>
      <InterUIList
        align="center"
        margin="0 0 16px"
        value={statusTag}
        text={
          <>
            {attachmentIcon}
            <PMedium marginBottom="0">{fileName}</PMedium>
          </>
        }
      />
    </>
  )
}
