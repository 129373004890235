import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { NavbarActions } from 'src/store/ducks/navbar/actions'

interface BasePage {
  navbarTitle: PageTitles
  hideHomeBtn?: boolean
  hideBackNavigationBtn?: boolean
  showExitBS?: boolean
  backNavigationHandler?: () => void
  homeNavigationHandler?: () => void
}

export const useBasePage = (data: BasePage) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    navbarTitle,
    hideHomeBtn,
    hideBackNavigationBtn,
    showExitBS,
    backNavigationHandler,
    homeNavigationHandler,
  } = data

  useEffect(() => {
    dispatch(NavbarActions.setTitle(navbarTitle))

    if (hideHomeBtn) {
      dispatch(NavbarActions.hideHomeBtn())
    } else {
      dispatch(NavbarActions.showHomeBtn())
    }

    if (hideBackNavigationBtn) {
      dispatch(NavbarActions.hideBackNavigationBtn())
    } else {
      dispatch(NavbarActions.showBackNavigationBtn())
    }

    if (showExitBS) {
      dispatch(NavbarActions.showExitBS())
    } else {
      dispatch(NavbarActions.hideExitBS())
    }

    if (backNavigationHandler) {
      dispatch(NavbarActions.setBackNavigationHandler(backNavigationHandler))
      BaseBridge.addBackListener(backNavigationHandler)
    } else {
      const restart = () => history.push(TypesRoutes.START)
      dispatch(NavbarActions.setBackNavigationHandler(restart))
      BaseBridge.addBackListener(restart)
    }

    if (homeNavigationHandler) {
      dispatch(NavbarActions.setHomeNavigationHandler(homeNavigationHandler))
    }
  }, [dispatch, navbarTitle, hideHomeBtn, hideBackNavigationBtn])
}
