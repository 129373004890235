import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { H2, H3, PMedium, PSmall } from 'src/styles/commons'
import { formatDateReschedule, formatHours } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBox,
  InterUIContainer,
  InterUIList,
  InterUILoadingDots,
  InterUISeparator,
  InterUITimeline,
} from '@interco/inter-ui-react-lib'
import { TimelineType } from '@interco/inter-ui-react-lib/dist/interfaces/inter-ui-timeline-props'

import { AttachedFileStatus, Button } from '..'

export const WaitingRoom: React.FC = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()

  const spontaneousDemands = useSelector(HealthcareActions.getAttendance)
  const videoCallInfo = useSelector(HealthcareActions.getVideoCallInfos)
  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const { type } = useSelector(SchedulingActions.getAppointmentType)
  const appointmentPathParams = useSelector(PathParamsActions.getAppointmentPathParams)
  const getUploadedFilesResult = useSelector(FilesActions.getUploadedFilesResult)

  const [typePosition, setTypePosition] = useState<TimelineType>('Warning')
  const [typePreparingOffice, setTypePreparingOffice] = useState<TimelineType>('Start')
  const [typeStartConsultation, setTypeStartConsultation] = useState<TimelineType>('Start')

  const currentAppointment = type || appointmentPathParams

  useEffect(() => {
    let intervalId = {} as NodeJS.Timer

    if (currentAppointment === 'IMMEDIATE') {
      const { attendance, videocall } = spontaneousDemands

      setTypePosition('Warning')
      setTypePreparingOffice('Start')

      if (attendance) {
        if (videocall) {
          setTypePosition('Success')
          setTypePreparingOffice('Warning')

          dispatch(
            SchedulingActions.getDoctorDetailsRequest({
              history,
              pathname: TypesRoutes.APPOINTMENT,
              doctorId: videocall.doctorId,
              callback: () => {
                setTypePreparingOffice('Success')
                setTypeStartConsultation('Success')
              },
            }),
          )
        } else {
          intervalId = setInterval(() => {
            dispatch(HealthcareActions.getAttendanceRequest())
          }, 10000)
        }
      }
    } else {
      setTypePosition('Success')
      setTypePreparingOffice('Warning')

      if (videoCallInfo) {
        setTypePreparingOffice('Success')
        setTypeStartConsultation('Start')

        intervalId = setInterval(() => {
          setTypeStartConsultation('Success')
          history.push(TypesRoutes.APPOINTMENT)
        }, 2000)
      } else {
        intervalId = setInterval(() => {
          dispatch(HealthcareActions.getVideoCallUrlRequest())
        }, 10000)
      }
    }
    return () => clearInterval(intervalId)
  }, [currentAppointment, dispatch, history, spontaneousDemands, videoCallInfo])

  useBasePage({
    navbarTitle: PageTitles.CONSULT_NOW,
    hideHomeBtn: true,
    hideBackNavigationBtn: true,
  })

  const handleStickyFooter = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_FILADEESPERA_T_CANCELARPA.name)

    dispatch(
      HealthcareActions.setRedirectToCancel({
        history,
        pathname: TypesRoutes.APPOINTMENT_CANCELLATION,
        type: 'IMMEDIATE',
      }),
    )
  }

  const stickyFooter = (
    <>
      {currentAppointment === 'IMMEDIATE' ? (
        <Button variant="secondary" onClick={handleStickyFooter}>
          Cancelar consulta
        </Button>
      ) : (
        <Button variant="secondary" onClick={() => history.push(TypesRoutes.HOME_DR_INTER)}>
          Voltar
        </Button>
      )}
    </>
  )

  const check = (
    <InterUILoadingDots
      isLoading={false}
      bgColor="transparent"
      checkmarkColor={theme.colors.feedback.success.A500}
      checkmarkTop="1px"
    />
  )

  const loading = (
    <InterUILoadingDots
      isLoading
      bgColor="transparent"
      colorDots={theme.colors.feedback.warning.A500}
      widthDot="2px"
      heightDot="2px"
      heightAnimation="-2px"
      dotLeft="12px"
      dotCenter="16px"
      dotRight="20px"
    />
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H2 marginBottom="16px">
        {currentAppointment === 'IMMEDIATE'
          ? 'Consulta com Clínico Geral'
          : `Consulta com ${appointment.professional?.specialty}`}
      </H2>
      <InterUIBox padding="24px 24px 10px" direction="column" align="initial">
        <InterUITimeline>
          <InterUITimeline.Item
            title={
              currentAppointment === 'IMMEDIATE'
                ? 'Aguardando consulta'
                : 'Aguardando horário da consulta'
            }
            icon={(typePosition === 'Warning' && loading) || (typePosition === 'Success' && check)}
            type={typePosition}
          >
            <span
              style={{
                fontSize: '12px',
                margin: '0px',
                color: theme.colors.neutral.grayscale.A400,
              }}
            >
              Sala de espera
            </span>
          </InterUITimeline.Item>
          <InterUITimeline.Item
            title="Preparando consultório"
            icon={
              (typePreparingOffice === 'Warning' ? loading : undefined) ||
              (typePreparingOffice === 'Success' && check)
            }
            type={typePreparingOffice}
          />
          <InterUITimeline.Item
            title="Início da consulta"
            icon={typeStartConsultation === 'Success' && check}
            type={typeStartConsultation}
            hasVerticalBar
          />
        </InterUITimeline>

        <InterUISeparator margin="16px 0 24px" variant="small" />

        <InterUIList
          value={
            <PSmall scale={400}>
              {currentAppointment === 'IMMEDIATE'
                ? 'Sua consulta será entre'
                : 'Sua consulta será às'}
            </PSmall>
          }
          text={
            <PSmall scale={400}>
              {currentAppointment === 'IMMEDIATE' ? 'Tempo de espera' : 'Data da consulta'}
            </PSmall>
          }
        />
        <InterUIList
          margin="0 0 0px"
          value={
            <PMedium style={{ color: theme.colors.neutral.grayscale.A400 }} bold>
              {currentAppointment === 'IMMEDIATE'
                ? `${spontaneousDemands.attendance.minimumTimeForAttendance} e ${spontaneousDemands.attendance.maximumTimeForAttendance}`
                : formatHours(appointment.appointmentDate)}
            </PMedium>
          }
          text={
            currentAppointment === 'IMMEDIATE' ? (
              <PMedium style={{ color: theme.colors.primary.A500 }} bold>
                {`${spontaneousDemands.attendance.estimatedWaitTime}min`}
              </PMedium>
            ) : (
              <PSmall marginBottom="0" style={{ color: theme.colors.neutral.grayscale.A400 }} bold>
                {formatDateReschedule(appointment.appointmentDate)}
              </PSmall>
            )
          }
        />
      </InterUIBox>

      <InterUIAlert margin="16px 0 0">
        <PSmall marginBottom="4px" bold>
          Informações sobre o pagamento
        </PSmall>
        <PSmall marginBottom="0">
          Caso a consulta não seja realizada, o valor será estornado em até 24h.
        </PSmall>
      </InterUIAlert>

      {(getUploadedFilesResult.success?.length > 0 || getUploadedFilesResult.fail?.length > 0) && (
        <>
          <H3 margin="24px 0" marginBottom="16px">
            Arquivos anexados
          </H3>
          <InterUIBox padding="16px 16px 0" direction="column" align="initial">
            {getUploadedFilesResult.success?.length > 0 && (
              <>
                {getUploadedFilesResult.success.map((file) => (
                  <AttachedFileStatus key={file.id} fileName={file.name} fileStatus="SUCCESS" />
                ))}
              </>
            )}

            {getUploadedFilesResult.fail?.length > 0 && (
              <>
                {getUploadedFilesResult.fail.map((file) => (
                  <AttachedFileStatus key={file.id} fileName={file.name} fileStatus="FAIL" />
                ))}
              </>
            )}
          </InterUIBox>
        </>
      )}
    </InterUIContainer>
  )
}
