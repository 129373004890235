import styled from 'styled-components'

export const ListBenefits = styled.ul`
  list-style: disc;
  padding-left: 22px;

  li + li {
    margin-top: 16px;
  }
`

export const ContractTerms = styled.div`
  button {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }
`
