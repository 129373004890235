import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, PaymentMethod, WaitingPayment } from 'src/components'
import { ExitScheduleBS } from 'src/components/ExitScheduleBS/ExitScheduleBS'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { formatDateSchedule, formatHours } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBox,
  InterUIContainer,
  InterUIProgressBar,
} from '@interco/inter-ui-react-lib'

import { InfoReschedule, Value } from './Payment.styles'

export const Payment: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { patientId } = useSelector(SubscriptionActions.getHolder)
  const professional = useSelector(SchedulingActions.getSelectedProfessional)
  const dateTime = useSelector(SchedulingActions.getSelectedDateTime)
  const professionalType = useSelector(SchedulingActions.getProfessionalType)
  const valueConsult = useSelector(SpecialtiesActions.getHighlightsList)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const isLoading = useSelector(LoadingActions.get)

  const iconArrow = <Icons.ChevronRight color={theme.colors.neutral.grayscale.A400} />
  const iconArrowOrange = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconPayment = <Icons.Dollar color={theme.colors.neutral.grayscale.A500} />
  const iconDebit = <Icons.Wallet color={theme.colors.primary.A500} />
  const iconCredit = <Icons.Card color={theme.colors.primary.A500} />
  const iconAgenda = (
    <Icons.Agenda width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconPending = (
    <Icons.Pending width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconUserAccount = (
    <Icons.UserAccount width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconProfessional = () => {
    if (professionalType?.type === 'NUTRITIONIST') {
      return (
        <Icons.Nutritionist width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
      )
    }
    if (professionalType?.type === 'PSYCHOLOGIST') {
      return (
        <Icons.Psychologist width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
      )
    }
    return <Icons.Doctor width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  }

  const [inWaitPayment, setInWaitPayment] = useState(false)
  const [exitBS, setExitBS] = useState(false)
  const [paymentCardConfig, setPaymentCardConfig] = useState({
    border: theme.colors.neutral.grayscale.A200,
    icon: iconPayment,
    title: 'Formas de pagamento',
    subTitle: '',
    arrow: iconArrow,
  })

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_SCHEDULING,
    showExitBS: true,
    hideBackNavigationBtn: inWaitPayment,
    hideHomeBtn: inWaitPayment,
    homeNavigationHandler: () => setExitBS(true),
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (formOfPayment.type) {
      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: formOfPayment.type === 'DEBIT' ? iconDebit : iconCredit,
        title: formOfPayment.title,
        subTitle: formOfPayment.subTitle,
        arrow: iconArrowOrange,
      })
    }
  }, [])

  const openChoicePaymentForm = async () => {
    try {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONSAGENDADA_PAGAMENTO_T_SELECIONAR.name)

      const response = await CheckoutBaseService.openWallet(
        professionalType?.type === 'DOCTOR'
          ? valueConsult[0].price.replace(`R$`, ``).replace(`,`, `.`)
          : valueConsult[1].price.replace(`R$`, ``).replace(`,`, `.`),
        'APPOINTMENT',
        patientId?.toString(),
      )

      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONSAGENDADA_PAGAMENTO_T_SELECIONADO.name)

      dispatch(CheckoutActions.setFormOfPayment(response))

      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: response.type === 'DEBIT' ? iconDebit : iconCredit,
        title: response.title,
        subTitle: response.subTitle,
        arrow: iconArrowOrange,
      })
    } catch (error: unknown) {
      const errorDetails = {
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        disabledButton: false,
        route: TypesRoutes.HOME_DR_INTER,
      }

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Subscription.openWallet',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const processTransactionStatus = (status: string) => {
    const errorDetails = {
      title: 'Transação negada',
      subTitle:
        'Houve um erro na transação requisitada, por favor, entre em contato com o suporte.',
      route: TypesRoutes.HOME_DR_INTER,
    }

    switch (status) {
      case 'PROCESSED':
        dispatch(
          SchedulingActions.createScheduling({
            history,
            pathname: TypesRoutes.APPOINTMENT_CONFIRMATION,
          }),
        )
        break
      case 'DENIED':
        dispatch(ErrorActions.show(errorDetails))
        break
      default:
        break
    }
  }

  const finalizeContract = async () => {
    if (BaseBridge.isBrowser()) processTransactionStatus('PROCESSED')
    else {
      try {
        const result = await CheckoutBaseService.contractWallet(
          professionalType?.type === 'DOCTOR'
            ? valueConsult[0].price.replace(`R$`, ``).replace(`,`, `.`)
            : valueConsult[1].price.replace(`R$`, ``).replace(`,`, `.`),
        )

        const payload = {
          transactionId: result.transactionId,
          onTransmission: processTransactionStatus,
        }

        dispatch(CheckoutActions.deleteTransactionId())
        dispatch(CheckoutActions.setTransactionId(result.transactionId))
        dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))
        dispatch(SubscriptionActions.getTransactionStatus(payload))

        setInWaitPayment(true)
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'Subscription.finalizeContract',
        })

        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const stickyFooter = (
    <Button
      margin="24px"
      onClick={finalizeContract}
      disabled={paymentCardConfig.title === `Formas de pagamento` || isLoading}
    >
      Confirmar agendamento
    </Button>
  )

  return inWaitPayment ? (
    <WaitingPayment />
  ) : (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="100%" />
        <InterUIContainer margin="24px 24px 32px">
          <PSmall marginBottom="24px" bold scale={300}>
            {professionalType?.type === 'DOCTOR'
              ? 'Passo 4/4 - Pagamento'
              : 'Passo 3/3 - Pagamento'}
          </PSmall>
          <H2 marginBottom="24px">Resumo</H2>

          <InterUIBox direction="column" align="flex-start">
            <PMedium bold marginBottom="4px">
              Consulta agendada
            </PMedium>
            <InfoReschedule>
              {iconProfessional()}
              <PSmall marginBottom="0" scale={400}>
                {professional.specialty}
              </PSmall>
            </InfoReschedule>
            <InfoReschedule>
              {iconUserAccount}
              <PSmall marginBottom="0" scale={400}>
                {professional.name}
              </PSmall>
            </InfoReschedule>
            <InfoReschedule>
              {iconAgenda}
              <PSmall marginBottom="0" scale={400}>
                {formatDateSchedule(dateTime)}
              </PSmall>
            </InfoReschedule>
            <InfoReschedule>
              {iconPending}
              <PSmall marginBottom="0" scale={400}>
                {formatHours(dateTime)}
              </PSmall>
            </InfoReschedule>
            <PSmall bold scale={400} margin="8px 0 4px">
              Valor da consulta
            </PSmall>

            {valueConsult.length > 0 && (
              <Value>
                {professionalType?.type === 'DOCTOR'
                  ? valueConsult[0].price
                  : valueConsult[1].price}
              </Value>
            )}
          </InterUIBox>

          <H2 margin="24px 0 16px">Como gostaria de pagar?</H2>

          <PaymentMethod
            title={paymentCardConfig.title}
            subTitle={paymentCardConfig.subTitle}
            border={paymentCardConfig.border}
            iconLeft={paymentCardConfig.icon}
            iconRight={paymentCardConfig.arrow}
            openChoicePaymentForm={openChoicePaymentForm}
          />

          <InterUIAlert margin="16px 0 0">
            <PSmall marginBottom="4px" bold>
              Informações sobre o pagamento
            </PSmall>
            <PSmall marginBottom="0">
              O pagamento será cobrado ao final do agendamento. Caso a consulta não seja realizada,
              o valor será estornado em até 24h.
            </PSmall>
          </InterUIAlert>
        </InterUIContainer>
      </InterUIContainer>
      <ExitScheduleBS toggleState={[exitBS, setExitBS]} />
    </>
  )
}
