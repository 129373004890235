export enum PersistedCacheTypes {
  STORE = `@persistedCache/STORE`,
  CLEAR = `@persistedCache/CLEAR`,
  GET_CAMERA_FIRST_ACCESS = `@persistedCache/GET_CAMERA_FIRST_ACCESS`,
  SET_CAMERA_FIRST_ACCESS = `@persistedCache/SET_CAMERA_FIRST_ACCESS`,
}

export interface PersistedCacheState {
  readonly hasAppointmentsListCache: boolean
  readonly hasCameraFirstAccess: boolean
}
