import styled from 'styled-components'

export const ContainerCaptureImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.primary.A100};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 0 auto 24px;
`

export const CapturePhotoButton = styled.button`
  display: block;
  width: 56px;
  height: 56px;
  background-color: ${(props) => props.theme.colors.primary.A500};
  border: 6px solid ${(props) => props.theme.colors.theme};
  border-radius: 50%;
  box-shadow: 0 0 0 4px ${(props) => props.theme.colors.primary.A500};
  margin: 16px auto 24px;
`
