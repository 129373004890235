import InterWebviewBridge from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'

export class VideoCallService {
  static async openVideoCallOnSafari(url: string, doctorName: string, doctorPhoto?: string) {
    const iWb = InterWebviewBridge.getInstance()

    try {
      const parsedUrl = `${window.location.origin}?videocall=${encodeURIComponent(
        url,
      )}&doctorName=${encodeURIComponent(doctorName)}${
        doctorPhoto && `&doctorPhoto=${encodeURIComponent(doctorPhoto)}`
      }`

      return await iWb.execute({
        action: 'willConnectVideoCall',
        metadata: {
          url: parsedUrl,
        },
      })
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'VideoCallService.openVideoCallOnSafari',
      })

      return Promise.reject(error)
    }
  }
}
