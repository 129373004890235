import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { H2, PMedium } from 'src/styles/commons'

import { InterUIAccordion } from '@interco/inter-ui-react-lib'

import { ContentAccordion } from './CommonQuestions.styles'

interface CommonQuestionProps {
  typePage: string
}

interface CommonQuestion {
  id: number
  question: string
  answer: string
}

export const CommonQuestions = ({ typePage }: CommonQuestionProps) => {
  const commonQuestions: CommonQuestion[] = [
    {
      id: 1,
      question: 'O que é o Doutor Inter?',
      answer:
        'O Doutor Inter é uma clínica médica on-line que possibilita o atendimento remoto e o acompanhamento continuado de pacientes através da telemedicina. Estamos à sua disposição para teleconsultas de pronto atendimento ou atendimento agendado com uma equipe médica, nutricionistas e psicólogos altamente qualificados para ajudá-lo no que for preciso e por um preço acessível.',
    },
    {
      id: 2,
      question: 'Qual o valor da consulta?',
      answer:
        'A consulta através do Doutor Inter é particular, com preços acessíveis. As teleconsultas de pronto atendimento com clínico geral são R$ 50,90. Já as teleconsultas agendadas com Nutricionistas e sessão com Psicólogos são R$ 46,90. Por fim, as teleconsultas agendadas com especialistas possuem o valor fixo de R$ 77,00.',
    },
    {
      id: 3,
      question: 'Quais são as formas de pagamento?',
      answer:
        'O pagamento da consulta no Doutor Inter é feito por cartão de débito ou crédito, conforme cadastrado no ato da contratação. No pronto atendimento a cobrança é realizada momentos antes da consulta enquanto na consulta agendada a cobrança é realizada no ato do agendamento. Caso o paciente cancele a consulta antes da data agendada o valor será estornado e não será cobrada nenhuma multa.',
    },
    {
      id: 4,
      question: 'Posso solicitar reembolso da consulta no Doutor Inter ao meu Plano de Saúde?',
      answer:
        'Sim, você terá o reembolso conforme os valores estipulados pelo seu plano de saúde. Para solicitá-lo, a nota fiscal emitida pelo Inter deverá ser anexada junto à documentação exigida pelo seu plano de saúde.',
    },
    {
      id: 5,
      question:
        'Não conseguirei comparecer a uma consulta agendada, posso remarcar para outro dia?',
      answer:
        'Sim, você deverá cancelar através da aba Agenda no Menu e marcar uma nova consulta em outra data conforme o processo de solicitação de consulta agendada.',
    },
    {
      id: 6,
      question: 'E se eu precisar de medicamentos, exames ou atestados?',
      answer:
        'Como em uma consulta presencial, caso necessário, o profissional de saúde poderá prescrever receitas, solicitar exames ou emitir um atestado por meio da nossa plataforma. Seguro para o profissional de saúde e fácil para o paciente, que vai receber tudo por SMS.',
    },
    {
      id: 7,
      question: 'Posso ter atendimento médico a qualquer hora?',
      answer:
        'Sim, com o Doutor Inter você tem atendimento médico rápido, a qualquer hora do dia, através do aplicativo. Realize uma consulta imediata com um médico clínico geral ou, se preferir, agende o seu atendimento com um especialista.',
    },
    {
      id: 8,
      question: 'Mas é confiável?',
      answer:
        'Sim. Você receberá atendimento como se estivesse em um consultório, conversando diretamente com o profissional de saúde e receberá as orientações necessárias.',
    },
  ]

  return (
    <>
      <H2 marginBottom="0">Perguntas frequentes</H2>

      <ContentAccordion>
        {commonQuestions.map((item: CommonQuestion, index: number) => (
          <InterUIAccordion
            key={item.id}
            id={`questions-${index}`}
            data-testid={`questions-${index}`}
            header={<PMedium marginBottom="0">{item.question}</PMedium>}
            className={index === commonQuestions.length - 1 ? 'last' : ''}
            border="bottom"
            onClick={() =>
              BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_FAQ.name, { tela: typePage })
            }
          >
            <PMedium scale={400} marginBottom="0">
              {item.answer}
            </PMedium>
          </InterUIAccordion>
        ))}
      </ContentAccordion>
    </>
  )
}
