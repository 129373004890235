import BaseService from 'src/config/bridge/BaseService'
import {
  IAppointmentsListResponse,
  ICancelSchedulingRequest,
  ICreateReschedulingRequest,
  ICreateSchedulingRequest,
  IDataAndTimeAvailableRequest,
  IDataAndTimeAvailableResponse,
  IDoctorDetailsResponse,
  IDoctorsRequest,
  IProfessionalsRequest,
  IProfessionalsResponse,
  IRealizedAppointmentsRequest,
  IRealizedAppointmentsResponse,
  ISpecialtiesResponse,
} from 'src/store/ducks/scheduling/types'

export default class SchedulingService {
  static async getSpecialtiesList() {
    return BaseService.doExecute<ISpecialtiesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/doctors/specialties`,
    })
  }

  static async getDateAndTimeAvailable(data: IDataAndTimeAvailableRequest) {
    const professionalIdURI = encodeURIComponent(data?.professionalId)
    const startDateURI = encodeURIComponent(data?.startDate)
    const endDateURI = encodeURIComponent(data?.endDate)
    const typeURI = encodeURIComponent(data?.type)

    return BaseService.doExecute<IDataAndTimeAvailableResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/professionals/${professionalIdURI}/schedule?startDate=${startDateURI}&endDate=${endDateURI}&type=${typeURI}`,
    })
  }

  static async getDoctorsList(data: IDoctorsRequest) {
    const { specialtyId, page } = data

    return BaseService.doExecute<IProfessionalsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/doctors?specialty=${specialtyId}&page=${page}`,
    })
  }

  static async getProfessionalList(data: IProfessionalsRequest) {
    const { type, page, patientId } = data

    const headers = {
      'x-patient-id': patientId.toString(),
    }

    return BaseService.doExecute<IProfessionalsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/professionals?type=${type}&page=${page}`,
      headers,
    })
  }

  static async getDoctorDetails(doctorId: number) {
    return BaseService.doExecute<IDoctorDetailsResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/doctors/${doctorId}`,
    })
  }

  static async getAppointmentsList(patientId: number) {
    const headers = {
      'x-patient-id': patientId.toString(),
    }

    return BaseService.doExecute<IAppointmentsListResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/appointments`,
      headers,
    })
  }

  static async createScheduling({ transactionId, ...payload }: ICreateSchedulingRequest) {
    const headers = {
      'x-transaction-id': transactionId,
    }

    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/appointments`,
      headers,
      data,
    })
  }

  static async cancelScheduling({ appointmentId, ...payload }: ICancelSchedulingRequest) {
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/appointments/${appointmentId}/cancel`,
      data,
    })
  }

  static async createRescheduling({ appointmentId, ...payload }: ICreateReschedulingRequest) {
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/appointments/${appointmentId}/reschedule`,
      data,
    })
  }

  static async getRealizedAppointments({
    patientId,
    professionalType,
    specialty,
  }: IRealizedAppointmentsRequest) {
    const headers = {
      'x-patient-id': patientId.toString(),
    }

    return BaseService.doExecute<IRealizedAppointmentsResponse>({
      method: 'GET',
      endpoint: `${
        process.env.REACT_APP_API_TELEMEDICINE_V1
      }/appointments/realized?professionalType=${professionalType}${
        specialty && `&specialty=${encodeURIComponent(specialty)}`
      }`,
      headers,
    })
  }
}
