import { RootState } from 'src/store'
import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'
import { action } from 'typesafe-actions'

import {
  ICancellationFee,
  IGetSubscriptionRequest,
  IHolder,
  IPrices,
  ISubscription,
  ISubscriptionStatusRequest,
  ITransactionStatusRequest,
  SubscriptionTypes,
} from './types'

const createSubscription = (data?: SagaWithHistory) =>
  action(SubscriptionTypes.CREATE_SUBSCRIPTION, data)

const cancelSubscription = (data: SagaWithHistory) =>
  action(SubscriptionTypes.CANCEL_SUBSCRIPTION, data)

const getReasonCancelSubscription = (state: RootState) => state.subscription.reasonCancellation

const setReasonCancelSubscription = (data: string) =>
  action(SubscriptionTypes.SET_REASON_CANCEL_SUBSCRIPTION, data)

const getSubscriptionRequest = (data: IGetSubscriptionRequest) =>
  action(SubscriptionTypes.GET_SUBSCRIPTION_REQUEST, data)

const getSubscription = (state: RootState) => state.subscription.subscription

const setSubscription = (data: ISubscription) => action(SubscriptionTypes.SET_SUBSCRIPTION, data)

const getHolder = (state: RootState) => state.subscription.holder

const setHolder = (data: IHolder) => action(SubscriptionTypes.SET_HOLDER, data)

const getPrices = (state: RootState) => state.subscription.prices

const getPricesRequest = () => action(SubscriptionTypes.GET_PRICES_REQUEST)

const setPrices = (data: IPrices) => action(SubscriptionTypes.SET_PRICES, data)

const getTransactionStatus = (data: ITransactionStatusRequest) =>
  action(SubscriptionTypes.GET_TRANSACTION_STATUS, data)

const activateSubscription = (data: SagaWithHistory) =>
  action(SubscriptionTypes.ACTIVATE_SUBSCRIPTION, data)

const getCancellationFeeRequest = (data: SagaWithHistory) =>
  action(SubscriptionTypes.GET_CANCELLATION_FEE_REQUEST, data)

const getCancellationFee = (state: RootState) => state.subscription.cancellationFee

const setCancellationFee = (data: ICancellationFee) =>
  action(SubscriptionTypes.SET_CANCELLATION_FEE, data)

const termsAndConditions = (data?: SagaWithHistory) =>
  action(SubscriptionTypes.GET_TERMS_AND_CONDITIONS, data)

const getSubscriptionStatus = (data?: ISubscriptionStatusRequest) =>
  action(SubscriptionTypes.GET_SUBSCRIPTION_STATUS_REQUEST, data)

export const SubscriptionActions = {
  createSubscription,
  cancelSubscription,
  getReasonCancelSubscription,
  setReasonCancelSubscription,
  getSubscriptionRequest,
  getSubscription,
  setSubscription,
  getHolder,
  setHolder,
  getPrices,
  setPrices,
  getTransactionStatus,
  activateSubscription,
  getPricesRequest,
  getCancellationFeeRequest,
  getCancellationFee,
  setCancellationFee,
  termsAndConditions,
  getSubscriptionStatus,
}
