import { Reducer } from 'redux'

import {
  ICancellationFee,
  IHolder,
  IPrices,
  ISubscription,
  SubscriptionState,
  SubscriptionTypes,
} from './types'

const INITIAL_STATE: SubscriptionState = {
  holder: {} as IHolder,
  prices: {} as IPrices,
  subscription: {} as ISubscription,
  reasonCancellation: '',
  cancellationFee: {} as ICancellationFee,
}

const reducer: Reducer<SubscriptionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubscriptionTypes.SET_HOLDER:
      return { ...state, holder: action.payload }
    case SubscriptionTypes.SET_PRICES:
      return { ...state, prices: action.payload }
    case SubscriptionTypes.SET_SUBSCRIPTION:
      return { ...state, subscription: action.payload }
    case SubscriptionTypes.SET_REASON_CANCEL_SUBSCRIPTION:
      return { ...state, reasonCancellation: action.payload }
    case SubscriptionTypes.SET_CANCELLATION_FEE:
      return { ...state, cancellationFee: action.payload }
    default:
      return state
  }
}

export default reducer
