import { action } from 'typesafe-actions'
import { RootState } from 'src/store'
import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

import { ISpecialties, ISpecialtyFields, SpecialtiesTypes } from './types'

const getSpecialtiesRequest = (data?: SagaWithHistory) =>
  action(SpecialtiesTypes.GET_SPECIALTIES_REQUEST, data)

const getHighlightsList = (state: RootState) => state.specialties.highlightsList

const setHighlightsList = (data: ISpecialtyFields[]) =>
  action(SpecialtiesTypes.SET_HIGHLIGHTS_LIST, data)

const getSpontaneousDemandsList = (state: RootState) => state.specialties.spontaneousDemandsList

const setSpontaneousDemandsList = (data: ISpecialtyFields[]) =>
  action(SpecialtiesTypes.SET_SPONTANEOUS_DEMANDS_LIST, data)

const getSpecialtiesList = (state: RootState) => state.specialties.specialtiesList

const setSpecialtiesList = (data: ISpecialties[]) =>
  action(SpecialtiesTypes.SET_SPECIALTIES_LIST, data)

export const SpecialtiesActions = {
  getSpecialtiesRequest,
  getHighlightsList,
  setHighlightsList,
  getSpontaneousDemandsList,
  setSpontaneousDemandsList,
  setSpecialtiesList,
  getSpecialtiesList,
}
