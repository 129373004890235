export const browserTransactionId = {
  processed: 'b62e7d02-8bf3-4888-bd99-6b0548b0435d',
}

export const formPaymentCredit = {
  title: 'Crédito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: 'CREDIT',
}

export const formPaymentDebit = {
  title: 'Débito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: 'DEBIT',
}
