import styled from 'styled-components'

export const ContentLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 32px;
  padding: 0 48px;
  text-align: center;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const ListEducation = styled.div`
  margin-bottom: 16px;

  p {
    padding: 16px 0;

    & + p {
      border-top: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A200};
    }
  }
`
