import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { CheckoutTypes, IFormOfPayment } from './types'

const getTransactionId = (state: RootState) => state.checkout.transactionId

const setTransactionId = (data: string) => action(CheckoutTypes.SET_TRANSACTION_ID, data)

const deleteTransactionId = () => action(CheckoutTypes.DELETE_TRANSACTION_ID)

const getFormOfPayment = (state: RootState) => state.checkout.formOfPayment

const setFormOfPayment = (data: IFormOfPayment) => action(CheckoutTypes.SET_FORM_OF_PAYMENT, data)

export const CheckoutActions = {
  getTransactionId,
  setTransactionId,
  deleteTransactionId,
  getFormOfPayment,
  setFormOfPayment,
}
