import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ListBenefits } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { ContainerFullPage, H2, PSmall, PriceTag } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'

import { InterUIAlert, InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

export const Cancellation: React.FC = () => {
  const history = useHistory()

  const subscription = useSelector(SubscriptionActions.getSubscription)
  const { hasCancellationFee, cancellationAmount } = useSelector(
    SubscriptionActions.getCancellationFee,
  )

  useBasePage({
    navbarTitle: PageTitles.CANCELLATION,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_DC_CANCELAR.name, {
      multa_cancelamento: cancellationAmount || '0.00',
    })
  }, [cancellationAmount])

  const keepSubscription = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_MANTER.name)
    history.push(TypesRoutes.HOME_DR_INTER)
  }

  const cancellationSubscription = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_CANCELAR.name)
    history.push(TypesRoutes.CANCELLATION_REASON)
  }

  const stickyFooter = (
    <>
      <Button margin="16px 24px 0" data-testid="keep-subscription" onClick={keepSubscription}>
        Quero manter minha assinatura
      </Button>
      <Button
        margin="16px 24px"
        variant="secondary"
        data-testid="cancellation-subscription"
        onClick={cancellationSubscription}
      >
        Cancelar minha assinatura
      </Button>
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <ContainerFullPage height="144px">
        <InterUIContainer margin="24px 24px 32px">
          <PriceTag>
            <PSmall scale={400} marginBottom="4px">
              Valor total atual
            </PSmall>
            <H2>{`${subscription.paymentAmount} por mês`}</H2>
          </PriceTag>

          {hasCancellationFee && (
            <InterUIAlert type="attention" margin="24px 0 0">
              <PSmall marginBottom="4px" bold>
                Atenção à permanência mínima
              </PSmall>
              <PSmall marginBottom="0">
                O plano possui permanência mínima de 12 meses. Você pode cancelar a qualquer
                momento, mas estará sujeito à multa de {numberFormat(Number(cancellationAmount))}.
              </PSmall>
            </InterUIAlert>
          )}
        </InterUIContainer>

        <InterUISeparator />

        <InterUIContainer margin="32px 24px 24px">
          <H2 marginBottom="24px">Cancelando, você perde os seguintes benefícios</H2>
          <ListBenefits />
        </InterUIContainer>
      </ContainerFullPage>
    </InterUIContainer>
  )
}
