import styled from 'styled-components'

export const Hero = styled.figure`
  margin: 24px 0;

  img {
    width: 100%;
  }
`
export const Banner = styled.figure`
  img {
    width: 100%;
  }
`

export const SpecialtiesContent = styled.div`
  max-height: calc(100vh - 304px);
  overflow: scroll;
`

export const Image = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px auto 24px;
`
