import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { VideoCallService } from 'src/config/bridge/VideoCallService'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/mixedRoutes'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { PMedium } from 'src/styles/commons'
import { TAGS } from 'src/enums/TaggingEnum'

import { InterUIBottomSheet, InterUIInputGroup } from '@interco/inter-ui-react-lib'

import { PersistedCacheActions } from '../../../store/ducks/persistedCache/actions'
import { PreAppointment } from './PreAppointment/PreAppointment'
import { VideoCall } from './VideoCall/VideoCall'

export const Appointment: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const EIGHT_MINUTES_IN_MILLISECONDS = 480000

  const videoCallInfo = useSelector(HealthcareActions.getVideoCallInfos)
  const videoCallParams = useSelector(PathParamsActions.getVideoCallRedirectParams)

  const [pageTitle, setPageTitle] = useState(PageTitles.CONSULT_NOW)
  const [inPreAppointment, setInPreAppointment] = useState(true)
  const [startNow, setStartNow] = useState(false)
  const [bottomSheet, setBottomSheet] = useState(false)
  const [bottomSheetIAmHere, setBottomSheetIAmHere] = useState(false)
  const [bottomSheetConfig, setBottomSheetConfig] = useState({
    title: 'Tempo de inatividade',
    description:
      'Por medida de segurança, o Super App estabelece um tempo máximo de inatividade. Durante sua consulta, vamos perguntar se você ainda está presente, tudo bem?',
    nameButton: 'Tudo bem',
  })

  useBasePage({
    navbarTitle: pageTitle,
    backNavigationHandler: () => setBottomSheet(true),
    hideHomeBtn: true,
    hideBackNavigationBtn: inPreAppointment,
  })

  useEffect(() => {
    if (videoCallParams.videocall) {
      setStartNow(true)
    }
  }, [videoCallParams.videocall])

  // Lógica do bottomSheet a cada 8 minutos
  useEffect(() => {
    let interval = {} as NodeJS.Timer

    BaseBridge.resetSessionTime()
    setBottomSheetIAmHere(true)

    interval = setInterval(() => {
      setBottomSheetConfig({
        title: 'Você ainda está aí?',
        description: '',
        nameButton: 'Sim, estou',
      })
      setBottomSheetIAmHere(true)
    }, EIGHT_MINUTES_IN_MILLISECONDS)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const continueOnScreen = () => {
    setBottomSheetIAmHere(false)
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_VIDEOCHAMADA_T_BOTTOMSHEET.name, {
      name_button: bottomSheetConfig.nameButton,
    })
  }

  const goToHome = () => {
    dispatch(HealthcareActions.setVideoCallInfos(undefined))
    dispatch(HealthcareActions.setAppointmentReady(false))
    dispatch(PersistedCacheActions.clearAppointmentsListCache())
    dispatch(PathParamsActions.setAppointmentPathParams(''))

    setBottomSheet(false)
    history.push(TypesRoutes.START)
  }

  const goToAppointment = () => {
    const videoPageCall = document.getElementById('video-page')

    setPageTitle(PageTitles.VIDEO_CALL)
    if (!videoCallParams.videocall) setInPreAppointment(false)

    if (videoPageCall !== null) {
      videoPageCall.style.opacity = '1'
    }
  }

  const canStart = (doctorName: string, doctorPhoto?: string) => {
    if (videoCallInfo && BaseBridge.isIOS()) {
      VideoCallService.openVideoCallOnSafari(videoCallInfo.url, doctorName, doctorPhoto)
    } else {
      setStartNow(true)
    }
  }

  return (
    <>
      {inPreAppointment && <PreAppointment goToAppointment={goToAppointment} canStart={canStart} />}

      {startNow && (
        <div id="video-page" style={{ opacity: 0 }}>
          <VideoCall />
        </div>
      )}

      <InterUIBottomSheet
        title="Ao sair sua consulta poderá ser encerrada pelo médico."
        toggle={bottomSheet}
        data-testid="exit-BS"
        onHide={() => setBottomSheet(false)}
      >
        <InterUIInputGroup>
          <PMedium margin="4px 0 44px" scale={400}>
            Ao sair da video consulta, o médico poderá entender como desistência e encerrar o
            atendimento. Deseja encerrar?
          </PMedium>
          <Button onClick={goToHome}>Sair</Button>
          <Button variant="secondary" margin="16px 0 0" onClick={() => setBottomSheet(false)}>
            Voltar
          </Button>
        </InterUIInputGroup>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        title={bottomSheetConfig.title}
        toggle={bottomSheetIAmHere}
        onHide={continueOnScreen}
      >
        <InterUIInputGroup>
          {bottomSheetConfig.description && (
            <PMedium marginBottom="24px" scale={400}>
              {bottomSheetConfig.description}
            </PMedium>
          )}
          <Button onClick={continueOnScreen}>{bottomSheetConfig.nameButton}</Button>
        </InterUIInputGroup>
      </InterUIBottomSheet>
    </>
  )
}
