export enum SymptomsTypes {
  GET_SYMPTOMS_LIST_REQUEST = `@symptoms/GET_SYMPTOMS_LIST_REQUEST`,
  GET_SYMPTOMS_LIST = `@symptoms/GET_SYMPTOMS_LIST`,
  SET_SYMPTOMS_LIST = `@symptoms/SET_SYMPTOMS_LIST`,
  GET_SELECTED_SYMPTOMS = `@symptoms/GET_SELECTED_SYMPTOMS`,
  SET_SELECTED_SYMPTOMS = `@symptoms/SET_SELECTED_SYMPTOMS`,
  GET_OTHER_SELECTED_SYMPTOMS = `@symptoms/GET_OTHER_SELECTED_SYMPTOMS`,
  SET_OTHER_SELECTED_SYMPTOMS = `@symptoms/SET_OTHER_SELECTED_SYMPTOMS`,
}

export interface ISymptoms {
  value: string
  description: string
}

export interface ISymptomsResponse {
  symptoms: ISymptoms[]
}

export interface SymptomsState {
  readonly selectedSymptoms: ISymptoms[]
  readonly selectedOtherSymptoms: string
  readonly symptomsList: ISymptoms[]
}
