/**
 * Títulos disponíveis na aplicação.
 */
export enum PageTitles {
  DR_INTER = 'Doutor Inter',
  CONSULT_NOW = 'Consultar agora',
  PROPOSAL_CONTRACT = 'Contrato do Doutor Inter',
  PRODUCT_MANUAL = 'Manual do Doutor Inter',
  PRIVACY_POLICY = 'Política de Privacidade',
  MY_SUBSCRIPTION = 'Minha assinatura',
  MY_SUBSCRIPTION_DATA = 'Dados da minha assinatura',
  CANCELLATION = 'Cancelamento',
  VIDEO_CALL = 'Video chamada',
  APPOINTMENT_SCHEDULED = 'Consulta Agendada',
  APPOINTMENT_UNREALIZED = 'Consulta não realizada',
  APPOINTMENT_CANCELLED = 'Consulta cancelada',
  APPOINTMENT_SCHEDULING = 'Agendamento de consulta',
  APPOINTMENT_RESCHEDULING = 'Reagendar consulta',
  TAKE_THE_PICTURE = 'Tire a foto',
  REVIEW_THE_PICTURE = 'Revise a foto',
  ACCREDITED_NETWORK = 'Rede conveniada',
  EMPTY = '',
}
