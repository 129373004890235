import { BaseResponseBack } from 'src/config/bridge/BaseService'
import {
  IAppointmentDetails,
  IDataAndTimeAvailableResponse,
  IProfessionalsResponse,
  IRealizedAppointmentsResponse,
  ISpecialtiesResponse,
} from 'src/store/ducks/scheduling/types'

const today = new Date()

const formattedDayMonth = `${`0${today.getMonth() + 1}`.slice(-2)}`
const year = today.getFullYear()

export const filters = [
  { label: 'Todas', status: 'ALL' },
  { label: 'Agendadas', status: 'PENDING' },
  { label: 'Realizadas', status: 'COMPLETED' },
  { label: 'Canceladas', status: 'CANCELED' },
  { label: 'Não realizadas', status: 'UNREALIZED' },
]

export const schedules: IAppointmentDetails[] = [
  {
    id: 123456,
    status: 'PENDING',
    professional: {
      id: 41316,
      specialty: 'Cardiologia',
      name: 'Dr. Roberto Oliveira Andrade',
      type: 'DOCTOR',
    },
    appointmentDate: `20/${formattedDayMonth}/${year} 17:50`,
  },
  {
    id: 123456,
    status: 'COMPLETED',
    professional: {
      id: 41316,
      specialty: 'Psicologo',
      name: 'Dr. Bruno Farias',
      type: 'PSYCHOLOGIST',
    },
    appointmentDate: `02/${formattedDayMonth}/${year} 17:50`,
  },
  {
    id: 123456,
    status: 'CANCELED',
    professional: {
      id: 41316,
      specialty: 'Cardiologia',
      name: 'Dr. Roberto Oliveira Andrade',
      type: 'DOCTOR',
    },
    appointmentDate: `15/${formattedDayMonth}/${year} 17:50`,
  },
  {
    id: 123456,
    status: 'UNREALIZED',
    professional: {
      id: 41316,
      specialty: 'Cardiologia',
      name: 'Dr. Roberto Oliveira Andrade',
      type: 'DOCTOR',
    },
    appointmentDate: `15/${formattedDayMonth}/${year} 17:50`,
  },
]

export const dateAndTimeAvailableResponse: BaseResponseBack<IDataAndTimeAvailableResponse> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    schedules: [
      {
        date: `26/${formattedDayMonth}/${year}`,
        times: ['08:00', '08:20', '08:40'],
      },
      {
        date: `27/${formattedDayMonth}/${year}`,
        times: [''],
      },
      {
        date: `28/${formattedDayMonth}/${year}`,
        times: ['08:00'],
      },
    ],
  },
}

export const dateAndTimeAvailable = [
  {
    date: `26/${formattedDayMonth}/${year}`,
    times: ['08:00', '08:20', '08:40'],
  },
  {
    date: `27/${formattedDayMonth}/${year}`,
    times: [''],
  },
  {
    date: `28/${formattedDayMonth}/${year}`,
    times: ['08:00'],
  },
]

export const specialtiesListResponse: BaseResponseBack<ISpecialtiesResponse> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    specialties: [
      {
        id: 1,
        description: 'Cardiologia',
      },
      {
        id: 17,
        description: 'Psiquiatria',
      },
      {
        id: 2,
        description: 'Clínica geral',
      },
      {
        id: 3,
        description: 'Neurologia',
      },
      {
        id: 29,
        description: 'Cirurgia geral',
      },
    ],
  },
}

export const realizedAppointments: IRealizedAppointmentsResponse = {
  appointments: [
    {
      id: 86482,
      appointmentDate: '2023-03-10T16:20:00',
      professional: {
        id: 98726,
        name: 'Henry Jeckyll',
        type: 'DOCTOR',
        specialty: 'Cardiologia',
        councilNumber: '123456',
        councilUf: 'PE',
      },
      isSpontaneousDemand: false,
      appointmentObjective: 'ORIENTACAO',
    },
  ],
}

export const professionalsListResponse: BaseResponseBack<IProfessionalsResponse> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    professionals: [
      {
        id: 42,
        name: 'Carlos Chagas',
        specialty: 'Fisioterapeuta/TO',
        councilNumber: '45546',
        councilUf: 'RJ',
      },
    ],
  },
}
