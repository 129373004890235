import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TaggingEnum } from 'src/enums/TaggingEnum'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'

export function PathQueryParams() {
  const dispatch = useDispatch()
  const location = useLocation()

  const whitelist = ['/']
  const conexaDomain = 'conexasaude.com.br'
  const psicologiaDomain = 'psicologiaviva.com.br'

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      setPathParams(location.pathname, searchParams)
    }
  }

  const isAllowedDomain = (url: string) => {
    const urlSplit = url.split('.')
    const urlLen = urlSplit.length
    const rootDomain = `${urlSplit[urlLen - 3]}.${urlSplit[urlLen - 2]}.${urlSplit[urlLen - 1]}`

    return rootDomain === conexaDomain || rootDomain === psicologiaDomain
  }

  const validateUrl = (url: string) => {
    try {
      if (url) {
        const urlObj = new URL(url)

        BaseBridge.requestAnalytics(TaggingEnum.C_DOUTORINTER_VIDEOCALL_URL, {
          url: urlObj.host,
        })

        return urlObj.protocol === 'https:' && isAllowedDomain(urlObj.host) ? url : undefined
      }
    } catch (error) {
      BaseBridge.requestAnalytics(TaggingEnum.C_DOUTORINTER_INVALID_VIDEOCALL_URL, {
        url,
      })
    }

    return undefined
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    if (params.getAll.length > 0 && pathname === '/') {
      const videocallRedirectParams = {
        videocall: validateUrl(params.get('videocall') || ''),
        doctorName: params.get('doctorName') || undefined,
        doctorPhoto: params.get('doctorPhoto') || undefined,
      }

      const appointmentPathParams = {
        appointmentType: params.get('appointmentType') || undefined,
      }

      const prefixParams = {
        prefix: params.get('prefix') || undefined,
      }

      const themeParams = {
        theme: params.get('theme') || undefined,
      }

      const utmCampaignParams = {
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      if (videocallRedirectParams.videocall) {
        dispatch(PathParamsActions.setVideoCallRedirectParams(videocallRedirectParams))
      }

      if (appointmentPathParams.appointmentType) {
        dispatch(PathParamsActions.setAppointmentPathParams(appointmentPathParams.appointmentType))
      }

      if (themeParams.theme) {
        dispatch(PathParamsActions.setThemePathParams(themeParams.theme))
      }

      if (prefixParams.prefix) {
        dispatch(PathParamsActions.setPrefixPathParams(prefixParams.prefix))
      }

      if (utmCampaignParams.utm_campaign) {
        dispatch(PathParamsActions.setUtmCampaignPathParams(utmCampaignParams.utm_campaign))
      }
    }
  }

  return handler
}
