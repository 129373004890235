import { AnyAction } from 'redux'
import EmergencyCareService from 'src/services/EmergencyCareService'
import { call, put, takeLatest } from 'typed-redux-saga'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'

import { MedicinesActions } from './actions'
import { MedicinesTypes } from './types'

function* getMedicinesList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { medicine } = payload

    const response = yield* call(EmergencyCareService.getMedicinesList, medicine)

    yield* put(MedicinesActions.setMedicinesList(response.object.medicines))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'MedicinesActions.getMedicinesList')
  }
}

export default function* watchMedicines() {
  yield* takeLatest(MedicinesTypes.GET_MEDICINES_LIST_REQUEST, getMedicinesList)
}
