import { AnyAction } from 'redux'
import SubscriptionService from 'src/services/SubscriptionService'
import { call, put, takeLatest } from 'typed-redux-saga'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'

import { SpecialtiesActions } from './actions'
import { SpecialtiesTypes } from './types'

function* getSpecialties({ payload }: AnyAction) {
  try {
    if (payload) yield* put(LoadingActions.show())

    const response = yield* call(SubscriptionService.getSpecialties)

    yield* put(SpecialtiesActions.setSpecialtiesList(response.object.specialties))
    yield* put(SpecialtiesActions.setSpontaneousDemandsList(response.object.spontaneousDemands))
    yield* put(SpecialtiesActions.setHighlightsList(response.object.highlights))

    if (payload) yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SpecialtiesActions.getSpecialties')
  }
}

export default function* watchSpecialties() {
  yield* takeLatest(SpecialtiesTypes.GET_SPECIALTIES_REQUEST, getSpecialties)
}
