import { PMedium, PSmall } from 'src/styles/commons'

import { InterUIListDescription } from '@interco/inter-ui-react-lib'

interface PaymentMethodProps {
  title: string
  subTitle: string
  iconLeft: JSX.Element
  iconRight: JSX.Element
  border: string
  openChoicePaymentForm: () => void
}

export const PaymentMethod = ({
  title,
  subTitle,
  iconLeft,
  iconRight,
  border,
  openChoicePaymentForm,
}: PaymentMethodProps) => (
  <InterUIListDescription
    iconLeft={iconLeft}
    iconRight={iconRight}
    withBorder
    alignAllItems="center"
    borderColor={border}
    data-testid="payment-methods"
    onClick={openChoicePaymentForm}
  >
    <PMedium marginBottom="0" bold>
      {title}
    </PMedium>
    {subTitle !== '' && <PSmall margin="8px 0 0">{subTitle}</PSmall>}
  </InterUIListDescription>
)
