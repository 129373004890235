import styled from 'styled-components'

export const InformationBallon = styled.div<{ hidden?: boolean }>`
  ${(props) => props.hidden && `visibility: hidden`};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto 14px;
`

export const BoxBallon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  background: ${(props) => props.theme.colors.neutral.grayscale.A100};
  border-radius: 8px;
  position: relative;

  p:first-child {
    text-transform: capitalize;
  }

  ::before {
    content: '';
    border: 5px solid transparent;
    border-top-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
    position: absolute;
    bottom: -10px;
  }
`

export const ContainerImg = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`

export const Doctor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary.A100};
`
export const DoctorImg = styled.img`
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
`

export const PositionLoading = styled.div`
  position: absolute;
  bottom: -10px;
`
