import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { IUser, UserTypes } from './types'

const getUser = (state: RootState) => state.user.user

const setUser = (data: IUser) => action(UserTypes.SET_USER, data)

export const UserActions = {
  getUser,
  setUser,
}
