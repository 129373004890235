import { Reducer } from 'redux'

import { PersistedCacheState, PersistedCacheTypes } from './types'

const INITIAL_STATE: PersistedCacheState = {
  hasAppointmentsListCache: false,
  hasCameraFirstAccess: false,
}

export const reducer: Reducer<PersistedCacheState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PersistedCacheTypes.STORE:
      return { ...state, hasAppointmentsListCache: true }
    case PersistedCacheTypes.CLEAR:
      return { ...state, hasAppointmentsListCache: false }
    case PersistedCacheTypes.SET_CAMERA_FIRST_ACCESS:
      return { ...state, hasCameraFirstAccess: true }
    default:
      return state
  }
}

export default reducer
