import React, { Dispatch, SetStateAction } from 'react'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import BaseBridge from 'src/config/bridge/BaseBridge'
import { PMedium } from 'src/styles/commons'

import { Button } from '..'

interface ExitScheduleBSProps {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export const ExitScheduleBS: React.FC<ExitScheduleBSProps> = ({ toggleState }) => {
  const [toggle, setToggle] = toggleState

  return (
    <InterUIBottomSheet
      title="Deseja cancelar o agendamento?"
      toggle={toggle}
      onHide={() => setToggle(false)}
      data-testid="exit-bs-schedule"
    >
      <PMedium margin="4px 0 16px" scale={400}>
        Voltando para a home nesse momento, resultará no cancelamento do agendamento
      </PMedium>
      <Button onClick={() => BaseBridge.requestGoBack()} data-testid="test-bs-btnRequestGoBack">
        Voltar para a home
      </Button>
      <Button
        onClick={() => setToggle(false)}
        variant="secondary"
        margin="16px 0 0"
        data-testid="test-bs-buttonBack"
      >
        Voltar
      </Button>
    </InterUIBottomSheet>
  )
}
