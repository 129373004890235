import React from 'react'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

import { LoadItemSymptom } from '../components/LoadItemSymptom/LoadItemSymptom'
import { SkeletonContent } from '../Skeleton.styles'

export const LoadSymptoms: React.FC = () => (
  <SkeletonContent data-testid="loading-symptoms">
    <SkeletonContent>
      <InterUISkeleton width="80%" height="24px" marginBottom="16px" />
      <InterUISkeleton marginBottom="4px" />
      <InterUISkeleton width="40%" marginBottom="24px" />
    </SkeletonContent>

    <InterUISkeleton height="48px" marginBottom="32px" />

    <SkeletonContent>
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
      <LoadItemSymptom />
    </SkeletonContent>
  </SkeletonContent>
)
