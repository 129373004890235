import { Reducer } from 'redux'

import { MedicinesTypes, MedicinesState } from './types'

const INITIAL_STATE: MedicinesState = {
  medicinesList: [],
  selectedMedicines: [],
}

const reducer: Reducer<MedicinesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MedicinesTypes.SET_MEDICINES_LIST:
      return { ...state, medicinesList: action.payload }
    case MedicinesTypes.SET_SELECTED_MEDICINES:
      return { ...state, selectedMedicines: action.payload }

    default:
      return state
  }
}

export default reducer
