import { Reducer } from 'redux'

import {
  IAppointmentDetails,
  IDoctorDetails,
  IProfessional,
  ISpecialty,
  IType,
  SchedulingState,
  SchedulingTypes,
} from './types'

const INITIAL_STATE: SchedulingState = {
  professionalType: {} as IType,
  appointmentType: {} as IType,
  selectedDateTime: '',
  specialtiesList: [],
  doctorsList: [],
  professionalsList: [],
  doctorDetails: {} as IDoctorDetails,
  selectedSpecialty: {} as ISpecialty,
  selectedProfessional: {} as IProfessional,
  dataAndTimeAvailable: [],
  appointmentList: [],
  selectedAppointment: {} as IAppointmentDetails,
  realizedAppointments: [],
}

const reducer: Reducer<SchedulingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SchedulingTypes.SET_PROFESSIONAL_TYPE:
      return { ...state, professionalType: action.payload }
    case SchedulingTypes.SET_APPOINTMENT_TYPE:
      return { ...state, appointmentType: action.payload }
    case SchedulingTypes.SET_SELECTED_DATE_TIME:
      return { ...state, selectedDateTime: action.payload }
    case SchedulingTypes.SET_SPECIALTIES_LIST:
      return { ...state, specialtiesList: action.payload }
    case SchedulingTypes.SET_DOCTORS_LIST:
      return { ...state, doctorsList: action.payload }
    case SchedulingTypes.SET_PROFESSIONAL_LIST:
      return { ...state, professionalsList: action.payload }
    case SchedulingTypes.SET_DOCTOR_DETAILS:
      return { ...state, doctorDetails: action.payload }
    case SchedulingTypes.SET_SELECTED_SPECIALTY:
      return { ...state, selectedSpecialty: action.payload }
    case SchedulingTypes.SET_SELECTED_PROFESSIONAL:
      return { ...state, selectedProfessional: action.payload }
    case SchedulingTypes.SET_DATE_AND_TIME_AVAILABLE:
      return { ...state, dataAndTimeAvailable: action.payload }
    case SchedulingTypes.SET_APPOINTMENTS_LIST:
      return { ...state, appointmentList: action.payload }
    case SchedulingTypes.SET_SELECTED_APPOINTMENT:
      return { ...state, selectedAppointment: action.payload }
    case SchedulingTypes.SET_REALIZED_APPOINTMENTS:
      return { ...state, realizedAppointments: action.payload }
    default:
      return state
  }
}

export default reducer
