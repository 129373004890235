export enum AttachFilesTypes {
  SET_SELECTED_FILES_ATTACHED = `@files/SET_SELECTED_FILES_ATTACHED`,
  UPLOAD_SELECTED_FILES_ATTACHED = `@files/UPLOAD_SELECTED_FILES_ATTACHED`,
  SET_UPLOAD_FILES_RESULT = `@files/SET_UPLOAD_FILES_RESULT`,
}

export interface IAttachedFile {
  id: number
  name: string
  fileContent: string
  fileExtension: string
}

export interface IAttachedFiles {
  files: IAttachedFile[]
}

export interface IFileUploadRequest {
  protocolId: string
  files: IAttachedFile[]
}

export interface IUploadFilesResponse {
  files: [
    {
      id: number
      isAttached: boolean
    },
  ]
}
export interface IUploadFilesResult {
  success: { id: number; name: string }[]
  fail: { id: number; name: string }[]
}

export interface FilesState {
  readonly selectedAttachedFiles: IAttachedFiles
  readonly uploadedFilesResult: IUploadFilesResult
}
