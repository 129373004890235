import React from 'react'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent } from '../Skeleton.styles'
import { ContentWaitingLoading } from './LoadWaitingTime.styles'

export const LoadWaitingTime: React.FC = () => (
  <ContentWaitingLoading data-testid="loading-waiting-time">
    <SkeletonContent>
      <InterUISkeleton width="64px" height="64px" variant="circle" />
      <InterUISkeleton width="60%" height="24px" marginBottom="8px" />
      <InterUISkeleton width="80%" height="32px" marginBottom="16px" />
      <InterUISkeleton height="16px" />
    </SkeletonContent>

    <SkeletonContent>
      <InterUISkeleton height="48px" marginBottom="16px" />
      <InterUISkeleton height="48px" />
    </SkeletonContent>
  </ContentWaitingLoading>
)
