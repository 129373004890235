import React, { HTMLAttributes, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { IAppointmentDetails } from 'src/store/ducks/scheduling/types'
import { PMedium, PSmall } from 'src/styles/commons'
import {
  countDays,
  formatDateReschedule,
  formatHours,
  removeHoursFromDate,
} from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIBottomSheet,
  InterUIBox,
  InterUIListDescription,
  InterUISeparator,
  InterUITag,
} from '@interco/inter-ui-react-lib'

import { InfoSchedule } from './CardSchedule.style'

import { Button } from '..'

interface CardScheduleProps extends HTMLAttributes<HTMLDivElement> {
  appointmentDetails: IAppointmentDetails
  showStatus: boolean
  filterStatus?: string
  onClickedDetail?: () => void
}

export const CardSchedule: React.FC<CardScheduleProps> = ({
  appointmentDetails,
  showStatus,
  filterStatus,
  onClickedDetail,
  ...rest
}) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconDoctor = <Icons.Doctor color={theme.colors.primary.A500} />
  const iconAgenda = (
    <Icons.Agenda width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconPending = (
    <Icons.Pending width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconArrowWhite = (
    <Icons.ChevronRight width={16} height={16} color={theme.colors.base.white} />
  )

  const [showButton, setShowButton] = useState(false)
  const [bottomSheet, setBottomSheet] = useState(false)
  const [minutesForConsultation, setMinutesForConsultation] = useState(0)
  const [userCalibratedTimeZone, setUserCalibratedTimezone] = useState(0)
  const SERVER_TIMEZONE_OFFSET = 180
  const ONE_DAY_MINUTES = 1440

  const userDate = new Date()
  const userDateLocale = userDate.toLocaleDateString('pt-Br')

  const serverEquivalentLocale = userDate.toLocaleDateString('pt-Br', {
    timeZone: 'America/Sao_Paulo',
  })

  useEffect(() => {
    setMinutesForConsultation(0)
    const userTimeZoneOffset = userDate.getTimezoneOffset()
    const calibratedTimeZone = userTimeZoneOffset - SERVER_TIMEZONE_OFFSET

    setUserCalibratedTimezone(calibratedTimeZone)
  }, [filterStatus])

  useEffect(() => {
    let intervalId = {} as NodeJS.Timer

    if (
      appointmentDetails.status === 'PENDING' &&
      appointmentDetails.appointmentDate.split(' ')[0] === serverEquivalentLocale &&
      minutesForConsultation >= -20 &&
      minutesForConsultation <= 10
    ) {
      intervalId = setInterval(() => {
        const currentTime = `${userDate.getHours()}:${`0${userDate.getMinutes()}`.slice(-2)}`
        const initial = appointmentDetails.appointmentDate.split(' ')[1]

        const splInicial = initial.split(':')
        const splFinal = currentTime.split(':')

        const initialMin = Number(Number(splInicial[0]) * 60) + Number(splInicial[1])
        const finalMin = Number(Number(splFinal[0]) * 60) + Number(splFinal[1])

        const totalMinutes = calibratedTimeDifference(initialMin, finalMin)

        setMinutesForConsultation(totalMinutes)
      }, 1000)
    } else {
      setShowButton(false)
    }

    return () => clearInterval(intervalId)
  }, [minutesForConsultation, appointmentDetails.appointmentDate, appointmentDetails.status])

  const calibratedTimeDifference = (initialMin: number, finalMin: number) => {
    const dayDifferences = countDays(userDateLocale, serverEquivalentLocale)
    let differentDateCalibration = 0

    if (dayDifferences < 0) {
      differentDateCalibration = ONE_DAY_MINUTES
    } else if (dayDifferences > 0) {
      differentDateCalibration = -ONE_DAY_MINUTES
    }

    const totalMinutes =
      Number(finalMin - initialMin) + userCalibratedTimeZone + differentDateCalibration
    if (totalMinutes >= -10 && totalMinutes <= 10) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }

    return totalMinutes
  }

  const handleClickEnterConsultation = () => {
    const currentTime = `${userDate.getHours()}:${`0${userDate.getMinutes()}`.slice(-2)}`

    const initial = appointmentDetails.appointmentDate.split(' ')[1]

    const splInicial = initial.split(':')
    const splFinal = currentTime.split(':')

    const initialMin = Number(Number(splInicial[0]) * 60) + Number(splInicial[1])
    const finalMin = Number(Number(splFinal[0]) * 60) + Number(splFinal[1])

    const totalMinutes = calibratedTimeDifference(initialMin, finalMin)

    if (totalMinutes > 5) {
      setBottomSheet(true)
    } else {
      dispatch(SchedulingActions.setSelectedAppointment(appointmentDetails))
      dispatch(
        SchedulingActions.setAppointmentType({
          history,
          pathname: TypesRoutes.WAITING_ROOM,
          type: 'SCHEDULED',
        }),
      )
    }
  }

  const renderInterTag = (value: string) => {
    let tag: React.ReactElement

    switch (value) {
      case 'COMPLETED':
        tag = (
          <InterUITag
            margin="0 0 16px"
            backgroundColor={theme.colors.feedback.success.A500}
            color={theme.colors.theme}
          >
            Realizada
          </InterUITag>
        )
        break

      case 'UNREALIZED':
        tag = (
          <InterUITag
            margin="0 0 16px"
            backgroundColor={theme.colors.feedback.error.A500}
            color={theme.colors.theme}
          >
            Não realizada
          </InterUITag>
        )
        break

      case 'CANCELED':
        tag = (
          <InterUITag
            margin="0 0 16px"
            backgroundColor={theme.colors.neutral.grayscale.A100}
            color={theme.colors.neutral.grayscale.A500}
          >
            Cancelada
          </InterUITag>
        )
        break
      default:
        tag = (
          <InterUITag
            margin="0 0 16px"
            backgroundColor={theme.colors.primary.A500}
            color={theme.colors.theme}
          >
            Agendada
          </InterUITag>
        )
        break
    }

    return tag
  }

  const renderInfoAgenda = (
    <>
      {(appointmentDetails.status === 'PENDING' || appointmentDetails.status === 'CANCELED') && (
        <>
          <InfoSchedule>
            {iconAgenda}
            <PSmall marginBottom="0" scale={400}>
              {formatDateReschedule(appointmentDetails.appointmentDate)}
            </PSmall>
          </InfoSchedule>
          <InfoSchedule>
            {iconPending}
            <PSmall marginBottom="0" scale={400}>
              {formatHours(appointmentDetails.appointmentDate)} (Horário de Brasília)
            </PSmall>
          </InfoSchedule>
        </>
      )}

      {appointmentDetails.status === 'COMPLETED' && (
        <InfoSchedule>
          {iconAgenda}
          <PSmall marginBottom="0" scale={400}>
            Atendimento realizado {removeHoursFromDate(appointmentDetails.appointmentDate)} às{' '}
            {formatHours(appointmentDetails.appointmentDate)} <br /> (Horário de Brasília)
          </PSmall>
        </InfoSchedule>
      )}

      {appointmentDetails.status === 'UNREALIZED' && (
        <InfoSchedule>
          {iconAgenda}
          <PSmall marginBottom="0" scale={400}>
            Atendimento não realizado
          </PSmall>
        </InfoSchedule>
      )}
    </>
  )

  return (
    <>
      <InterUIBox direction="column" align="stretch" margin="0 0 16px" {...rest}>
        <div
          aria-hidden
          onClick={
            showStatus && appointmentDetails.status !== 'COMPLETED' ? onClickedDetail : undefined
          }
        >
          {showStatus && renderInterTag(appointmentDetails.status)}

          <InterUIListDescription
            alignAllItems="center"
            iconLeft={iconDoctor}
            iconRight={
              showStatus && appointmentDetails.status !== 'COMPLETED' ? iconArrow : undefined
            }
          >
            <PMedium marginBottom="4px" bold>
              {appointmentDetails.professional.specialty}
            </PMedium>
            <PSmall marginBottom="0px" scale={400}>
              {appointmentDetails.professional.name}
            </PSmall>
          </InterUIListDescription>
          <InterUISeparator variant="small" margin="24px 0" />
          {renderInfoAgenda}
        </div>
        {showButton && (
          <Button
            variant="primary"
            small
            alignContent="space-between"
            margin="16px 0 0"
            onClick={handleClickEnterConsultation}
          >
            Entrar na consulta {iconArrowWhite}
          </Button>
        )}
      </InterUIBox>

      <InterUIBottomSheet
        title="Consulta"
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
        data-testid="bs-consult"
      >
        <PMedium margin="4px 0 44px" scale={400}>
          O horário da consulta já passou.
        </PMedium>
        <Button data-testid="btn-bottom-sheet" onClick={() => setBottomSheet(false)}>
          Continuar
        </Button>
      </InterUIBottomSheet>
    </>
  )
}
