import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, LoadSymptoms } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SymptomsActions } from 'src/store/ducks/symptoms/actions'
import { ISymptoms } from 'src/store/ducks/symptoms/types'
import { ContainerFullPage, H2, PSmall, SearchContainer } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUICheckBox,
  InterUIContainer,
  InterUIProgressBar,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

export const Symptoms: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const iconSearch = <Icons.Search color={theme.colors.primary.A500} className="inter-ic-search" />

  const searchRef = useRef<HTMLInputElement>(null)

  const symptomsList = useSelector(SymptomsActions.getSymptomsList)
  const selectedSymptoms = useSelector(SymptomsActions.getSelectedSymptoms)
  const selectedOtherSymptoms = useSelector(SymptomsActions.getSelectedOtherSymptoms)

  const [emptySearch, setEmptySearch] = useState<boolean>(true)
  const [symptomsChecked, setSymptomsChecked] = useState<ISymptoms[]>([])
  const [symptomsOtherChecked, setSymptomsOtherChecked] = useState<boolean>(false)
  const [symptomsListView, setSymptomsListView] = useState<ISymptoms[]>([])

  let debounce: NodeJS.Timeout

  useEffect(() => {
    setSymptomsListView([...symptomsList])
    setSymptomsChecked([...selectedSymptoms])
  }, [selectedSymptoms, symptomsList])

  useEffect(() => {
    if (selectedOtherSymptoms) {
      setSymptomsOtherChecked(true)
    } else {
      setSymptomsOtherChecked(false)
    }
  }, [selectedOtherSymptoms, symptomsOtherChecked])

  useBasePage({
    navbarTitle: PageTitles.CONSULT_NOW,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const onContinue = () => {
    const content_name = `${symptomsChecked.map((symptom) => symptom.description)}${
      selectedOtherSymptoms && symptomsChecked.length > 0
        ? `,${selectedOtherSymptoms}`
        : `${selectedOtherSymptoms}`
    }`

    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_SINTOMAS_T_CONTINUAR.name, {
      content_name,
    })
    dispatch(SymptomsActions.setSelectedSymptoms(symptomsChecked))
    history.push(TypesRoutes.MEDICINES)
  }

  const goOthers = () => {
    if (!symptomsOtherChecked) {
      dispatch(SymptomsActions.setSelectedSymptoms(symptomsChecked))
      history.push(TypesRoutes.OTHER_SYMPTOMS)
      setSymptomsOtherChecked(true)
    } else {
      dispatch(SymptomsActions.setSelectedOtherSymptoms(''))
      setSymptomsOtherChecked(false)
    }
  }

  const deleteSearchValue = () => {
    setEmptySearch(true)
    setSymptomsListView(symptomsList)

    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  const keyUpSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const search = RemoveAccents(value, true)
    setEmptySearch(!search)

    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (search) {
        const updatedListView = symptomsList.filter((item) =>
          RemoveAccents(item.description, true).includes(search),
        )

        setSymptomsListView(updatedListView)
      } else {
        setSymptomsListView(symptomsList)
      }
    }, 150)
  }

  const symptomsSearch = (
    <>
      <SearchContainer>
        <input
          ref={searchRef}
          data-testid="search"
          type="search"
          name="symptoms-search"
          placeholder="Pesquisar"
          autoComplete="off"
          onKeyUp={keyUpSearch}
          aria-labelledby="symptoms-search-button"
        />

        {emptySearch ? (
          iconSearch
        ) : (
          <Icons.Exit
            color={theme.colors.primary.A500}
            className="inter-ic-exit"
            data-testid="deleteSearchValue"
            onClick={deleteSearchValue}
          />
        )}
      </SearchContainer>
    </>
  )

  const selectSymptoms = (item: ISymptoms) => {
    if (!symptomsChecked.includes(item)) {
      symptomsChecked.push(item)
    } else {
      const index = symptomsChecked.indexOf(item)
      symptomsChecked.splice(index, 1)
    }

    setSymptomsChecked([...symptomsChecked])
  }

  const symptomWasSelected = (item: ISymptoms) => (item ? symptomsChecked.includes(item) : false)

  const renderSymptomsList = symptomsListView
    .filter((value) => value.value !== 'OUTROS')
    .map((item, index) => {
      const key = `symptoms-${index}`

      return (
        <InterUICheckBox
          variant="border-bottom"
          id={key}
          key={key}
          name={`checkbox-${key}`}
          value={item.value}
          onChange={() => selectSymptoms(item)}
          checked={symptomWasSelected(item)}
        >
          <PSmall marginBottom="0">{item.description}</PSmall>
        </InterUICheckBox>
      )
    })

  const stickyFooter = (
    <Button margin="24px" onClick={onContinue}>
      Continuar
    </Button>
  )

  return symptomsList && symptomsList.length > 0 ? (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar progress="25%" />
      <ContainerFullPage margin="0 24px">
        <H2 margin="24px 0px">O que você está sentindo?</H2>

        {symptomsSearch}
        {renderSymptomsList}

        <InterUICheckBox
          variant="default"
          id="other-symptoms"
          key="other-symptoms"
          name="checkbox-other-symptoms"
          value={selectedOtherSymptoms}
          onChange={goOthers}
          checked={symptomsOtherChecked}
        >
          <PSmall marginBottom="0">Outro</PSmall>
        </InterUICheckBox>
      </ContainerFullPage>
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="24px" margin="24px 24px 0" overflowHidden>
      <LoadSymptoms />
    </ContainerFullPage>
  )
}
