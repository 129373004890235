/**
 * Define o scroll no topo da página.
 */
export const scrollToTop = () => {
  window.scrollTo(0, 0)
}

/**
 * Método para validação do Nome Completo. Retorna 'true' se a validação for bem sucedida e 'false' se o Nome Completo for inválido.
 * @param fullName Nome Completo a ser verificado.
 * @returns true ou false.
 */
export const validateFullName = (fullName: string) => {
  const regexFullName = /^[A-ZÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-ZÀ-Ÿ][A-zÀ-ÿ']+$/

  if (regexFullName.test(fullName.trim())) {
    return true
  }
  return false
}

/**
 * Método para validação da Data de nascimento brasileiro. Retorna 'true' se a validação for bem sucedida e 'false' se a Data de nascimento for inválido.
 * @param birthDate Data de nascimento a ser verificado.
 * @returns true ou false.
 */
export const validateBirthDate = (birthDate: string) => {
  const regexBirthDate =
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g

  if (regexBirthDate.test(birthDate)) {
    return true
  }
  return false
}

/**
 * Método para validação do CPF brasileiro. Retorna 'true' se a validação for bem sucedida e 'false' se o CPF for inválido.
 * @param cpf cpf a ser verificado.
 * @returns true ou false.
 */
export const validateCpf = (cpf: string) => {
  if (cpf === null) {
    return false
  }
  if (cpf.length !== 11) {
    return false
  }
  if (
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let numero = 0
  let caracter = ''
  const numeros = '0123456789'
  let j = 10
  let somatorio = 0
  let resto = 0
  let digito1 = 0
  let digito2 = 0
  let cpfAux = ''
  cpfAux = cpf.substring(0, 9)
  for (let i = 0; i < 9; i += 1) {
    caracter = cpfAux.charAt(i)
    if (numeros.search(caracter) === -1) {
      return false
    }
    numero = Number(caracter)
    somatorio += numero * j
    j -= 1
  }
  resto = somatorio % 11
  digito1 = 11 - resto
  if (digito1 > 9) {
    digito1 = 0
  }
  j = 11
  somatorio = 0
  cpfAux += digito1
  for (let i = 0; i < 10; i += 1) {
    caracter = cpfAux.charAt(i)
    numero = Number(caracter)
    somatorio += numero * j
    j -= 1
  }
  resto = somatorio % 11
  digito2 = 11 - resto
  if (digito2 > 9) {
    digito2 = 0
  }
  cpfAux += digito2
  if (cpf !== cpfAux) {
    return false
  }

  return true
}

/**
 * Método para validação do Email. Retorna 'true' se a validação for bem sucedida e 'false' se o Email for inválido.
 * @param email email a ser verificado.
 * @returns true ou false.
 */
export const validateEmail = (email: string) => {
  const regexEmail = /\S+@\S+\.\S+/

  if (regexEmail.test(email)) {
    return true
  }
  return false
}

/**
 * Método para validação do Telefone brasileiro. Retorna 'true' se a validação for bem sucedida e 'false' se o Telefone for inválido.
 * @param phone número de telefone a ser verificado.
 * @returns true ou false.
 */
export const validatePhone = (phone: string) => {
  const regexPhone =
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

  if (regexPhone.test(phone)) {
    return true
  }
  return false
}

/**
 * Método para validação da idade. Retorna 'true' se for menor de 18 anos e 'false' se for maior de 18 anos.
 * @param birthDate data de nascimento a ser comparado.
 * @returns true ou false.
 */
export const validateAge = (birthDate: string) => {
  if (validateBirthDate(birthDate)) {
    const today = new Date()
    const dateNsc = new Date(invertDate(birthDate))
    let age = today.getFullYear() - dateNsc.getFullYear()
    const month = today.getMonth() - dateNsc.getMonth()

    if (month < 0 || (month === 0 && today.getDate() < dateNsc.getDate())) {
      age -= 1
    }

    if (age < 18) {
      return true
    }
    return false
  }

  return false
}

export const isStringNotEmpty = (str?: string) =>
  str !== undefined && str !== null && str !== '' && str.length > 0

/**
 * Retira caracteres especiais (incluindo espaço em branco) de uma determinada string
 * @param str string contendo caracteres especiais
 * @returns string sem caracteres especiais
 */
export const clearFormatting = (str: string) => str.replace(/[^a-zA-Z0-9]+/g, '')

/**
 * Formata uma determinada string no padrão dd/mm/yyyy para o padrão ISO 8601 yyyy-mm-dd
 * @param str data no formato dd/mm/yyyy
 * @returns data no formato yyyy-mm-dd
 */
export const formatDateToISO8601 = (str: string) => {
  let date = timestampToDate(str)

  if (isStringNotEmpty(date) && date.includes('/')) {
    const dates = date.split('/')
    const year = dates[2]
    const month = dates[1]
    const day = dates[0]

    date = `${year}-${month}-${day}`
  }
  return date
}

export const firstName = (str: string) => {
  if (isStringNotEmpty(str)) {
    const names = str.split(' ')

    return names[0]
  }

  return str
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy para o padrão yyyy/mm/dd
 * @param str data no formato dd/mm/yyyy
 * @returns data no formato yyyy/mm/dd
 */
export const invertDate = (str: string) => {
  if (isStringNotEmpty(str)) {
    const dates = str.split('/')
    const year = dates[2]
    const month = dates[1]
    const day = dates[0]

    str = `${year}/${month}/${day}`
  }

  return str
}

/**
 * Formata um numero para moeda Real
 * @param data numero a ser formatado
 * @returns uma string com o valor formatado em Reais
 */
export const numberFormat = (data: number | string) => {
  try {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    const dataFormatted = Number(data)
    return formatter.format(dataFormatted)
  } catch {
    return data.toString()
  }
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy para o padrão dia de mês
 * @param str data no formato dd/mm/yyyy
 * @returns data no formato dia de mês
 */
export const formatDayMouth = (str: string) => {
  try {
    const date = str.split(' ')

    const formatEN = invertDate(date[0])

    const localeDate = new Date(formatEN).toLocaleDateString('pt-br', {
      day: 'numeric',
      month: 'long',
    })

    return localeDate
  } catch {
    return str
  }
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy 00:00 para o padrão 00h00
 * @param str hora no formato dd/mm/yyyy 00:00
 * @returns hora no formato 00h00
 */
export const formatHours = (str: string) => {
  try {
    const time = str.split(' ')

    const hours = time[1].split(':').join('h')

    return hours
  } catch {
    return str
  }
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy 00:00 para o padrão dd/mm/yyyy
 * @param str data no formato dd/mm/yyyy 00:00
 * @returns data no formato dd/mm/yyyy
 */
export const removeHoursFromDate = (str: string) => {
  try {
    const time = str.split(' ')

    const hours = time[0]

    return hours
  } catch {
    return str
  }
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy 00:00 para o padrão dia da semana, dia
 * @param str data no formato dd/mm/yyyy 00:00
 * @returns data no formato dia da semana, dia
 */
export const formatDateSchedule = (str: string) => {
  const date = str.split(' ')
  const formatEN = invertDate(date[0])
  const splitDay = new Date(formatEN)
    .toLocaleDateString('pt-br', {
      weekday: 'long',
    })
    .split('-')

  const day = new Date(formatEN).toLocaleDateString('pt-br', {
    day: '2-digit',
    month: '2-digit',
  })

  const weekday = splitDay[0].charAt(0).toUpperCase() + splitDay[0].slice(1)
  const localeDate = `${weekday}, ${day}`

  return localeDate
}

/**
 * Formata uma determinada string no padrão dd/mm/yyyy 00:00 para o padrão DD de MM, dia da semana
 * @param str data no formato dd/mm/yyyy 00:00
 * @returns data no formato 15 de Setembro, sexta-feira
 */
export const formatDateReschedule = (str: string) => {
  try {
    const date = str.split(' ')
    const formatEN = invertDate(date[0])
    const day = new Date(formatEN).toLocaleDateString('pt-br', {
      day: '2-digit',
      month: 'short',
    })
    const weekday = new Date(formatEN).toLocaleDateString('pt-br', {
      weekday: 'long',
    })

    const localeDate = `${day.replace('.', '')}, ${weekday}`
    return localeDate
  } catch {
    return str
  }
}

/**
 * Converte uma string timestamp para o padrão dd/mm/yyyy
 * @param str string contendo o timestamp
 * @returns data no formato dd/mm/yyyy
 */
export const timestampToDate = (str: unknown) => {
  const value = `${str}`

  if (str && !value.includes('/') && !(value.split('-').length > 2)) {
    let multiplier = 1

    if (value.length < 12) {
      multiplier = 1000
    }

    const timestamp = Number.parseFloat(value) * multiplier
    const date = new Date(timestamp)

    return date.toLocaleString('pt-BR').split(' ')[0]
  }

  return value
}

/**
 * Retorna a data do dia
 */
export const today = new Date().toLocaleDateString('pt-br', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})

/**
 * Contador de dias
 * @param dateInitial string contendo a data inicial
 * @param dateFinal string contendo a data final
 * @param startingCount inicio da contagem (Default: 0)
 * @returns a diferença entre a data inicial e a final. Ex: 10
 */
export const countDays = (dateInitial: string, dateFinal: string, startingCount?: number) => {
  const second = 1000
  const minute = second * 60
  const hour = minute * 60
  const day = hour * 24

  const dateIni = new Date(invertDate(dateInitial))
  const dateEnd = new Date(invertDate(dateFinal))

  const diff = dateEnd.getTime() - dateIni.getTime()

  return startingCount ? Math.floor(diff / day) + startingCount : Math.floor(diff / day)
}

/**
 * Gera id a partir de substring do timestamp corrente
 */
export const generateId = () => Number(Date.now().toString().substring(5, 11))

/**
 * Esta função trata um arquivo base64, removendo o tipo do arquivo (Ex: 'data:image/png;base64'),
 * retornando apenas o conteúdo do mesmo.
 */
export const extractBase64Content = (base64: string | undefined) => base64?.split(',')?.[1]
