/**
 * Rotas disponíveis no telemedicina.
 */
export enum TypesRoutes {
  // HOME
  START = '/',
  HOME_DR_INTER = '/dr-inter',

  // EMERGENCY_CARE
  SYMPTOMS = '/emergency/symptoms',
  OTHER_SYMPTOMS = '/emergency/symptoms/other-symptoms',
  MEDICINES = '/emergency/medicines',
  PAYMENT_CONSULT = '/emergency/payment-consult',
  ATTACH_FILES = '/emergency/attach-files',
  PREPARE_CONSULT = '/emergency/prepare-consult',
  WAITING_TIME = '/emergency/waiting-time',
  APPOINTMENT = '/emergency/appointment',

  // SCHEDULING
  SPECIALTIES = '/appointment-scheduling/specialties',
  PROFESSIONAL = '/appointment-scheduling/professional',
  APPOINTMENT_DATE = '/appointment-scheduling/appointment-date',
  PAYMENT = '/appointment-scheduling/payment',

  // SCHEDULE
  RESCHEDULE = '/schedule/reschedule',
  SCHEDULED_DETAILS = '/schedule/scheduled-card-details',
  CANCELLED_DETAILS = '/schedule/cancelled-card-details',
  UNREALIZED_DETAILS = '/schedule/unrealized-card-details',
  RESCHEDULE_DETAILS = '/schedule/reschedule-detailing',

  // PROFILE
  MY_SUBSCRIPTION_DETAILS = '/profile/my-subscription/my-subscription-details',
  CANCELLATION_SUBSCRIPTION = '/profile/my-subscription/cancellation',
  CANCELLATION_REASON = '/profile/my-subscription/cancellation/cancellation-reason',
  CANCELLATION_CONDITIONS = '/profile/my-subscription/cancellation/cancellation-conditions',
  CANCELLATION_SUCCESS = '/profile/my-subscription/cancellation/cancellation-success',
  CANCELLATION_FINE = '/profile/my-subscription/cancellation/cancellation-fine',
  CANCELLATION_FINE_SUCCESS = '/profile/my-subscription/cancellation/cancellation-fine-success',

  // SUBSCRIPTION
  SUBSCRIPTION = '/subscription',
  SUBSCRIPTION_SUMMARY = '/subscription/subscription-summary',
  SUBSCRIPTION_DETAILS = '/subscription/subscription-details',

  // COMPONENTS
  APPOINTMENT_CANCELLATION_CONFIRMED = '/appointment-cancellation/cancellation-confirmed',
  APPOINTMENT_CANCELLATION = '/appointment-cancellation',
  APPOINTMENT_CONFIRMATION = '/appointment-confirmation',
  DOCUMENT = '/subscription/document',
  WAITING_ROOM = '/waiting-room',
  TAKE_THE_PICTURE = '/take-the-picture',
  ACCREDITED_NETWORK = '/accredited-network',
  MAINTENANCE = '/maintenance',

  // REQUEST_GO_BACK
  REQUEST_GO_BACK = 'requestGoBack',
}
