import BaseService from 'src/config/bridge/BaseService'
import { IUser } from 'src/store/ducks/user/types'

export default class UserService {
  static async getUserInfo(account: string) {
    const headers = {
      'x-inter-user-bank-account': account,
    }
    const response = await BaseService.doExecute<IUser>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/user-info`,
      headers,
    })

    return response
  }
}
