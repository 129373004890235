import FileService from 'src/services/FilesService'
import { call, put, select, takeLatest } from 'typed-redux-saga'

import { HealthcareActions } from '../healthcare/actions'
import { FilesActions } from './actions'
import { AttachFilesTypes, IUploadFilesResponse, IUploadFilesResult } from './types'

function* uploadSelectedFiles() {
  const { files: attachedFiles } = yield* select(FilesActions.getSelectedFilesAttached)

  try {
    const { protocolId } = yield* select(HealthcareActions.getProtocolId)

    if (attachedFiles.length > 0) {
      const response = yield* call(FileService.uploadFiles, {
        protocolId,
        files: attachedFiles,
      })

      const filesResponse: IUploadFilesResponse = response?.object as IUploadFilesResponse

      const successResponseIds = filesResponse.files
        .filter((file) => file.isAttached === true)
        .map((f) => f.id)

      const successFiles = attachedFiles
        .filter((file) => successResponseIds.includes(file.id))
        .map((file) => ({
          id: file.id,
          name: file.name,
        }))

      const failResponseIds = filesResponse.files
        .filter((file) => file.isAttached === false)
        .map((f) => f.id)

      const failFiles = attachedFiles
        .filter((file) => failResponseIds.includes(file.id))
        .map((file) => ({
          id: file.id,
          name: file.name,
        }))

      const result: IUploadFilesResult = {
        success: successFiles,
        fail: failFiles,
      }

      yield* put(FilesActions.setUploadFilesResult(result))

      yield* put(FilesActions.setSelectedFilesAttached({ files: [] }))
    }
  } catch (error) {
    // TODO: Dar a possibilidade do usuário anexar novamente caso haja falha em algum dos anexos
    const resultServiceFail = {
      success: [],
      fail: attachedFiles,
    }
    yield* put(FilesActions.setUploadFilesResult(resultServiceFail))
  }
}

export default function* watchFiles() {
  yield* takeLatest(AttachFilesTypes.UPLOAD_SELECTED_FILES_ATTACHED, uploadSelectedFiles)
}
