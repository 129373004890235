export enum CheckoutTypes {
  SET_TRANSACTION_ID = `@checkout/SET_TRANSACTION_ID `,
  GET_TRANSACTION_ID = `@checkout/GET_TRANSACTION_ID `,
  DELETE_TRANSACTION_ID = `@checkout/DELETE_TRANSACTION_ID `,
  SET_FORM_OF_PAYMENT = `@checkout/SET_FORM_OF_PAYMENT`,
  GET_FORM_OF_PAYMENT = `@checkout/GET_FORM_OF_PAYMENT`,
}

export interface IFormOfPayment {
  title: string
  subTitle: string
  type: string
}
export interface CheckoutState {
  readonly transactionId: string
  readonly formOfPayment: IFormOfPayment
}
