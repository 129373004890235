import { Reducer } from 'redux'

import { IUser, UserState, UserTypes } from './types'

const INITIAL_STATE: UserState = {
  user: {} as IUser,
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SET_USER:
      return { ...state, user: action.payload }

    default:
      return state
  }
}

export default reducer
