import BaseService from 'src/config/bridge/BaseService'
import { GenericDocumentResponse } from 'src/store/ducks/document/types'

export default class DocumentService {
  static async getProductManualPDF() {
    return BaseService.doExecute<GenericDocumentResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/user-manuals`,
    })
  }

  static async getTermsAndConditionsPDF() {
    return BaseService.doExecute<GenericDocumentResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/terms-and-conditions`,
    })
  }

  static async getPrivacyPolicyPDF() {
    return BaseService.doExecute<GenericDocumentResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/privacy-policies`,
    })
  }
}
