import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

export enum SchedulingTypes {
  GET_SPECIALTIES_LIST_REQUEST = `@scheduling/GET_SPECIALTIES_LIST_REQUEST`,
  GET_SPECIALTIES_LIST = `@scheduling/GET_SPECIALTIES_LIST`,
  SET_SPECIALTIES_LIST = `@scheduling/SET_SPECIALTIES_LIST`,
  GET_DOCTORS_LIST_REQUEST = `@scheduling/GET_DOCTORS_LIST_REQUEST`,
  GET_DOCTORS_LIST = `@scheduling/GET_DOCTORS_LIST`,
  SET_DOCTORS_LIST = `@scheduling/SET_DOCTORS_LIST`,
  GET_PROFESSIONAL_LIST_REQUEST = `@scheduling/GET_PROFESSIONAL_LIST_REQUEST`,
  GET_PROFESSIONAL_LIST = `@scheduling/GET_PROFESSIONAL_LIST`,
  SET_PROFESSIONAL_LIST = `@scheduling/SET_PROFESSIONAL_LIST`,
  GET_DOCTOR_DETAILS_REQUEST = `@scheduling/GET_DOCTOR_DETAILS_REQUEST`,
  GET_DOCTOR_DETAILS = `@scheduling/GET_DOCTOR_DETAILS`,
  SET_DOCTOR_DETAILS = `@scheduling/SET_DOCTOR_DETAILS`,
  GET_SELECTED_SPECIALTY = `@scheduling/GET_SELECTED_SPECIALTY`,
  SET_SELECTED_SPECIALTY = `@scheduling/SET_SELECTED_SPECIALTY`,
  GET_SELECTED_PROFESSIONAL = `@scheduling/GET_SELECTED_PROFESSIONAL`,
  SET_SELECTED_PROFESSIONAL = `@scheduling/SET_SELECTED_PROFESSIONAL`,
  GET_PROFESSIONAL_TYPE = `@scheduling/GET_PROFESSIONAL_TYPE`,
  SET_PROFESSIONAL_TYPE = `@scheduling/SET_PROFESSIONAL_TYPE`,
  GET_DATE_AND_TIME_AVAILABLE_REQUEST = `@scheduling/GET_DATE_AND_TIME_AVAILABLE_REQUEST`,
  GET_DATE_AND_TIME_AVAILABLE = `@scheduling/GET_DATE_AND_TIME_AVAILABLE`,
  SET_DATE_AND_TIME_AVAILABLE = `@scheduling/SET_DATE_AND_TIME_AVAILABLE`,
  GET_SELECTED_DATE_TIME = `@scheduling/GET_SELECTED_DATE_TIME`,
  SET_SELECTED_DATE_TIME = `@scheduling/SET_SELECTED_DATE_TIME`,
  GET_APPOINTMENTS_LIST_REQUEST = `@scheduling/GET_APPOINTMENTS_LIST_REQUEST`,
  GET_APPOINTMENTS_LIST = `@scheduling/GET_APPOINTMENTS_LIST `,
  SET_APPOINTMENTS_LIST = `@scheduling/SET_APPOINTMENTS_LIST`,
  GET_SELECTED_APPOINTMENT = `@scheduling/GET_SELECTED_APPOINTMENT`,
  SET_SELECTED_APPOINTMENT = `@scheduling/SET_SELECTED_APPOINTMENT `,
  CREATE_SCHEDULING = `@scheduling/CREATE_SCHEDULING`,
  CANCEL_SCHEDULING = `@scheduling/CANCEL_SCHEDULING`,
  CREATE_RESCHEDULING = `@scheduling/CREATE_RESCHEDULING`,
  GET_APPOINTMENT_TYPE = `@scheduling/GET_APPOINTMENT_TYPE`,
  SET_APPOINTMENT_TYPE = `@scheduling/SET_APPOINTMENT_TYPE`,
  GET_PROFESSIONAL_BY_ID_REQUEST = `@scheduling/GET_PROFESSIONAL_BY_ID_REQUEST`,
  GET_PROFESSIONAL_BY_ID = `@scheduling/GET_PROFESSIONAL_BY_ID`,
  SET_PROFESSIONAL_BY_ID = `@scheduling/SET_PROFESSIONAL_BY_ID`,
  GET_REALIZED_APPOINTMENTS_REQUEST = `@scheduling/GET_REALIZED_APPOINTMENTS_REQUEST`,
  GET_REALIZED_APPOINTMENTS = `@scheduling/GET_REALIZED_APPOINTMENTS`,
  SET_REALIZED_APPOINTMENTS = `@scheduling/SET_REALIZED_APPOINTMENTS`,
}

export interface IDoctorsRequest {
  specialtyId: number
  page: number
}

export interface IProfessionalsRequest {
  type: string
  page: number
  patientId: number
}

export interface IProfessionalsResponse {
  professionals: IProfessional[]
}

export interface IProfessional {
  id: number
  name: string
  specialty: string
  councilNumber: string
  councilUf: string
}

export interface ISpecialtiesResponse {
  specialties: ISpecialty[]
}

export interface ISpecialty {
  id: number
  description: string
}

export interface IDoctorDetailsRequest extends SagaWithHistory {
  doctorId: number
  callback?: () => void
}

export interface IDoctorDetailsResponse {
  doctor: IDoctorDetails
}

export interface IDoctorDetails {
  id: number
  name: string
  specialty: string
  about: string
  councilNumber: string
  councilUf: string
  photo: string
  qualifications: [
    {
      institution: string
      course: string
      completionYear: number
    },
  ]
}

export interface IProfessionalByIDRequest extends SagaWithHistory {
  id: number
}

export interface IDataAndTimeAvailableRequest extends SagaWithHistory {
  professionalId: number
  startDate: string
  endDate: string
  type: string
}

export interface ISchedules {
  date: string
  times: string[]
}

export interface IDataAndTimeAvailableResponse {
  schedules: ISchedules[]
}

export interface IAppointmentDetails {
  status: string
  id: number
  professional: {
    id: number
    name: string
    specialty: string
    type: string
  }
  appointmentDate: string
}

export interface IAppointmentsListResponse {
  appointments: IAppointmentDetails[]
}

export interface ISchedulingRequest {
  patientId: number
  professionalId: number
  professionalType: string
  professionalName: string
  professionalSpecialty: string
  appointmentDate: string
}

export interface ICreateSchedulingRequest extends ISchedulingRequest {
  transactionId: string
}

export interface ICreateReschedulingRequest extends ISchedulingRequest {
  appointmentId: number
}

export interface ICancelSchedulingRequest {
  patientId: number
  appointmentId: number
  professionalType: string
}

export interface IType extends SagaWithHistory {
  type: string
}

export interface IAppointment {
  id: number
  appointmentDate: string
  professional: {
    id: number
    name: string
    type: string
    specialty: string
    councilNumber: string
    councilUf: string
  }
  isSpontaneousDemand: boolean
  appointmentObjective: string
}

export interface IRealizedAppointmentsRequest {
  patientId: number
  professionalType: string
  specialty?: string
}
export interface IRealizedAppointmentsResponse {
  appointments: IAppointment[]
}

export interface SchedulingState {
  readonly professionalType: IType
  readonly appointmentType: IType
  readonly selectedDateTime: string
  readonly selectedSpecialty: ISpecialty
  readonly specialtiesList: ISpecialty[]
  readonly dataAndTimeAvailable: ISchedules[]
  readonly professionalsList: IProfessional[]
  readonly doctorsList: IProfessional[]
  readonly selectedProfessional: IProfessional
  readonly appointmentList: IAppointmentDetails[]
  readonly selectedAppointment: IAppointmentDetails
  readonly doctorDetails: IDoctorDetails
  readonly realizedAppointments: IAppointment[]
}
