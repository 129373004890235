import { Fragment, lazy, Suspense, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeroImg from 'src/assets/img/hero-image.png'
import { Babi, Button } from 'src/components'
import { CommonQuestions } from 'src/components/CommonQuestions/CommonQuestions'
import { LogoConexa } from 'src/components/LogoConexa/LogoConexa'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { reasonsToHire } from 'src/mocks/Subscription'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { ISpecialties } from 'src/store/ducks/specialties/types'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { BtnLink, ContainerFullPage, H2, PLarge, PMedium, PSmall } from 'src/styles/commons'
import { numberFormat, validateAge } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAccordion,
  InterUIBottomSheet,
  InterUIContainer,
  InterUIListDescription,
  InterUILoading,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { Hero, SpecialtiesContent } from './HomeSubscription.styles'

export const HomeSubscription = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const prices = useSelector(SubscriptionActions.getPrices)
  const holder = useSelector(SubscriptionActions.getHolder)
  const highlightList = useSelector(SpecialtiesActions.getHighlightsList)
  const specialtiesList = useSelector(SpecialtiesActions.getSpecialtiesList)
  const spontaneousDemandsList = useSelector(SpecialtiesActions.getSpontaneousDemandsList)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const iconDoctor = <Icons.Doctor color={theme.colors.primary.A500} />
  const iconPill = <Icons.Pill color={theme.colors.primary.A500} />
  const iconGeneralPractitioner = <Icons.GeneralPractitioner color={theme.colors.primary.A500} />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />

  const importIcon = (iconName: string) =>
    lazy(() => import(`@interco/inter-frontend-svgs/lib/orangeds/MD/${iconName}.js`))

  const [mainSpecialtiesBS, setMainSpecialtiesBS] = useState(false)
  const [eligibilityBS, setEligibilityBS] = useState(false)
  const [specialtiesBS, setSpecialtiesBS] = useState(false)
  const [selectedSpecialty, setSelectedSpecialty] = useState<ISpecialties>()

  const [knowHowUseDiscount, setKnowHowUseDiscount] = useState(false)
  const [discountType, setDiscountType] = useState('')

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_DC_ACESSO.name, {
      tela: 'Landing page contratação',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }, [])

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  const onContinue = (contentId: string) => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_ASSINAR.name, {
      content_id: contentId,
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
    if (validateAge(holder.birthdate)) {
      setEligibilityBS(true)
    } else if (holder.status === 'WITHOUT_SIGNATURE') {
      dispatch(
        SubscriptionActions.createSubscription({
          history,
          pathname: TypesRoutes.SUBSCRIPTION_DETAILS,
        }),
      )
    } else {
      history.push(TypesRoutes.SUBSCRIPTION_DETAILS)
    }
  }

  const stickyFooter = (
    <Button margin="24px" onClick={() => onContinue('botão')}>
      Quero conhecer
    </Button>
  )

  const specialtyInformation = (specialty: ISpecialties) => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_ESPECIALIDADE.name, {
      content_name: specialty.name,
    })
    setSelectedSpecialty(specialty)
    setMainSpecialtiesBS(true)
  }

  const handleClickKnowHowUse = (type: string) => {
    setDiscountType(type)
    setKnowHowUseDiscount(true)
  }

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <ContainerFullPage>
          <Hero>
            <img src={HeroImg} alt="Mulher afro feliz, contratando telemedicina" />
          </Hero>

          <InterUIContainer margin="0 24px 32px">
            <H2 marginBottom="16px">Seu plano de assinatura mensal de telemedicina</H2>
            <PMedium scale={400} marginBottom="32px">
              Por
              <strong> {numberFormat(prices.price)}/mês </strong>você tem desconto em farmácias,
              realiza consultas médicas pelo app e muito mais!
            </PMedium>
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px">
            <H2 marginBottom="16px">Especialistas à sua disposição </H2>

            {spontaneousDemandsList.length > 0 &&
              spontaneousDemandsList.map((item, index) => {
                const key = `spontaneous-${index}`

                return (
                  <InterUIListDescription
                    key={key}
                    id={key}
                    data-testid={key}
                    iconLeft={iconDoctor}
                    iconRight={iconArrow}
                    withBorder
                    margin="0 0 16px 0"
                    onClick={() => specialtyInformation(item)}
                    role="group"
                  >
                    <PMedium marginBottom="4px" bold>
                      {item.name === 'Clínico geral' ? 'Pronto atendimento' : item.name}
                    </PMedium>
                    <PSmall marginBottom="0px" bold scale={400}>
                      {`${item.price} por consulta`}
                    </PSmall>
                  </InterUIListDescription>
                )
              })}

            {highlightList.length > 0 &&
              highlightList.map((item, index) => {
                const key = `highlights-${index}`
                const Icon = importIcon(item.icon)

                const renderIcon = (
                  <Suspense fallback={<InterUILoading size="md" />}>
                    <Icon color={theme.colors.primary.A500} />
                  </Suspense>
                )

                return (
                  <InterUIListDescription
                    key={key}
                    id={key}
                    data-testid={key}
                    iconLeft={renderIcon}
                    iconRight={iconArrow}
                    withBorder
                    margin="0 0 16px 0"
                    onClick={() => specialtyInformation(item)}
                    role="group"
                  >
                    <PMedium marginBottom="4px" bold>
                      {item.name}
                    </PMedium>
                    <PSmall marginBottom="0px" bold scale={400}>
                      {`${item.price} por consulta`}
                    </PSmall>
                  </InterUIListDescription>
                )
              })}

            <BtnLink onClick={() => setSpecialtiesBS(true)}>Mostrar todas especialidades</BtnLink>
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px">
            <H2 marginBottom="24px">Descontos para você economizar ainda mais!</H2>
            <InterUIListDescription iconLeft={iconPill} AlignOnlyLeftIcon="center" role="group">
              <PMedium bold>Descontos em medicamentos</PMedium>
              <PMedium scale={400}>
                Garanta até 30% de descontos em medicamentos em toda rede de farmácias PagueMenos.
              </PMedium>
              <PMedium
                style={{ color: `${theme.colors.primary.A500}` }}
                marginBottom="0px"
                bold
                onClick={() => handleClickKnowHowUse('medicamentos')}
                data-testid="test-button-knowHowUse-medicines"
              >
                Saiba como utilizar
              </PMedium>
            </InterUIListDescription>

            <InterUISeparator variant="small" margin="16px 0" />

            <InterUIListDescription
              iconLeft={iconGeneralPractitioner}
              AlignOnlyLeftIcon="center"
              role="group"
            >
              <PMedium bold>Descontos em exames laboratoriais</PMedium>
              <PMedium scale={400}>
                Garanta até 15% de desconto em exames laboratoriais em toda a rede de laboratórios
                Dasa.
              </PMedium>
              <PMedium
                style={{ color: `${theme.colors.primary.A500}` }}
                marginBottom="0px"
                bold
                onClick={() => handleClickKnowHowUse('exames laboratoriais')}
                data-testid="test-button-knowHowUse-lab"
              >
                Saiba como utilizar
              </PMedium>
            </InterUIListDescription>
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px">
            <H2 marginBottom="24px">Mais motivos para contratar</H2>

            {reasonsToHire.map((item, index) => {
              const key = `reasons-${index}`

              return (
                <Fragment key={key}>
                  <PMedium bold>{item.label}</PMedium>
                  <PMedium marginBottom="0" scale={400}>
                    {item.value}
                  </PMedium>

                  {index !== reasonsToHire.length - 1 && (
                    <InterUISeparator variant="small" margin="16px 0" />
                  )}
                </Fragment>
              )
            })}
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px">
            <CommonQuestions typePage="Landing Page contratação" />
          </InterUIContainer>

          <InterUIContainer margin="0 0 32px">
            <LogoConexa />
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="32px 24px 0">
            <Babi />
          </InterUIContainer>
        </ContainerFullPage>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="test-mainSpecialtiesBS"
        title={
          selectedSpecialty?.name === 'Clínico geral'
            ? 'Pronto atendimento'
            : selectedSpecialty?.name
        }
        toggle={mainSpecialtiesBS}
        onHide={() => setMainSpecialtiesBS(false)}
      >
        <PMedium scale={400} marginBottom="48px">
          {selectedSpecialty?.description}
        </PMedium>

        <Button onClick={() => setMainSpecialtiesBS(false)}>Entendi</Button>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        data-testid="test-specialtiesBS"
        title="Especialidades"
        toggle={specialtiesBS}
        onHide={() => setSpecialtiesBS(false)}
      >
        <SpecialtiesContent>
          {specialtiesList.length > 0 &&
            specialtiesList.map((item, index) => (
              <InterUIAccordion
                key={`specialties-${index.toString()}`}
                id={`specialties-${index.toString()}`}
                data-testid={`specialties-${index.toString()}`}
                header={item.name}
                border="bottom"
              >
                <PSmall scale={400} marginBottom="0">
                  {item.description}
                </PSmall>
              </InterUIAccordion>
            ))}
        </SpecialtiesContent>
        <Button margin="24px 0 0" onClick={() => setSpecialtiesBS(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        data-testid="test-eligibilityBS"
        title="Menores de 18 anos"
        toggle={eligibilityBS}
        onHide={() => setEligibilityBS(false)}
      >
        <PMedium marginBottom="0" scale={400}>
          Para contratar os serviços do Doutor Inter você deve ser correntista do Inter e ter mais
          de 18 anos.
        </PMedium>
        <Button margin="24px 0 0" onClick={BaseBridge.requestGoBack}>
          Entendi
        </Button>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        toggle={knowHowUseDiscount}
        onHide={() => setKnowHowUseDiscount(false)}
        data-testid="test-knowHowUseDiscount-BS"
      >
        <PLarge bold>Descontos em {discountType}</PLarge>
        <PMedium marginBottom="0" scale={400}>
          {discountType === 'medicamentos'
            ? 'Para utilizar seu desconto do Doutor Inter em compras de medicamentos nas farmácias PagueMenos, apresente seu CPF durante o pagamento.'
            : 'Para utilizar seu desconto em exames laboratoriais, ligue para central de atendimento dos laboratórios Dasa e informe que é contratante do Doutor Inter.'}
        </PMedium>
        <Button
          margin="24px 0 0"
          onClick={() => setKnowHowUseDiscount(false)}
          data-testid="test-button-understood-knowHowUseDiscount-BS"
        >
          Entendi
        </Button>
      </InterUIBottomSheet>
    </>
  )
}
