import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, PaymentMethod, WaitingPayment } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { ContainerFullPage, H2, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBox,
  InterUIContainer,
  InterUIProgressBar,
  InterUISeparator,
  InterUITag,
} from '@interco/inter-ui-react-lib'

import { Value } from './PaymentConsult.styles'

export const PaymentConsult: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { patientId } = useSelector(SubscriptionActions.getHolder)
  const { hasFreeAppointment } = useSelector(SubscriptionActions.getSubscription)
  const spontaneousDemandsList = useSelector(SpecialtiesActions.getSpontaneousDemandsList)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const isLoading = useSelector(LoadingActions.get)

  const iconArrow = <Icons.ChevronRight color={theme.colors.neutral.grayscale.A500} />
  const iconPayment = <Icons.Dollar color={theme.colors.neutral.grayscale.A500} />
  const iconArrowOrange = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconDebit = <Icons.Wallet color={theme.colors.primary.A500} />
  const iconCredit = <Icons.Card color={theme.colors.primary.A500} />

  const [inWaitPayment, setInWaitPayment] = useState(false)

  const [paymentCardConfig, setPaymentCardConfig] = useState({
    border: theme.colors.neutral.grayscale.A200,
    icon: iconPayment,
    title: 'Formas de pagamento',
    subTitle: '',
    arrow: iconArrow,
  })

  const consultPrice = spontaneousDemandsList[0]?.price

  useBasePage({
    navbarTitle: PageTitles.CONSULT_NOW,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
    hideBackNavigationBtn: inWaitPayment,
  })

  useEffect(() => {
    if (formOfPayment.type) {
      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: formOfPayment.type === 'DEBIT' ? iconDebit : iconCredit,
        title: formOfPayment.title,
        subTitle: formOfPayment.subTitle,
        arrow: iconArrowOrange,
      })
    }
  }, [])

  const openChoicePaymentForm = async () => {
    try {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_PAGAMENTOPA_T_SELECIONAR.name)

      const response = await CheckoutBaseService.openWallet(
        consultPrice.replace(`R$`, ``).replace(`,`, `.`),
        'SPONTANEOUS_DEMAND',
        patientId?.toString(),
      )

      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_PAGAMENTOPA_T_SELECIONADO.name)

      dispatch(CheckoutActions.setFormOfPayment(response))

      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: response.type === 'DEBIT' ? iconDebit : iconCredit,
        title: response.title,
        subTitle: response.subTitle,
        arrow: iconArrowOrange,
      })
    } catch (error: unknown) {
      const errorDetails = {
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        disabledButton: false,
        route: TypesRoutes.START,
      }

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Subscription.openWallet',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const processTransactionStatus = (status: string) => {
    const errorDetails = {
      title: 'Transação negada',
      subTitle:
        'Houve um erro na transação requisitada, por favor, entre em contato com o suporte.',
      route: TypesRoutes.START,
    }

    switch (status) {
      case 'PROCESSED':
        history.push(TypesRoutes.PREPARE_CONSULT)
        break
      case 'DENIED':
        dispatch(ErrorActions.show(errorDetails))
        break
      default:
        break
    }
  }

  const finalizeContract = async () => {
    if (BaseBridge.isBrowser() || hasFreeAppointment) processTransactionStatus('PROCESSED')
    else {
      try {
        const result = await CheckoutBaseService.contractWallet(
          consultPrice.replace(`R$`, ``).replace(`,`, `.`),
        )

        const payload = {
          transactionId: result.transactionId,
          onTransmission: processTransactionStatus,
        }

        dispatch(CheckoutActions.deleteTransactionId())
        dispatch(CheckoutActions.setTransactionId(result.transactionId))
        dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))
        dispatch(SubscriptionActions.getTransactionStatus(payload))

        setInWaitPayment(true)
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'Subscription.finalizeContract',
        })

        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const stickyFooter = (
    <Button
      data-testid="button-continue"
      margin="24px"
      onClick={finalizeContract}
      disabled={
        (paymentCardConfig.title === `Formas de pagamento` && !hasFreeAppointment) || isLoading
      }
    >
      Continuar para a consulta
    </Button>
  )

  return inWaitPayment ? (
    <WaitingPayment />
  ) : (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIProgressBar progress="100%" />
      <ContainerFullPage height="98px" margin="0 24px">
        <H2 margin="24px 0">{hasFreeAppointment ? 'Pagamento' : 'Estamos quase lá!'}</H2>
        <InterUIBox direction="column" align="flex-start">
          <PSmall marginBottom="4px" bold>
            Consulta de pronto atendimento
          </PSmall>
          <PSmall marginBottom="16px">
            Você vai participar de uma consulta com um clínico geral.
          </PSmall>
          {hasFreeAppointment && (
            <InterUITag variant="success" margin="0 0 8px">
              Primeira consulta por nossa conta
            </InterUITag>
          )}
          <PSmall marginBottom="4px" bold>
            Valor da consulta
          </PSmall>

          {spontaneousDemandsList.length > 0 && (
            <Value>{hasFreeAppointment ? 'R$ 0,00' : consultPrice}</Value>
          )}
        </InterUIBox>

        {!hasFreeAppointment && (
          <>
            <InterUISeparator variant="small" margin="24px 0" />

            <PaymentMethod
              title={paymentCardConfig.title}
              subTitle={paymentCardConfig.subTitle}
              border={paymentCardConfig.border}
              iconLeft={paymentCardConfig.icon}
              iconRight={paymentCardConfig.arrow}
              openChoicePaymentForm={openChoicePaymentForm}
            />

            <InterUIAlert margin="24px 0 0">
              <PSmall marginBottom="4px" bold>
                Tipo de cobrança
              </PSmall>
              <PSmall marginBottom="0">
                O valor será reservado e a cobrança será feita após o encerramento da consulta. O
                dinheiro será estornado caso a consulta não seja realizada.
              </PSmall>
            </InterUIAlert>
          </>
        )}
      </ContainerFullPage>
    </InterUIContainer>
  )
}
