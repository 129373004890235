import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { CardSchedule } from 'src/components/CardSchedule/CardSchedule'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { PSmall } from 'src/styles/commons'

import { InterUIAlert, InterUIContainer } from '@interco/inter-ui-react-lib'

export const CancelledCardDetails: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const holder = useSelector(SubscriptionActions.getHolder)
  const appointment = useSelector(SchedulingActions.getSelectedAppointment)

  const scheduleNewAppointment = () => {
    if (appointment.professional.type === 'DOCTOR') {
      dispatch(SchedulingActions.getSpecialtiesListRequest())
    } else {
      dispatch(
        SchedulingActions.getProfessionalsListRequest({
          type: appointment.professional.type,
          page: 1,
          patientId: holder.patientId as number,
        }),
      )
    }

    dispatch(
      SchedulingActions.setProfessionalType({
        history,
        pathname:
          appointment.professional.type === 'DOCTOR'
            ? TypesRoutes.SPECIALTIES
            : TypesRoutes.PROFESSIONAL,
        type: appointment.professional.type,
      }),
    )
  }

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_CANCELLED,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const stickyFooter = <Button onClick={scheduleNewAppointment}>Agendar uma nova consulta</Button>

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <CardSchedule appointmentDetails={appointment} showStatus={!appointment.status} />
      <InterUIAlert type="attention" margin="24px 0 32px 0">
        <PSmall bold scale={500} marginBottom="4px">
          Sua consulta foi cancelada
        </PSmall>
        <PSmall scale={500} marginBottom="0">
          Nesses casos, o valor cobrado será estornado em até 24 horas, direto na sua conta Inter.
          Você pode agendar uma nova consulta sempre que quiser.
        </PSmall>
      </InterUIAlert>
    </InterUIContainer>
  )
}
