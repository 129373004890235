import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { DocumentActions } from 'src/store/ducks/document/action'
import * as PDFJS from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

import { InterUIContainer, InterUILoading } from '@interco/inter-ui-react-lib'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'

import { Button } from '..'

import { LoadContainer, PdfContainer } from './Document.styles'

import '@react-pdf-viewer/core/lib/styles/index.css'

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker

export const Document: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const getTermsAndConditionsPDF = useSelector(DocumentActions.getTermsAndConditionsPDF)
  const getProductManualPDF = useSelector(DocumentActions.getProductManualPDF)
  const getPrivacyPolicyPDF = useSelector(DocumentActions.getPrivacyPolicyPDF)
  const getRedirectDocument = useSelector(DocumentActions.getRedirectToDocument)

  const [isProductManual, setIsProductManual] = useState(false)
  const [isTermsAndConditions, setIsTermsAndConditions] = useState(false)
  const [isPrivacyPolicy, setIsPrivacyPolicy] = useState(false)
  const [enabledButton, setEnabledButton] = useState(false)

  const setNavBarTitle = () => {
    switch (getRedirectDocument?.type) {
      case 'MANUAL':
        return PageTitles.PRODUCT_MANUAL
      case 'CONTRACT':
        return PageTitles.PROPOSAL_CONTRACT
      case 'PRIVACY_POLICY':
        return PageTitles.PRIVACY_POLICY
      default:
        return PageTitles.EMPTY
    }
  }

  useBasePage({
    navbarTitle: setNavBarTitle(),
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (getRedirectDocument?.type === 'CONTRACT' && getTermsAndConditionsPDF) {
      setIsTermsAndConditions(true)
    } else if (getRedirectDocument?.type === 'MANUAL' && getProductManualPDF) {
      setIsProductManual(true)
    } else if (getRedirectDocument?.type === 'PRIVACY_POLICY' && getPrivacyPolicyPDF) {
      setIsPrivacyPolicy(true)
    }
  }, [
    dispatch,
    getProductManualPDF,
    getRedirectDocument?.type,
    getTermsAndConditionsPDF,
    getPrivacyPolicyPDF,
  ])

  const stickyFooter = (
    <>
      {enabledButton && (
        <InterUIContainer margin="24px">
          <Button
            variant="primary"
            margin="0 0 16px"
            onClick={() => {
              history.goBack()
            }}
          >
            Entendi
          </Button>
        </InterUIContainer>
      )}
    </>
  )

  const loading = (
    <LoadContainer>
      <InterUILoading size="xl" />
    </LoadContainer>
  )

  if (isTermsAndConditions && getTermsAndConditionsPDF?.documentUrl) {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={getTermsAndConditionsPDF?.documentUrl}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  if (isProductManual && getProductManualPDF?.documentUrl) {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={getProductManualPDF?.documentUrl}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  if (isPrivacyPolicy && getPrivacyPolicyPDF?.documentUrl) {
    return (
      <InterUIContainer stickyFooter={stickyFooter} margin="0px">
        <PdfContainer>
          <Viewer
            fileUrl={getPrivacyPolicyPDF?.documentUrl}
            renderLoader={() => loading}
            onDocumentLoad={() => setEnabledButton(true)}
            defaultScale={SpecialZoomLevel.PageFit}
          />
        </PdfContainer>
      </InterUIContainer>
    )
  }

  return <></>
}
