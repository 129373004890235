import { Reducer } from 'redux'

import { CheckoutState, CheckoutTypes, IFormOfPayment } from './types'

const INITIAL_STATE: CheckoutState = {
  transactionId: '',
  formOfPayment: {} as IFormOfPayment,
}

const reducer: Reducer<CheckoutState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CheckoutTypes.SET_TRANSACTION_ID:
      return { ...state, transactionId: action.payload }
    case CheckoutTypes.DELETE_TRANSACTION_ID:
      return { ...state, transactionId: '' }
    case CheckoutTypes.SET_FORM_OF_PAYMENT:
      return { ...state, formOfPayment: action.payload }
    default:
      return state
  }
}

export default reducer
