import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, LoadWaitingTime } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { ContainerFullPage, H1, PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { TAGS } from 'src/enums/TaggingEnum'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ContainerWaitingTime, Image } from './WaitingTime.style'

export const WaitingTime: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const spontaneousDemand = useSelector(HealthcareActions.getAttendance)
  const getProtocolId = useSelector(HealthcareActions.getProtocolId)

  const isLoading = useSelector(LoadingActions.get)

  const iconCouch = <Icons.Couch width={32} height={32} color={theme.colors.primary.A500} />

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    hideHomeBtn: true,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    if (getProtocolId?.protocolId) {
      dispatch(FilesActions.uploadSelectedFiles())
    }
  }, [getProtocolId])

  const confirmeAttendance = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_TEMPODEESPERA_T_OPCAO.name, {
      content_name: 'Sim, quero continuar',
    })

    dispatch(
      SchedulingActions.setAppointmentType({
        history,
        pathname: TypesRoutes.WAITING_ROOM,
        type: 'IMMEDIATE',
      }),
    )
  }

  const goToCancellation = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_TEMPODEESPERA_T_OPCAO.name, {
      content_name: 'Desistir',
    })

    dispatch(
      HealthcareActions.setRedirectToCancel({
        history,
        pathname: TypesRoutes.APPOINTMENT_CANCELLATION,
        type: 'IMMEDIATE',
      }),
    )
  }

  const stickyFooter = (
    <>
      <Button onClick={confirmeAttendance}>Sim, quero continuar</Button>
      <Button onClick={goToCancellation} variant="secondary" margin="16px 0 24px">
        Desistir
      </Button>
    </>
  )

  return spontaneousDemand &&
    spontaneousDemand.attendance &&
    spontaneousDemand.attendance.estimatedWaitTime &&
    !isLoading ? (
    <InterUIContainer margin="0 24px" stickyFooter={stickyFooter}>
      <ContainerFullPage height="136px">
        <ContainerWaitingTime>
          <Image>{iconCouch}</Image>
          <H1>Tempo de espera</H1>
          <PMedium scale={400} margin="8px 0 16px">
            {`No momento, o tempo de espera é de aproximadamente ${spontaneousDemand.attendance.estimatedWaitTime}
              minutos.`}
          </PMedium>
          <PMedium scale={400}>Quer solicitar o atendimento com clínico geral? </PMedium>
        </ContainerWaitingTime>
      </ContainerFullPage>
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="0px" margin="0 24px" overflowHidden>
      <LoadWaitingTime />
    </ContainerFullPage>
  )
}
