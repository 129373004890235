import styled from 'styled-components'

export const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

export const BoxSmall = styled.div`
  border: 1px solid ${(props) => `${props.theme.colors.neutral.grayscale.A200}`};
  border-radius: 20px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-size: 12px;

  &.active {
    background-color: ${(props) => `${props.theme.colors.neutral.grayscale.A500}`};
    color: ${(props) => `${props.theme.colors.theme}`};
  }
`
