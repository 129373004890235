import { useSelector } from 'react-redux'
import LogoImgDark from 'src/assets/img/logo-conexa-dark.png'
import LogoImg from 'src/assets/img/logo-conexa.png'
import { ThemesEnum } from 'src/enums/ThemesEnum'
import { ThemeActions } from 'src/store/ducks/theme/actions'
import { PMedium } from 'src/styles/commons'

import { ContentLogo, LogoImage } from './LogoConexa.styles'

export const LogoConexa = () => {
  const theme = useSelector(ThemeActions.getTheme)

  return (
    <ContentLogo>
      <PMedium margin="0 4px 0 0">Em parceria com</PMedium>
      <LogoImage>
        <img src={theme.name === ThemesEnum.LIGHT ? LogoImg : LogoImgDark} alt="Logo Conexa" />
      </LogoImage>
    </ContentLogo>
  )
}
