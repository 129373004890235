import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { formatDateSchedule, formatHours, removeHoursFromDate } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIBox, InterUIContainer } from '@interco/inter-ui-react-lib'

import { InfoReschedule } from './RescheduleDetails.styles'

export const RescheduleDetails: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const dateTime = useSelector(SchedulingActions.getSelectedDateTime)
  const isLoading = useSelector(LoadingActions.get)

  const iconAgenda = (
    <Icons.Agenda width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconPending = (
    <Icons.Pending width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconDoctor = (
    <Icons.Doctor width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconUserAccount = (
    <Icons.UserAccount width={16} height={16} color={theme.colors.neutral.grayscale.A400} />
  )

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_RESCHEDULING,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const confirmReschedule = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_RESUMREAGEND_T_CONFIRMAR.name, {
      content_id: appointment.professional.specialty,
      content_name: appointment.professional.name,
      data: removeHoursFromDate(dateTime),
      hora: formatHours(dateTime),
    })

    dispatch(
      SchedulingActions.createRescheduling({
        history,
        pathname: TypesRoutes.APPOINTMENT_CONFIRMATION,
      }),
    )
  }

  const stickyFooter = (
    <Button onClick={confirmReschedule} disabled={isLoading}>
      Confirmar reagendamento
    </Button>
  )

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <H2 marginBottom="24px">Resumo do reagendamento</H2>

      <InterUIBox direction="column" align="flex-start">
        <PMedium bold marginBottom="16px">
          Consulta agendada
        </PMedium>

        <InfoReschedule>
          {iconDoctor}
          <PSmall marginBottom="0" scale={400}>
            {appointment.professional.specialty}
          </PSmall>
        </InfoReschedule>
        <InfoReschedule>
          {iconUserAccount}
          <PSmall marginBottom="0" scale={400}>
            {appointment.professional.name}
          </PSmall>
        </InfoReschedule>
        <InfoReschedule>
          {iconAgenda}
          <PSmall marginBottom="0" scale={400}>
            {formatDateSchedule(dateTime)}
          </PSmall>
        </InfoReschedule>
        <InfoReschedule>
          {iconPending}
          <PSmall marginBottom="0" scale={400}>
            {formatHours(dateTime)}
          </PSmall>
        </InfoReschedule>
      </InterUIBox>
    </InterUIContainer>
  )
}
