import styled from 'styled-components'

export const ContentLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoImage = styled.div`
  display: block;
  width: 80px;

  img {
    width: 100%;
    height: auto;
  }
`
