import { action } from 'typesafe-actions'

import { IAppointmentPersistedData, PersistedDataTypes } from './types'
import { RootState } from '../..'

const getAppointmentPersistedData = (state: RootState) =>
  state.persistedData.appointmentPersistedData

const setAppointmentPersistedData = (data?: IAppointmentPersistedData) =>
  action(PersistedDataTypes.SET_APPOINTMENT_PERSISTED_DATA, data)

export const PersistedDataActions = {
  getAppointmentPersistedData,
  setAppointmentPersistedData,
}
