import { action } from 'typesafe-actions'
import { PageTitles } from 'src/enums/pageTitles'
import { PageDirections } from 'src/routes/pageDirections'

import { RootState } from '../..'
import { NavbarTypes } from './types'

const getTitle = (state: RootState) => state.navbar.title

const setTitle = (data: PageTitles) => action(NavbarTypes.SET_TITLE, data)

const showHomeBtn = () => action(NavbarTypes.TOGGLE_HOME_BTN, true)

const hideHomeBtn = () => action(NavbarTypes.TOGGLE_HOME_BTN, false)

const showBackNavigationBtn = () => action(NavbarTypes.TOGGLE_BACK_NAVIGATION_BTN, true)

const hideBackNavigationBtn = () => action(NavbarTypes.TOGGLE_BACK_NAVIGATION_BTN, false)

const showExitBS = () => action(NavbarTypes.TOGGLE_EXIT_BS, true)

const hideExitBS = () => action(NavbarTypes.TOGGLE_EXIT_BS, false)

const getToggleHomeBtn = (state: RootState) => state.navbar.toggleHomeBtn

const getToggleBackNavigationBtn = (state: RootState) => state.navbar.toggleBackNavigationBtn

const getToggleExitBS = (state: RootState) => state.navbar.toggleExitBS

const setDirection = (direction: PageDirections) => action(NavbarTypes.SET_DIRECTION, direction)

const getDirection = (state: RootState) => state.navbar.direction

const setBackNavigationHandler = (cb: () => void) =>
  action(NavbarTypes.SET_BACK_NAVIGATION_HANDLER, cb)

const getBackNavigationHandler = (state: RootState) => state.navbar.backNavigationHandler

const setHomeNavigationHandler = (cb: () => void) =>
  action(NavbarTypes.SET_HOME_NAVIGATION_HANDLER, cb)

const getHomeNavigationHandler = (state: RootState) => state.navbar.homeNavigationHandler

export const NavbarActions = {
  getTitle,
  setTitle,
  showHomeBtn,
  hideHomeBtn,
  showExitBS,
  hideExitBS,
  showBackNavigationBtn,
  hideBackNavigationBtn,
  getToggleHomeBtn,
  getToggleBackNavigationBtn,
  getToggleExitBS,
  setDirection,
  getDirection,
  setBackNavigationHandler,
  getBackNavigationHandler,
  setHomeNavigationHandler,
  getHomeNavigationHandler,
}
