import { action } from 'typesafe-actions'

import { RootState } from '../..'
import {
  DocumentTypes,
  IDocumentCallback,
  RedirectToDocument,
  GenericDocumentResponse,
} from './types'

const getProductManualPDFRequest = (data?: IDocumentCallback) =>
  action(DocumentTypes.GET_PRODUCT_MANUAL_PDF, data)

const getProductManualPDF = (state: RootState) => state.document.productManual

const setProductManualPDF = (data?: GenericDocumentResponse) =>
  action(DocumentTypes.SET_PRODUCT_MANUAL_PDF, data)

const getTermsAndConditionsPDFRequest = (data?: IDocumentCallback) =>
  action(DocumentTypes.GET_TERMS_AND_CONDITIONS_PDF, data)

const getTermsAndConditionsPDF = (state: RootState) => state.document.termsAndConditions

const setTermsAndConditionsPDF = (data?: GenericDocumentResponse) =>
  action(DocumentTypes.SET_TERMS_AND_CONDITIONS_PDF, data)

const getPrivacyPolicyPDFRequest = (data?: IDocumentCallback) =>
  action(DocumentTypes.GET_PRIVACY_POLICY_PDF, data)

const getPrivacyPolicyPDF = (state: RootState) => state.document.privacyPolicy

const setPrivacyPolicyPDF = (data?: GenericDocumentResponse) =>
  action(DocumentTypes.SET_PRIVACY_POLICY_PDF, data)

const deleteDocumentPDF = () => action(DocumentTypes.DELETE_DOCUMENT)

const getRedirectToDocument = (state: RootState) => state.document.redirectToDocument

const setRedirectToDocument = (data: RedirectToDocument) =>
  action(DocumentTypes.SET_REDIRECT_TO_DOCUMENT, data)

export const DocumentActions = {
  getProductManualPDFRequest,
  getProductManualPDF,
  setProductManualPDF,
  getTermsAndConditionsPDFRequest,
  getTermsAndConditionsPDF,
  setTermsAndConditionsPDF,
  getPrivacyPolicyPDFRequest,
  getPrivacyPolicyPDF,
  setPrivacyPolicyPDF,
  deleteDocumentPDF,
  getRedirectToDocument,
  setRedirectToDocument,
}
