import React from 'react'

import { SkeletonContent, SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadItemSymptom: React.FC = () => (
  <SkeletonContent>
    <SkeletonGrid>
      <InterUISkeleton width="50%" />
      <InterUISkeleton width="24px" height="24px" />
    </SkeletonGrid>
    <InterUISeparator height="1px" margin="16px 0" />
  </SkeletonContent>
)
