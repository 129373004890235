import NewRelicUtils from '../monitoring/NewRelicUtils'
import BaseBridge from './BaseBridge'
import CheckoutBridge, {
  ContractWalletResponseAndroid,
  ContractWalletResponseIOS,
  OpenWalletResponseAndroid,
  OpenWalletResponseIOS,
  TransactionType,
} from './CheckoutBridge'

export class CheckoutBaseService {
  private static getWalletClientID(
    transactionId: 'SUBSCRIPTION' | 'SPONTANEOUS_DEMAND' | 'APPOINTMENT' | 'CANCELLATION',
  ) {
    if (transactionId === 'SUBSCRIPTION') {
      return 'DR_INTER_MENSAL'
    }

    return 'DR_INTER'
  }

  private static openWalletResponse?: OpenWalletResponseAndroid | OpenWalletResponseIOS

  /**
   * Método para invocar a seleção de formas de pagamento.
   * @param amount valor em reais.
   * @param transactionType tipo de transação, se SUBSCRIPTION ou APPOINTMENT.
   * @param patientId identificador único do usuário.
   * @returns os dados da forma de pagamento escolhida.
   */
  static async openWallet(
    amount: string,
    transactionType: 'SUBSCRIPTION' | 'SPONTANEOUS_DEMAND' | 'APPOINTMENT' | 'CANCELLATION',
    patientId?: string,
  ): Promise<ChosenPaymentResult> {
    if (BaseBridge.isBrowser())
      return {
        title: `Crédito Inter`,
        subTitle: 'Não cobrado em ambiente de desenvolvimento.',
        type: 'CREDIT',
      } as ChosenPaymentResult

    let result: ChosenPaymentResult

    const subscriptionPrice = Number.parseFloat(amount) * 100

    try {
      const response = patientId
        ? await CheckoutBridge.openWalletMethod(
            CheckoutBaseService.getWalletClientID(transactionType),
            `${subscriptionPrice.toFixed(0)}`,
            {
              patientId,
              transactionType,
            },
          )
        : await CheckoutBridge.openWalletMethod(
            CheckoutBaseService.getWalletClientID(transactionType),
            `${subscriptionPrice.toFixed(0)}`,
            {
              transactionType,
            },
          )

      CheckoutBaseService.openWalletResponse = response

      const paymentMethod =
        (response as OpenWalletResponseAndroid)?.selectedPaymentMethod?.type ||
        (response as OpenWalletResponseIOS)?.method?.type

      const lastFourDigits = (response as OpenWalletResponseAndroid)?.selectedPaymentMethod?.card
        ?.lastFourDigits

      const showDigits = (response as OpenWalletResponseIOS)?.method?.firstLine

      const balanceAvailable = (response as OpenWalletResponseIOS)?.method?.firstLine

      if (paymentMethod && paymentMethod === 'DEBIT') {
        result = {
          title: 'Débito Inter',
          subTitle: balanceAvailable || '',
          type: paymentMethod,
        }
      } else {
        result = {
          title: 'Crédito Inter',
          subTitle: showDigits || `**** ${lastFourDigits ?? '****'}`,
          type: paymentMethod,
        }
      }

      return result
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'CheckoutBaseService.openWallet',
      })

      return Promise.reject(error)
    }
  }

  /**
   * Método invocado para finalizar a contratação via wallet.
   * @param amount valor da transação em reais.
   * @returns o status e o id da transação de contratação.
   */
  static async contractWallet(amount: string): Promise<ContractWalletResult> {
    if (BaseBridge.isBrowser()) {
      return Promise.resolve({
        transactionId: '',
        status: 'SUCCESS',
      } as ContractWalletResult)
    }

    if (!this.openWalletResponse) throw new Error('Erro ao resgatar os dados da forma de pagamento')

    const subscriptionPrice = Number.parseFloat(amount)

    const transactionType: TransactionType = {
      code: '16',
      checkType: 5,
      description: 'AUTORIZACAO_SEGURO',
      value: subscriptionPrice,
    }

    try {
      const result = await CheckoutBridge.contractWallet(this.openWalletResponse, transactionType)

      if (result.status === 'FAILURE') throw new Error(result.error)

      const transactionId =
        (result as ContractWalletResponseAndroid)?.walletLibraryCheckoutResult?.walletClientId ||
        (result as ContractWalletResponseIOS)?.response?.clientTransactionId

      return {
        transactionId,
        status: result.status,
      }
    } catch (error) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'CheckoutBaseService.contractWallet',
      })

      return Promise.reject(error)
    }
  }
}

export interface ContractWalletResult {
  transactionId: string
  status: string
}

export interface ChosenPaymentResult {
  title: string
  subTitle: string
  type: string
}
