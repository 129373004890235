import Agenda from '@interco/inter-frontend-svgs/lib/orangeds/MD/agenda'
import ArrowLeft from '@interco/inter-frontend-svgs/lib/orangeds/MD/arrow-left'
import Attachment from '@interco/inter-frontend-svgs/lib/orangeds/MD/attachment'
import BookOpen from '@interco/inter-frontend-svgs/lib/orangeds/MD/book-open'
import Building from '@interco/inter-frontend-svgs/lib/orangeds/MD/building'
import Camera from '@interco/inter-frontend-svgs/lib/orangeds/MD/camera-on'
import Canceled from '@interco/inter-frontend-svgs/lib/orangeds/MD/canceled'
import Card from '@interco/inter-frontend-svgs/lib/orangeds/MD/card'
import Check from '@interco/inter-frontend-svgs/lib/orangeds/MD/check'
import CheckCircle from '@interco/inter-frontend-svgs/lib/orangeds/MD/check-circle'
import ChevronRight from '@interco/inter-frontend-svgs/lib/orangeds/MD/chevron-right'
import Couch from '@interco/inter-frontend-svgs/lib/orangeds/MD/couch'
import Delete from '@interco/inter-frontend-svgs/lib/orangeds/MD/delete'
import Doctor from '@interco/inter-frontend-svgs/lib/orangeds/MD/doctor'
import Dollar from '@interco/inter-frontend-svgs/lib/orangeds/MD/dollar'
import Edit from '@interco/inter-frontend-svgs/lib/orangeds/MD/edit'
import Exit from '@interco/inter-frontend-svgs/lib/orangeds/MD/exit'
import GeneralPractitioner from '@interco/inter-frontend-svgs/lib/orangeds/MD/general-practitioner'
import Network from '@interco/inter-frontend-svgs/lib/orangeds/MD/globe'
import Health from '@interco/inter-frontend-svgs/lib/orangeds/MD/health'
import Heart from '@interco/inter-frontend-svgs/lib/orangeds/MD/heart'
import Help from '@interco/inter-frontend-svgs/lib/orangeds/MD/help'
import Home from '@interco/inter-frontend-svgs/lib/orangeds/MD/home'
import Info from '@interco/inter-frontend-svgs/lib/orangeds/MD/info'
import LightOn from '@interco/inter-frontend-svgs/lib/orangeds/MD/light-on'
import Location from '@interco/inter-frontend-svgs/lib/orangeds/MD/location'
import Money from '@interco/inter-frontend-svgs/lib/orangeds/MD/money-down'
import Nutritionist from '@interco/inter-frontend-svgs/lib/orangeds/MD/nutritionist'
import Pending from '@interco/inter-frontend-svgs/lib/orangeds/MD/pending'
import Pill from '@interco/inter-frontend-svgs/lib/orangeds/MD/pill'
import Power from '@interco/inter-frontend-svgs/lib/orangeds/MD/power'
import Psychologist from '@interco/inter-frontend-svgs/lib/orangeds/MD/psychologist'
import Reuse from '@interco/inter-frontend-svgs/lib/orangeds/MD/reuse'
import Search from '@interco/inter-frontend-svgs/lib/orangeds/MD/search'
import SignOut from '@interco/inter-frontend-svgs/lib/orangeds/MD/sign-out'
import Smartphones from '@interco/inter-frontend-svgs/lib/orangeds/MD/smartphones'
import UserAccount from '@interco/inter-frontend-svgs/lib/orangeds/MD/user-account'
import Wallet from '@interco/inter-frontend-svgs/lib/orangeds/MD/wallet'

export const Icons = {
  Attachment,
  Couch,
  Network,
  Agenda,
  ArrowLeft,
  BookOpen,
  Building,
  Camera,
  Canceled,
  Card,
  Check,
  CheckCircle,
  ChevronRight,
  Power,
  Delete,
  Doctor,
  Dollar,
  Edit,
  Exit,
  GeneralPractitioner,
  Health,
  Heart,
  Help,
  Home,
  Info,
  Money,
  Nutritionist,
  Pending,
  Pill,
  Psychologist,
  Reuse,
  Search,
  Smartphones,
  UserAccount,
  Wallet,
  LightOn,
  Location,
  SignOut,
}
