import React from 'react'

import { SkeletonBox, SkeletonContent, SkeletonGrid } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadListDescription: React.FC = () => (
  <SkeletonBox>
    <SkeletonGrid>
      <InterUISkeleton width="24px" height="24px" />
      <SkeletonContent>
        <InterUISkeleton width="50%" marginBottom="8px" />
        <InterUISkeleton width="80%" />
      </SkeletonContent>
      <InterUISkeleton width="24px" height="24px" />
    </SkeletonGrid>
  </SkeletonBox>
)
