import styled from 'styled-components'

export const ErrorContainer = styled.section`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.theme};
  z-index: 999;
  text-align: center;
`
export const Container = styled.div`
  height: calc(100vh - 56px);
  margin: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .foot-button {
    margin-top: auto;
    width: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const BtnBack = styled.button`
  position: absolute;
  top: 19px;
  right: 24px;
`
