import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

export enum HealthcareTypes {
  CREATE_ATTENDANCE = `@healthcare/CREATE_ATTENDANCE`,
  GET_QUEUE_ID = `@healthcare/GET_QUEUE_ID`,
  SET_QUEUE_ID = `@healthcare/SET_QUEUE_ID`,
  GET_ATTENDANCE_REQUEST = `@healthcare/GET_ATTENDANCE_REQUEST`,
  GET_ATTENDANCE = `@healthcare/GET_ATTENDANCE`,
  SET_ATTENDANCE = `@healthcare/SET_ATTENDANCE`,
  GET_VIDEO_CALL_URL_REQUEST = `@healthcare/GET_VIDEO_CALL_URL_REQUEST`,
  GET_VIDEO_CALL_URL = `@healthcare/GET_VIDEO_CALL_URL`,
  SET_VIDEO_CALL_URL = `@healthcare/SET_VIDEO_CALL_URL`,
  GET_APPOINTMENT_READY = `@healthcare/GET_APPOINTMENT_READY`,
  SET_APPOINTMENT_READY = `@healthcare/SET_APPOINTMENT_READY`,
  CANCEL_ATTENDANCE = `@healthcare/CANCEL_ATTENDANCE`,
  GET_REDIRECT_TO_CANCEL_ATTENDANCE = `@healthcare/GET_REDIRECT_TO_CANCEL_ATTENDANCE`,
  SET_REDIRECT_TO_CANCEL_ATTENDANCE = `@healthcare/SET_REDIRECT_TO_CANCEL_ATTENDANCE`,
  VERIFY_ATTENDANCE_STATUS = `@healthcare/VERIFY_ATTENDANCE_STATUS`,
  GET_ATTENDANCE_STATUS = `@healthcare/GET_ATTENDANCE_STATUS`,
  SET_ATTENDANCE_STATUS = `@healthcare/SET_ATTENDANCE_STATUS`,
}

export interface ICreateAttendanceResponse {
  protocolId: string
}

export interface IVideoCallRequest {
  onTransmission: () => void
}

export interface ICancelAttendanceRequest extends SagaWithHistory {
  patientId?: number
  reason: string
  protocolId?: string
}

export interface RedirectToCancel extends SagaWithHistory {
  type: string
}

export interface ICreateAttendanceRequest {
  patientId: number
  specialtyId?: number
  professionalType: string
  symptoms: string[]
  medicines: number[]
  isFreeAppointment: boolean
}

export interface ISpontaneousDemands {
  attendance: IAttendance
  videocall?: IVideoCall
}
export interface IAttendance {
  queueSize: number
  queuePosition: number
  queueEntryTime: string
  minimumWaitTime: number
  maximumWaitTime: number
  estimatedWaitTime: number
  minimumTimeForAttendance: string
  maximumTimeForAttendance: string
  protocolId: string
}

export interface IVideoCall {
  appointmentId: number
  callId: number
  doctorId: number
  url: string
}

export interface HealthcareState {
  readonly protocolId: ICreateAttendanceResponse
  readonly spontaneousDemands: ISpontaneousDemands
  readonly videoCall?: IVideoCall
  readonly redirectToCancel: RedirectToCancel
  readonly isAppointmentReady: boolean
  readonly attendanceStatus: 'FREE' | 'PENDING' | 'N.A.'
}
