import { Reducer } from 'redux'

import { IVideoCallRedirectParams, PathParamsState, PathParamsTypes } from './types'

const INITIAL_STATE: PathParamsState = {
  videocallRedirectParams: {} as IVideoCallRedirectParams,
  appointmentPathParams: '',
  prefixPathParams: '',
  themePathParams: '',
  utmCampaignParams: '',
}

export const reducer: Reducer<PathParamsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PathParamsTypes.SET_APPOINTMENT_PATH_PARAMS:
      return { ...state, appointmentPathParams: action.payload }
    case PathParamsTypes.SET_VIDEOCALL_REDIRECT_PARAMS:
      return { ...state, videocallRedirectParams: action.payload }
    case PathParamsTypes.SET_PREFIX_PATH_PARAMS:
      return { ...state, prefixPathParams: action.payload }
    case PathParamsTypes.SET_THEME_PARAMS:
      return { ...state, themePathParams: action.payload }
    case PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS:
      return { ...state, utmCampaignParams: action.payload }
    default:
      return state
  }
}

export default reducer
