import { Reducer } from 'redux'

import { SymptomsTypes, SymptomsState } from './types'

const INITIAL_STATE: SymptomsState = {
  selectedSymptoms: [],
  symptomsList: [],
  selectedOtherSymptoms: '',
}

const reducer: Reducer<SymptomsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SymptomsTypes.SET_SYMPTOMS_LIST:
      return { ...state, symptomsList: action.payload }
    case SymptomsTypes.SET_SELECTED_SYMPTOMS:
      return { ...state, selectedSymptoms: action.payload }
    case SymptomsTypes.SET_OTHER_SELECTED_SYMPTOMS:
      return { ...state, selectedOtherSymptoms: action.payload }

    default:
      return state
  }
}

export default reducer
