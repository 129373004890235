import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { isStringNotEmpty } from 'src/utils/commons'

import { EmbedContainer } from './VideoCall.styles'

export const VideoCall: React.FC = () => {
  const videoCallInfos = useSelector(HealthcareActions.getVideoCallInfos)
  const videoCallParams = useSelector(PathParamsActions.getVideoCallRedirectParams)

  const [loadIframe, setLoadIframe] = useState(false)
  const [loadFinish, setLoadFinish] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (isStringNotEmpty(videoCallInfos?.url) || isStringNotEmpty(videoCallParams?.videocall)) {
      setLoadIframe(true)
    }
  }, [videoCallInfos, videoCallParams?.videocall])

  useEffect(() => {
    if (loadFinish) {
      dispatch(HealthcareActions.setAppointmentReady(true))
    }
  }, [loadFinish, dispatch])

  const hideLoading = () => {
    setLoadFinish(true)
  }

  const onError = (e: unknown) => {
    NewRelicUtils.noticeError(new Error(JSON.stringify(e)), {
      errorCodeRef: '[VideoCall] Erro durante a video chamada.',
    })
  }

  const videoCallEmbed = (url?: string) => {
    if (url) {
      return (
        <EmbedContainer id="iframe-container">
          <iframe
            id="iframe-videocall"
            title="Video chamada."
            onLoad={hideLoading}
            onError={onError}
            allow="camera *;microphone *; encrypted-media; display-capture; autoplay"
            src={url}
            style={{ flex: 1 }}
          />
        </EmbedContainer>
      )
    }
    return <></>
  }

  return loadIframe ? videoCallEmbed(videoCallInfos?.url || videoCallParams?.videocall) : null
}
