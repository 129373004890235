import { RootState } from 'src/store'
import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'
import { action } from 'typesafe-actions'

import {
  HealthcareTypes,
  ICancelAttendanceRequest,
  ICreateAttendanceResponse,
  ISpontaneousDemands,
  IVideoCall,
  IVideoCallRequest,
  RedirectToCancel,
} from './types'

const createAttendance = (data?: SagaWithHistory) => action(HealthcareTypes.CREATE_ATTENDANCE, data)

const cancelAttendance = (data: ICancelAttendanceRequest) =>
  action(HealthcareTypes.CANCEL_ATTENDANCE, data)

const getRedirectToCancel = (state: RootState) => state.healthcare.redirectToCancel

const setRedirectToCancel = (data: RedirectToCancel) =>
  action(HealthcareTypes.SET_REDIRECT_TO_CANCEL_ATTENDANCE, data)

const getProtocolId = (state: RootState) => state.healthcare.protocolId

const setProtocolId = (data: ICreateAttendanceResponse) =>
  action(HealthcareTypes.SET_QUEUE_ID, data)

const getAttendanceRequest = (data?: SagaWithHistory) =>
  action(HealthcareTypes.GET_ATTENDANCE_REQUEST, data)

const getAttendance = (state: RootState) => state.healthcare.spontaneousDemands

const setAttendance = (data: ISpontaneousDemands) => action(HealthcareTypes.SET_ATTENDANCE, data)

const getVideoCallUrlRequest = (data?: IVideoCallRequest) =>
  action(HealthcareTypes.GET_VIDEO_CALL_URL_REQUEST, data)

const getVideoCallInfos = (state: RootState) => state.healthcare.videoCall

const setVideoCallInfos = (data?: IVideoCall) => action(HealthcareTypes.SET_VIDEO_CALL_URL, data)

const isAppointmentReady = (state: RootState) => state.healthcare.isAppointmentReady

const setAppointmentReady = (data: boolean) => action(HealthcareTypes.SET_APPOINTMENT_READY, data)

const verifyAttendanceStatus = (data: SagaWithHistory) =>
  action(HealthcareTypes.VERIFY_ATTENDANCE_STATUS, data)

const getAttendanceStatus = (state: RootState) => state.healthcare.attendanceStatus

const setAttendanceStatus = (data: 'FREE' | 'PENDING' | 'N.A.') =>
  action(HealthcareTypes.SET_ATTENDANCE_STATUS, data)

export const HealthcareActions = {
  createAttendance,
  getAttendanceRequest,
  cancelAttendance,
  getProtocolId,
  setProtocolId,
  getAttendance,
  setAttendance,
  getVideoCallUrlRequest,
  getVideoCallInfos,
  setVideoCallInfos,
  isAppointmentReady,
  setAppointmentReady,
  getRedirectToCancel,
  setRedirectToCancel,
  verifyAttendanceStatus,
  getAttendanceStatus,
  setAttendanceStatus,
}
