import React from 'react'

import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { ContainerFullPage, H1, InfoSign, PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ContentMaintenance } from './Maintenance.styles'

export const Maintenance: React.FC = () => {
  const theme = useTheme()

  const maintenanceIcon = <Icons.Power width={32} height={32} color={theme.colors.primary.A500} />

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    backNavigationHandler: BaseBridge.requestGoBack,
    hideHomeBtn: true,
  })

  const stickyFooter = (
    <Button margin="24px 0" onClick={BaseBridge.requestGoBack}>
      Entendi
    </Button>
  )

  return (
    <InterUIContainer margin="0 24px" stickyFooter={stickyFooter}>
      <ContainerFullPage>
        <ContentMaintenance>
          <InfoSign style={{ rotate: '45deg' }}>{maintenanceIcon}</InfoSign>
          <H1 margin="24px 0 8px">Estamos em manutenção</H1>
          <PMedium scale={400} marginBottom="0">
            Durante este período, as consultas estão indisponíveis. Mas não se preocupe, o serviço
            será reativado em algumas horas
          </PMedium>
        </ContentMaintenance>
      </ContainerFullPage>
    </InterUIContainer>
  )
}
