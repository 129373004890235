import React from 'react'

import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { ContainerFullPage, H1, PMedium, SuccessSign } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ContentSuccess } from './AppointmentConfirmation.styles'

export const AppointmentConfirmation: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    hideBackNavigationBtn: true,
  })

  const stickyFooter = (
    <Button margin="24px 0" onClick={() => history.push(TypesRoutes.START)}>
      Voltar para home
    </Button>
  )

  return (
    <InterUIContainer margin="0 24px" stickyFooter={stickyFooter}>
      <ContainerFullPage>
        <ContentSuccess>
          <SuccessSign>{successIcon}</SuccessSign>
          <H1 margin="24px 0 8px">Consulta marcada</H1>
          <PMedium scale={400} marginBottom="0">
            A confirmação do agendamento foi enviada para o e-mail em sua conta.
          </PMedium>
        </ContentSuccess>
      </ContainerFullPage>
    </InterUIContainer>
  )
}
