import BaseService from 'src/config/bridge/BaseService'
import { IFileUploadRequest } from 'src/store/ducks/attachFiles/types'

export default class FileService {
  static async uploadFiles({ protocolId, ...payload }: IFileUploadRequest) {
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<unknown>({
      method: 'PUT',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/spontaneous-demands/${protocolId}/file-attachment`,
      data,
    })
  }
}
