import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { browserTransactionId } from 'src/mocks/Checkout'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import SchedulingService from 'src/services/SchedulingService'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { PersistedCacheActions } from 'src/store/ducks/persistedCache/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'

import { SchedulingActions } from './actions'
import {
  IAppointment,
  ICancelSchedulingRequest,
  ICreateReschedulingRequest,
  ICreateSchedulingRequest,
  IDataAndTimeAvailableRequest,
  IDoctorDetails,
  IProfessionalsRequest,
  IRealizedAppointmentsRequest,
  ISchedules,
  SchedulingTypes,
} from './types'

function* getSpecialtiesList({ payload }: AnyAction) {
  try {
    const response = yield* call(SchedulingService.getSpecialtiesList)

    yield* put(SchedulingActions.setSpecialtiesList(response.object.specialties))

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActions.getSpecialtiesList')
  }
}

function* getDoctorsList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { specialtyId, page } = payload

    if (specialtyId && page) {
      const response = yield* call(SchedulingService.getDoctorsList, payload)

      yield* put(SchedulingActions.setDoctorsList(response.object.professionals))

      yield* put(SchedulingActions.getRealizedAppointmentsRequest())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'SchedulingActions.getDoctorsList')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActions.getDoctorsList')
  }
}

function* getProfessionalsList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const request: IProfessionalsRequest = {
      patientId: payload.patientId,
      page: payload.page,
      type: payload.type,
    }

    if (request) {
      yield* put(SchedulingActions.setProfessionalsList([]))

      const response = yield* call(SchedulingService.getProfessionalList, request)

      yield* put(SchedulingActions.setProfessionalsList(response.object.professionals))

      yield* put(SchedulingActions.getRealizedAppointmentsRequest())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'SchedulingActions.getProfessionalList')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActions.getProfessionalList')
  }
}

function* getDoctorDetails({ payload }: AnyAction) {
  try {
    const { doctorId, callback } = payload

    if (!callback) yield* put(LoadingActions.show())

    if (doctorId) {
      const response = yield* call(SchedulingService.getDoctorDetails, doctorId)

      let doctorDetails: IDoctorDetails

      if (response.status === 204) {
        doctorDetails = {} as IDoctorDetails
      } else {
        doctorDetails = response.object.doctor
      }

      if (callback) callback()

      yield* put(SchedulingActions.setDoctorDetails(doctorDetails))

      yield* delay(2000)

      yield* put(LoadingActions.hide())

      redirectThroughSaga(payload)
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'SchedulingActions.getDoctorsDetails')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingAction.getDoctorsDetails')
  }
}

function* getDateAndTimeAvailable({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const request: IDataAndTimeAvailableRequest = {
      professionalId: payload.professionalId,
      startDate: payload.startDate,
      endDate: payload.endDate,
      type: payload.type,
    }

    const response = yield* call(SchedulingService.getDateAndTimeAvailable, request)

    const currentDate = new Date().getTime()
    const datesAvailable24hLater: ISchedules[] = []

    let dateAvailable: string
    let timesAvailable: string[] = []
    const dayInMilliseconds = 86400000 // 24 horas

    response.object.schedules.forEach((schedule) => {
      const dates = schedule.date.split('/')

      schedule.times.forEach((time) => {
        const times = time.split(':')
        const scheduleDate = new Date(
          `${dates[2]}-${dates[1]}-${dates[0]}T${times[0]}:${times[1]}:00`,
        )

        const diference = scheduleDate.getTime() - currentDate
        if (diference > dayInMilliseconds) {
          dateAvailable = schedule.date
          timesAvailable.push(time)
        }
      })

      if (timesAvailable.length > 0) {
        datesAvailable24hLater.push({
          date: dateAvailable,
          times: timesAvailable,
        })
      }

      timesAvailable = []
    })

    yield* put(SchedulingActions.setDateAndTimeAvailable(datesAvailable24hLater))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingAction.getDateAndTimeAvailable')
  }
}

function* getAppointmentsList({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { patientId } = yield* select(SubscriptionActions.getHolder)

    if (patientId) {
      const response = yield* call(SchedulingService.getAppointmentsList, patientId)

      yield* put(SchedulingActions.setAppointmentList(response.object.appointments))
    }

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingAction.getAppointmentsList')
  }
}

function* createScheduling({ payload }: AnyAction) {
  try {
    const subscription = yield* select(SubscriptionActions.getSubscription)
    const professional = yield* select(SchedulingActions.getSelectedProfessional)
    const type = yield* select(SchedulingActions.getProfessionalType)
    const dateTime = yield* select(SchedulingActions.getSelectedDateTime)
    const transactionId = yield* select(CheckoutActions.getTransactionId)

    const createSchedulingRequest: ICreateSchedulingRequest = {
      transactionId: BaseBridge.isBrowser() ? browserTransactionId.processed : transactionId,
      patientId: subscription.patientId,
      professionalId: professional.id,
      professionalType: type.type,
      professionalName: professional.name,
      professionalSpecialty: professional.specialty,
      appointmentDate: dateTime,
    }

    yield* call(SchedulingService.createScheduling, createSchedulingRequest)

    if (payload) {
      redirectThroughSaga(payload)
    }
    yield* put(PersistedCacheActions.clearAppointmentsListCache())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActions.createScheduling')
  }
}

function* cancelScheduling({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const appointment = yield* select(SchedulingActions.getSelectedAppointment)
    const subscription = yield* select(SubscriptionActions.getSubscription)

    const cancelSchedulingRequest: ICancelSchedulingRequest = {
      appointmentId: appointment.id,
      patientId: subscription.patientId,
      professionalType: appointment.professional.type,
    }

    yield* call(SchedulingService.cancelScheduling, cancelSchedulingRequest)

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
    yield* put(PersistedCacheActions.clearAppointmentsListCache())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActions.cancelScheduling')
  }
}

function* createRescheduling({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const appointment = yield* select(SchedulingActions.getSelectedAppointment)
    const subscription = yield* select(SubscriptionActions.getSubscription)
    const dateTime = yield* select(SchedulingActions.getSelectedDateTime)

    const createReschedulingRequest: ICreateReschedulingRequest = {
      patientId: subscription.patientId,
      appointmentId: appointment.id,
      professionalId: appointment.professional.id,
      professionalType: appointment.professional.type,
      professionalName: appointment.professional.name,
      professionalSpecialty: appointment.professional.specialty,
      appointmentDate: dateTime,
    }

    yield* call(SchedulingService.createRescheduling, createReschedulingRequest)

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
    yield* put(PersistedCacheActions.clearAppointmentsListCache())
  } catch (error) {
    const errorDetails = {
      title: 'Erro ao reagendar consulta',
      subTitle:
        'Não conseguimos realizar o agendamento da sua consulta. Por favor, tente novamente mais tarde.',
      route: TypesRoutes.HOME_DR_INTER,
    }

    yield* errorHandlingSaga(error as Error, 'SchedulingActions.createRescheduling', errorDetails)
  }
}

function* professionalType({ payload }: AnyAction) {
  try {
    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActionsSaga.professionalType')
  }
}

function* appointmentType({ payload }: AnyAction) {
  try {
    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingActionsSaga.appointmentType')
  }
}

function* getRealizedAppointments() {
  try {
    const { patientId } = yield* select(SubscriptionActions.getHolder)
    const type = yield* select(SchedulingActions.getProfessionalType)
    const selectedSpecialty = yield* select(SchedulingActions.getSelectedSpecialty)

    if (patientId) {
      const realizedAppointmentsRequest: IRealizedAppointmentsRequest = {
        patientId,
        professionalType: type.type,
        specialty: type.type === 'DOCTOR' ? selectedSpecialty.description : '',
      }

      const response = yield* call(
        SchedulingService.getRealizedAppointments,
        realizedAppointmentsRequest,
      )

      let appointments: IAppointment[]

      if (response.status === 204) {
        appointments = [] as IAppointment[]
      } else {
        appointments = response.object.appointments
      }

      yield* put(SchedulingActions.setRealizedAppointments(appointments))

      yield* put(LoadingActions.hide())
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SchedulingAction.getRealizedAppointments')
  }
}

export default function* watchScheduling() {
  yield* takeLatest(SchedulingTypes.GET_SPECIALTIES_LIST_REQUEST, getSpecialtiesList)
  yield* takeLatest(SchedulingTypes.GET_DOCTORS_LIST_REQUEST, getDoctorsList)
  yield* takeLatest(SchedulingTypes.GET_PROFESSIONAL_LIST_REQUEST, getProfessionalsList)
  yield* takeLatest(SchedulingTypes.GET_DOCTOR_DETAILS_REQUEST, getDoctorDetails)
  yield* takeLatest(SchedulingTypes.SET_PROFESSIONAL_TYPE, professionalType)
  yield* takeLatest(SchedulingTypes.GET_DATE_AND_TIME_AVAILABLE_REQUEST, getDateAndTimeAvailable)
  yield* takeLatest(SchedulingTypes.GET_APPOINTMENTS_LIST_REQUEST, getAppointmentsList)
  yield* takeLatest(SchedulingTypes.CREATE_SCHEDULING, createScheduling)
  yield* takeLatest(SchedulingTypes.CANCEL_SCHEDULING, cancelScheduling)
  yield* takeLatest(SchedulingTypes.CREATE_RESCHEDULING, createRescheduling)
  yield* takeLatest(SchedulingTypes.SET_APPOINTMENT_TYPE, appointmentType)
  yield* takeLatest(SchedulingTypes.GET_REALIZED_APPOINTMENTS_REQUEST, getRealizedAppointments)
}
