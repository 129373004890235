import React from 'react'

import { InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { SkeletonContent } from '../Skeleton.styles'

export const LoadDoctorDetails: React.FC = () => (
  <SkeletonContent data-testid="loading-details">
    <SkeletonContent>
      <InterUISkeleton height="20px" marginBottom="4px" />
      <InterUISkeleton width="70%" height="20px" marginBottom="16px" />
    </SkeletonContent>

    <SkeletonContent>
      <InterUISkeleton width="30%" height="20px" marginBottom="4px" />
      <InterUISkeleton height="48px" marginBottom="24px" />
    </SkeletonContent>

    <InterUISeparator margin="0 0 24px" variant="small" />

    <SkeletonContent>
      <InterUISkeleton width="30%" height="20px" marginBottom="4px" />
      <InterUISkeleton height="48px" marginBottom="16px" />
    </SkeletonContent>

    <InterUISkeleton height="48px" />
  </SkeletonContent>
)
