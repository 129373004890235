import React, { Fragment } from 'react'

import { PMedium } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIListDescription, InterUISeparator } from '@interco/inter-ui-react-lib'

export const ListBenefits: React.FC = () => {
  const theme = useTheme()

  const cardIcon = <Icons.Card color={theme.colors.neutral.grayscale.A500} />
  const moneyIcon = <Icons.Money color={theme.colors.neutral.grayscale.A500} />
  const generalPractitionerIcon = (
    <Icons.GeneralPractitioner color={theme.colors.neutral.grayscale.A500} />
  )
  const doctorIcon = <Icons.Doctor color={theme.colors.neutral.grayscale.A500} />

  const benefits = [
    {
      icon: doctorIcon,
      text: 'R$ 50,90 para consultas de pronto atendimento com clínico geral.',
    },
    {
      icon: generalPractitionerIcon,
      text: 'R$ 77,00 para consultas agendadas com profissionais da saúde especializados.',
    },
    {
      icon: cardIcon,
      text: 'Pagamentos podem ser realizados com cartão de crédito ou débito.',
    },
    {
      icon: moneyIcon,
      text: 'Descontos em farmácias de até 30% e em exames laboratoriais em nossa rede credenciada de até 15%.',
    },
  ]

  return (
    <>
      {benefits.map((item, index) => {
        const key = `benefit-${index}`
        return (
          <Fragment key={key}>
            <InterUIListDescription id={key} iconLeft={item.icon}>
              <PMedium marginBottom="0" scale={400}>
                {item.text}
              </PMedium>
            </InterUIListDescription>

            {index < benefits.length - 1 && <InterUISeparator variant="small" margin="24px 0" />}
          </Fragment>
        )
      })}
    </>
  )
}
