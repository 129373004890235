import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import Webcam from 'react-webcam'
import { Button } from 'src/components'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { PersistedCacheActions } from 'src/store/ducks/persistedCache/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { ContainerFullPage, H1, PMedium } from 'src/styles/commons'
import { extractBase64Content, generateId } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUIListDescription } from '@interco/inter-ui-react-lib'

import { CapturePhotoButton, ContainerCaptureImage, Image } from './CaptureImage.styles'

export enum CameraScreens {
  INSTRUCTIONS = 'INSTRUCTIONS',
  PHOTO = 'PHOTO',
  REVIEW = 'REVIEW',
}
interface CaptureImageProps {
  setShowCamera: Dispatch<SetStateAction<boolean>>
  setCameraScreen: Dispatch<SetStateAction<CameraScreens>>
}

export const CaptureImage: React.FC<CaptureImageProps> = ({ setShowCamera, setCameraScreen }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const selectedFilesAttached = useSelector(FilesActions.getSelectedFilesAttached)

  const addImageToSelectedFilesAttached = (fileBase64: string | undefined) => {
    const id = generateId()
    const fileInfo = {
      id,
      name: `foto-${id}`,
      fileExtension: 'jpeg',
      fileContent: extractBase64Content(fileBase64) || '',
    }

    const attachedFiles = Object.assign([], selectedFilesAttached.files)
    const tempFiles = [...attachedFiles, fileInfo]

    dispatch(FilesActions.setSelectedFilesAttached({ files: tempFiles }))
  }

  const iconSmartphone = (
    <Icons.Smartphones width={32} height={32} color={theme.colors.primary.A500} />
  )
  const iconChevronRight = (
    <Icons.ChevronRight width={24} height={24} color={theme.colors.base.white} />
  )

  const iconCamera = (
    <Icons.Camera width={24} height={24} color={theme.colors.neutral.grayscale.A400} />
  )
  const iconLightOn = (
    <Icons.LightOn width={24} height={24} color={theme.colors.neutral.grayscale.A400} />
  )

  const cameraFirstAccess = useSelector(PersistedCacheActions.getCameraFirstAccess)
  const professionalType = useSelector(SchedulingActions.getProfessionalType)

  const webcamRef = useRef<Webcam>(null)

  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (!cameraFirstAccess) {
      setCameraScreen(CameraScreens.INSTRUCTIONS)
    } else if (!image) {
      setCameraScreen(CameraScreens.PHOTO)
    } else {
      setCameraScreen(CameraScreens.REVIEW)
    }
  }, [cameraFirstAccess, image])

  /**
   * Rear camera: facingMode: { exact: 'environment' }
   * Front camera: facingMode: 'user'
   */
  const videoConstraints = {
    facingMode: { exact: 'environment' },
  }

  const handleClickCapture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot() as string
      setImage(imageSrc)
    }
  }

  const handleClickGotGood = () => {
    if (professionalType.type) {
      // É o fluxo de agendamento
      // history.push(TypesRoutes.ALGUMA_COISA)
    } else {
      // É o fluxo de pronto atendimento
      addImageToSelectedFilesAttached(image)
      setShowCamera(false)
    }
  }

  const handleClickProceed = () => {
    dispatch(PersistedCacheActions.setCameraFirstAccess())
  }

  const capturePhoto = (
    <CapturePhotoButton data-testid="test-capture-photo-button" onClick={handleClickCapture} />
  )

  const reviewThePicture = (
    <>
      <PMedium margin="16px 24px">
        A foto está nítida e bem iluminada? Lembre-se de que não pode ter reflexos.
      </PMedium>
      <Button margin="0 24px 16px" data-testid="test-got-good" onClick={handleClickGotGood}>
        Ficou boa
      </Button>
      <Button variant="secondary" margin="0 24px 24px" onClick={() => setImage('')}>
        Tirar outra
      </Button>
    </>
  )

  const proceed = (
    <Button alignContent="space-between" data-testid="test-proceed" onClick={handleClickProceed}>
      <PMedium marginBottom="0px">Continuar</PMedium> {iconChevronRight}
    </Button>
  )

  if (!cameraFirstAccess) {
    return (
      <InterUIContainer stickyFooter={proceed}>
        <ContainerFullPage height="136px">
          <ContainerCaptureImage>
            <Image>{iconSmartphone}</Image>
            <H1 marginBottom="36px">Para anexar sua foto, siga as instruções</H1>
            <InterUIListDescription alignAllItems="center" iconLeft={iconLightOn} margin="0 0 24px">
              <PMedium marginBottom="0" scale={400}>
                Escolha um ambiente com boa iluminação.
              </PMedium>
            </InterUIListDescription>
            <InterUIListDescription alignAllItems="center" iconLeft={iconCamera}>
              <PMedium marginBottom="0" scale={400}>
                As fotos devem ser bem iluminadas. Centralize o documento, sem deixar seu dedo
                aparecer.
              </PMedium>
            </InterUIListDescription>
          </ContainerCaptureImage>
        </ContainerFullPage>
      </InterUIContainer>
    )
  }

  if (!image) {
    return (
      <>
        <InterUIContainer margin="0px" stickyFooter={capturePhoto}>
          <ContainerFullPage height="96px">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              autoPlay
              poster="poster"
              minScreenshotHeight={1080}
              minScreenshotWidth={1920}
              style={{ width: '100%', height: '100%' }}
              videoConstraints={videoConstraints}
            />
          </ContainerFullPage>
        </InterUIContainer>
      </>
    )
  }

  return (
    <InterUIContainer margin="0px" stickyFooter={reviewThePicture}>
      <ContainerFullPage height="202px" overflowHidden>
        <img
          src={image}
          alt="Foto Capturada"
          style={{ display: 'block', margin: 'auto', width: '100%' }}
        />
      </ContainerFullPage>
    </InterUIContainer>
  )
}
