export enum SpecialtiesTypes {
  GET_SPECIALTIES_REQUEST = `@specialties/GET_SPECIALTIES_REQUEST`,
  SET_HIGHLIGHTS_LIST = `@specialties/SET_HIGHLIGHTS_LIST`,
  SET_SPONTANEOUS_DEMANDS_LIST = `@specialties/SET_SPONTANEOUS_DEMANDS_LIST`,
  SET_SPECIALTIES_LIST = `@specialties/SET_SPECIALTIES_LIST`,
}

export interface SpecialtiesResponse {
  highlights: ISpecialtyFields[]
  spontaneousDemands: ISpecialtyFields[]
  specialties: ISpecialties[]
}

export interface ISpecialtyFields {
  name: string
  description: string
  route: string
  icon: string
  price: string
}

export interface ISpecialties {
  name: string
  description: string
}

export interface SpecialtiesState {
  readonly highlightsList: ISpecialtyFields[]
  readonly spontaneousDemandsList: ISpecialtyFields[]
  readonly specialtiesList: ISpecialties[]
}
