export function onlyNumber(event: React.ChangeEvent<HTMLInputElement>) {
  const cepValues = event.target.value
  event.target.value = cepValues.replace(/\D/g, '')
}

export function convertDateToBrazil(date: string) {
  try {
    const formatDate = new Date(date)

    return formatDate.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  } catch {
    return date
  }
}

export function maskFullName(value: string) {
  try {
    return value
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase())
      .replace(/[.]/g, '')
  } catch {
    return value
  }
}

export function maskDate(value: string) {
  try {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1')
  } catch {
    return value
  }
}

export function maskCpf(value?: string) {
  if (!value) return value

  try {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  } catch {
    return value
  }
}

export function maskOnlyLetters(value: string) {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, '')
}

export function maskPhone(value: string) {
  try {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d{4})/, '$1-$2')
  } catch {
    return value
  }
}
