import React from 'react'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

import { LoadSmallListDescription } from '../components/LoadSmallListDescription /LoadSmallListDescription '
import { SkeletonContent } from '../Skeleton.styles'

export const LoadSpecialties: React.FC = () => (
  <SkeletonContent data-testid="loading-specialties">
    <SkeletonContent>
      <InterUISkeleton width="70%" marginBottom="24px" />
      <InterUISkeleton height="20px" marginBottom="24px" />
      <InterUISkeleton height="50px" marginBottom="24px" />
    </SkeletonContent>

    <SkeletonContent>
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
      <LoadSmallListDescription />
    </SkeletonContent>
  </SkeletonContent>
)
