import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { AttachFilesTypes, IAttachedFiles, IUploadFilesResult } from './types'

const getSelectedFilesAttached = (state: RootState) => state.attachFiles.selectedAttachedFiles

const setSelectedFilesAttached = (data: IAttachedFiles) =>
  action(AttachFilesTypes.SET_SELECTED_FILES_ATTACHED, data)

const uploadSelectedFiles = () => action(AttachFilesTypes.UPLOAD_SELECTED_FILES_ATTACHED)

const getUploadedFilesResult = (state: RootState) => state.attachFiles.uploadedFilesResult

const setUploadFilesResult = (data: IUploadFilesResult) =>
  action(AttachFilesTypes.SET_UPLOAD_FILES_RESULT, data)

export const FilesActions = {
  setSelectedFilesAttached,
  getSelectedFilesAttached,
  uploadSelectedFiles,
  getUploadedFilesResult,
  setUploadFilesResult,
}
