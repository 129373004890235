import BaseBridge from 'src/config/bridge/BaseBridge'
import BaseService from 'src/config/bridge/BaseService'
import { SpecialtiesResponse } from 'src/store/ducks/specialties/types'
import {
  ICancellationFeeResponse,
  ICancelSubscriptionRequest,
  ICreateSubscriptionRequest,
  ICreateSubscriptionResponse,
  IPrices,
  ISubscription,
  ITransactionStatusResponse,
} from 'src/store/ducks/subscription/types'

export default class SubscriptionService {
  static async getSpecialties() {
    const response = await BaseService.doExecute<SpecialtiesResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/specialties`,
    })

    return response
  }

  static async createSubscription(payload: ICreateSubscriptionRequest) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
        'x-inter-user-social-id': (await BaseBridge.getUserInfo()).cpf,
      }
    }
    const data = payload as unknown as Record<string, string>

    return BaseService.doExecute<ICreateSubscriptionResponse>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions`,
      data,
      headers,
    })
  }

  static async getSubscription(socialId: string) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
        'x-inter-user-social-id': socialId,
      }
    }

    const response = await BaseService.doExecute<ISubscription>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions`,
      headers,
    })

    return response
  }

  static async getSubscriptionStatus(socialId: string) {
    const headers = {
      'x-inter-user-social-id': socialId,
    }

    return BaseService.doExecute<ISubscription>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions`,
      headers,
    })
  }

  static async cancelSubscription(data: ICancelSubscriptionRequest) {
    return BaseService.doExecute<unknown>({
      method: 'DELETE',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions?withRefund=${data.withRefund}&reason=${data.reason}`,
    })
  }

  static async getPrice() {
    return BaseService.doExecute<IPrices>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions/price`,
    })
  }

  static async getTransactionStatus(transactionId: string) {
    const headers = {
      'x-wallet-transaction-id': transactionId,
    }

    return BaseService.doExecute<ITransactionStatusResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/transactions/status`,
      headers,
    })
  }

  static async activateSubscription(patientId: number) {
    const headers = {
      'x-patient-id': patientId.toString(),
    }

    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions/activate`,
      headers,
    })
  }

  static async termsAndConditions(patientId: number) {
    let headers = {}

    if (BaseBridge.isBrowser()) {
      headers = {
        'x-patient-id': patientId.toString(),
        'x-inter-ip': '192.168.0.1',
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    } else {
      headers = {
        'x-patient-id': patientId.toString(),
        'x-inter-user-bank-account': (await BaseBridge.getUserInfo()).account,
      }
    }

    return BaseService.doExecute<void>({
      method: 'POST',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions/terms-and-conditions/acceptance`,
      headers,
    })
  }

  static async getCancellationFee() {
    return BaseService.doExecute<ICancellationFeeResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_TELEMEDICINE_V1}/subscriptions/cancellation-fee`,
    })
  }
}
