import { Reducer } from 'redux'

import { AttachFilesTypes, FilesState, IAttachedFiles, IUploadFilesResult } from './types'

const INITIAL_STATE: FilesState = {
  selectedAttachedFiles: {} as IAttachedFiles,
  uploadedFilesResult: {} as IUploadFilesResult,
}

const reducer: Reducer<FilesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AttachFilesTypes.SET_SELECTED_FILES_ATTACHED:
      return { ...state, selectedAttachedFiles: action.payload }
    case AttachFilesTypes.SET_UPLOAD_FILES_RESULT:
      return { ...state, uploadedFilesResult: action.payload }
    default:
      return state
  }
}

export default reducer
