/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { HTMLAttributes } from 'react'

import { PSmall } from 'src/styles/commons'

import { BoxSmall, ContainerBox } from './HoursAvailable.styles'

interface HoursAvailableProps extends HTMLAttributes<HTMLDivElement> {
  hoursAvailable: string[]
  selectedHour?: string
  onSelectedHour: (value: string) => void
}

export const HoursAvailable: React.FC<HoursAvailableProps> = ({
  hoursAvailable,
  selectedHour,
  onSelectedHour,
  ...props
}) => (
  <ContainerBox {...props}>
    {hoursAvailable.map((hours, index) => {
      const key = `hoursAvailable-${index}`

      return (
        <BoxSmall
          key={key}
          className={selectedHour === hours ? 'active' : undefined}
          onClick={() => onSelectedHour(hours)}
        >
          <PSmall marginBottom="0px">{hours}</PSmall>
        </BoxSmall>
      )
    })}
  </ContainerBox>
)
