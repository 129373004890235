export enum UserTypes {
  GET_USER = `@user/GET_USER`,
  SET_USER = `@user/SET_USER`,
}

export interface IUser {
  name: string
  email: string
  birthDate: string
}

export interface UserState {
  readonly user: IUser
}
