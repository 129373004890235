import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { PersistedCacheTypes } from './types'

const hasAppointmentsListCache = (state: RootState) => state.persistedCache.hasAppointmentsListCache

const setAppointmentsListCache = () => action(PersistedCacheTypes.STORE)

const clearAppointmentsListCache = () => action(PersistedCacheTypes.CLEAR)

const getCameraFirstAccess = (state: RootState) => state.persistedCache.hasCameraFirstAccess

const setCameraFirstAccess = () => action(PersistedCacheTypes.SET_CAMERA_FIRST_ACCESS)

export const PersistedCacheActions = {
  hasAppointmentsListCache,
  setAppointmentsListCache,
  clearAppointmentsListCache,
  getCameraFirstAccess,
  setCameraFirstAccess,
}
