import { BaseResponseBack } from 'src/config/bridge/BaseService'
import {
  ICancellationFeeResponse,
  IPrices,
  ISubscription,
} from 'src/store/ducks/subscription/types'
import { IUser } from 'src/store/ducks/user/types'

export const holderMock = {
  name: 'Teste',
  patientId: 999999,
  birthdate: '24/11/2010',
  email: 'email@email.com.br',
  phone: '99 99999-99999',
  socialId: '99999999999',
  bankAccount: '999999',
  holderPatientId: 999999,
}

export const subscription: ISubscription = {
  subscriptionId: 'b65be0c0-04c7-4c08-8fa2-f4cc7f2fa204',
  patientId: 123456,
  socialId: '06764914052',
  name: 'Roberta Oliveira de Andrade',
  birthdate: '23/04/1980',
  email: 'carlos.silveira@email.com',
  phone: '27985152526',
  customerStatus: 'ACTIVE',
  subscriptionStatus: 'ACTIVE',
  paymentAmount: '29',
  paymentDueDate: '22/02/2022',
  hasFreeAppointment: false,
  termsAndConditions: {
    accepted: false,
    acceptanceDateTime: '',
  },
  creationDate: '20/06/2022 13:59:42',
}

export const responseActiveSubscription: BaseResponseBack<ISubscription> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    subscriptionId: 'b65be0c0-04c7-4c08-8fa2-f4cc7f2fa204',
    patientId: 123456,
    socialId: '06764914052',
    name: 'Roberta Oliveira de Andrade',
    birthdate: '23/04/1980',
    email: 'carlos.silveira@email.com',
    phone: '27985152526',
    customerStatus: 'ACTIVE',
    subscriptionStatus: 'ACTIVE',
    paymentAmount: '29',
    paymentDueDate: '22/02/2022',
    hasFreeAppointment: true,
    termsAndConditions: {
      accepted: false,
      acceptanceDateTime: '',
    },
    creationDate: '20/06/2022 13:59:42',
  },
}

export const responseBlockedSubscription: BaseResponseBack<ISubscription> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    subscriptionId: 'b65be0c0-04c7-4c08-8fa2-f4cc7f2fa204',
    patientId: 123456,
    socialId: '06764914052',
    name: 'Roberta Oliveira de Andrade',
    birthdate: '23/04/1980',
    email: 'carlos.silveira@email.com',
    phone: '27985152526',
    customerStatus: 'BLOCKED',
    subscriptionStatus: 'BLOCKED',
    paymentAmount: '29',
    paymentDueDate: '22/02/2022',
    hasFreeAppointment: true,
    termsAndConditions: {
      accepted: false,
      acceptanceDateTime: '',
    },
    creationDate: '20/06/2022 13:59:42',
  },
}

export const responsePrices: BaseResponseBack<IPrices> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    price: '4,9',
  },
}

export const responseCancellationFee: BaseResponseBack<ICancellationFeeResponse> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: {
    subscriptionCancellationFee: {
      hasCancellationFee: false,
      cancellationAmount: '11.11',
      withRefund: false,
    },
  },
}

export const responseUserInfo: BaseResponseBack<IUser> = {
  status: 200,
  dateTime: '2021-03-04T12:42:37.457Z',
  object: { name: 'string', email: 'string', birthDate: 'string' },
}

export const reasonsToHire = [
  {
    label: 'Fácil de buscar e agendar',
    value:
      'Escolha especialistas com quem quer agendar sua vídeoconsulta e qual o melhor horário pra você.',
  },
  {
    label: 'Lembrete da hora da consulta',
    value: 'Receba alertas por e-mail ou pelo app pra não perder seu horário.',
  },
  {
    label: 'Atestados, receitas e solicitações digitais',
    value: 'Receitas, pedidos de exame e emissão de atestados 100% digitais.',
  },
  {
    label: 'Videoconsulta com clínico geral',
    value: 'Videoconsulta de pronto atendimento com clínico geral, por apenas R$ 50,90.',
  },
  {
    label: 'Videoconsulta com especialista',
    value: 'Videoconsulta agendada com profissionais de saúde especializados, por apenas R$ 77,00.',
  },
  {
    label: 'Flexibilidade no pagamento',
    value: 'Escolher a melhor opção para pagar as suas consultas, com cartão de débito ou crédito.',
  },
]
