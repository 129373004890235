import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { H1, H3 } from 'src/styles/commons'
import { firstName } from 'src/utils/commons'

import { InterUIContainer, InterUISegmentControl } from '@interco/inter-ui-react-lib'

import { Emergency } from '../Emergency/Emergency'
import { Profile } from '../Profile/Profile'
import { Schedule } from '../Schedule/Schedule'

export const DrInter: React.FC = () => {
  const holder = useSelector(SubscriptionActions.getHolder)
  const selectedTab = useSelector(TabActions.get)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_DC_ACESSO.name, {
      tela: 'Home',
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }, [])

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_OPCOES.name, {
      content_id: selectedTab,
    })
  }, [selectedTab])

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    backNavigationHandler: BaseBridge.requestGoBack,
    hideHomeBtn: false,
  })

  return (
    <InterUIContainer margin="24px 0 32px">
      <H1 margin="0 24px 8px">Olá, {firstName(holder.name)}</H1>
      <H3 scale={400} margin="0 24px">
        Como podemos te ajudar hoje?
      </H3>
      <InterUISegmentControl variant="witchIcon" selectedLabel={selectedTab} margin="24px">
        <Emergency data-label="Clínica" />
        <Schedule data-label="Agenda" />
        <Profile data-label="Perfil" />
      </InterUISegmentControl>
    </InterUIContainer>
  )
}
