/**
 * Condição atual da contratação.
 */
export enum SubscriptionStatusEnum {
  ATIVO = 'ACTIVE',
  INATIVO = 'INACTIVE',
  BLOQUEADO = 'BLOCKED',
  ERRO_PAGAMENTO = 'ENROLLMENT_FEE_ERROR',
  ERRO_ATIVACAO = 'ACTIVATION_ERROR',
  PENDENTE = 'PENDING',
  PROCESSANDO = 'PROCESSING',
}
