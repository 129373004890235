import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { MedicinesTypes, IMedicine, IMedicineFields, ISearchMedicineRequest } from './types'

const getMedicinesListRequest = (data: ISearchMedicineRequest | null) =>
  action(MedicinesTypes.GET_MEDICINES_LIST_REQUEST, data)

const getMedicinesList = (state: RootState) => state.medicines.medicinesList

const setMedicinesList = (data: IMedicineFields[]) =>
  action(MedicinesTypes.SET_MEDICINES_LIST, data)

const getSelectedMedicines = (state: RootState) => state.medicines.selectedMedicines

const setSelectedMedicines = (data: IMedicine[]) =>
  action(MedicinesTypes.SET_SELECTED_MEDICINES, data)

export const MedicinesActions = {
  getMedicinesListRequest,
  getMedicinesList,
  setMedicinesList,
  getSelectedMedicines,
  setSelectedMedicines,
}
