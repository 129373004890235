import { AnyAction } from 'redux'
import EmergencyCareService from 'src/services/EmergencyCareService'
import { call, put, takeLatest } from 'typed-redux-saga'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'

import { SymptomsActions } from './actions'
import { SymptomsTypes } from './types'

function* getSymptomsList({ payload }: AnyAction) {
  try {
    yield* put(SymptomsActions.setSelectedSymptoms([]))
    yield* put(SymptomsActions.setSelectedOtherSymptoms(''))

    const response = yield* call(EmergencyCareService.getSymptomsList)

    yield* put(SymptomsActions.setSymptomsList(response.object.symptoms))

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'SymptomsActions.getSymptomsList')
  }
}

export default function* watchSymptoms() {
  yield* takeLatest(SymptomsTypes.GET_SYMPTOMS_LIST_REQUEST, getSymptomsList)
}
