import { DefaultTheme } from 'styled-components'
import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { ThemeTypes } from './types'

const getTheme = (state: RootState) => state.theme.theme

const setTheme = (data: DefaultTheme) => action(ThemeTypes.SET_THEME, data)

export const ThemeActions = {
  getTheme,
  setTheme,
}
