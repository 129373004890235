import React from 'react'

import { InterUIContainer, InterUISeparator, InterUISkeleton } from '@interco/inter-ui-react-lib'

import { LoadListDescription } from '../components/LoadListDescription/LoadListDescription'
import { SkeletonBox, SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export const LoadHome: React.FC = () => (
  <SkeletonContent data-testid="loading-home">
    <InterUIContainer margin="24px">
      <SkeletonContent>
        <InterUISkeleton height="30px" marginBottom="8px" />
        <InterUISkeleton height="24px" marginBottom="24px" />
      </SkeletonContent>

      <SkeletonBox>
        <SkeletonGrid>
          <SkeletonGrid>
            <InterUISkeleton width="16px" height="16px" marginBottom="0" variant="circle" />
            <InterUISkeleton height="24px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>
          <SkeletonGrid>
            <InterUISkeleton width="16px" height="16px" marginBottom="0" variant="circle" />
            <InterUISkeleton height="24px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>
          <SkeletonGrid>
            <InterUISkeleton width="16px" height="16px" marginBottom="0" variant="circle" />
            <InterUISkeleton height="24px" marginBottom="0" style={{ flex: 1 }} />
          </SkeletonGrid>
        </SkeletonGrid>
      </SkeletonBox>
    </InterUIContainer>

    <InterUIContainer margin="0 24px">
      <SkeletonContent>
        <InterUISkeleton height="24px" marginBottom="8px" />
        <InterUISkeleton height="48px" marginBottom="16px" />
      </SkeletonContent>

      <SkeletonBox>
        <SkeletonGrid>
          <InterUISkeleton width="24px" height="24px" marginBottom="0" />
          <SkeletonContent>
            <InterUISkeleton height="32px" marginBottom="4px" />
            <InterUISkeleton marginBottom="4px" />
            <InterUISkeleton width="60%" marginBottom="0" />
          </SkeletonContent>
          <InterUISkeleton width="24px" height="24px" marginBottom="0" />
        </SkeletonGrid>
      </SkeletonBox>
    </InterUIContainer>

    <InterUISeparator height="8px" margin="32px 0" />

    <InterUIContainer margin="24px">
      <SkeletonContent>
        <InterUISkeleton height="24px" marginBottom="8px" />
        <InterUISkeleton height="32px" marginBottom="16px" />
      </SkeletonContent>

      <LoadListDescription />
      <LoadListDescription />
      <LoadListDescription />
    </InterUIContainer>
  </SkeletonContent>
)
