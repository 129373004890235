import { Reducer } from 'redux'

import { SpecialtiesState, SpecialtiesTypes } from './types'

const INITIAL_STATE: SpecialtiesState = {
  highlightsList: [],
  spontaneousDemandsList: [],
  specialtiesList: [],
}

const reducer: Reducer<SpecialtiesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SpecialtiesTypes.SET_HIGHLIGHTS_LIST:
      return { ...state, highlightsList: action.payload }
    case SpecialtiesTypes.SET_SPONTANEOUS_DEMANDS_LIST:
      return { ...state, spontaneousDemandsList: action.payload }
    case SpecialtiesTypes.SET_SPECIALTIES_LIST:
      return { ...state, specialtiesList: action.payload }

    default:
      return state
  }
}

export default reducer
