import { AnyAction } from 'redux'
import DocumentService from 'src/services/DocumentService'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks//utils/providerSaga'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { call, put, takeLatest } from 'typed-redux-saga'

import { DocumentActions } from './action'
import { DocumentTypes } from './types'

function* getProductManualPDFFile() {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(DocumentService.getProductManualPDF)

    yield* put(DocumentActions.setProductManualPDF(response.object))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DocumentActionsSaga.getProductManualPDFFile')
  }
}

function* getTermsAndConditionsPDFFile() {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(DocumentService.getTermsAndConditionsPDF)

    yield* put(DocumentActions.setTermsAndConditionsPDF(response.object))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DocumentActionsSaga.getTermsAndConditionsPDFFile')
  }
}

function* getPrivacyPolicyPDFFile() {
  try {
    yield* put(LoadingActions.show())

    const response = yield* call(DocumentService.getPrivacyPolicyPDF)

    yield* put(DocumentActions.setPrivacyPolicyPDF(response.object))

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DocumentActionsSaga.getPrivacyPolicyPDFFile')
  }
}

function* redirectToDocument({ payload }: AnyAction) {
  try {
    yield* put(DocumentActions.deleteDocumentPDF())

    redirectThroughSaga(payload)
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'DocumentActionsSaga.redirectToDocument')
  }
}

export default function* watchDocuments() {
  yield* takeLatest(DocumentTypes.GET_PRODUCT_MANUAL_PDF, getProductManualPDFFile)
  yield* takeLatest(DocumentTypes.GET_TERMS_AND_CONDITIONS_PDF, getTermsAndConditionsPDFFile)
  yield* takeLatest(DocumentTypes.GET_PRIVACY_POLICY_PDF, getPrivacyPolicyPDFFile)
  yield* takeLatest(DocumentTypes.SET_REDIRECT_TO_DOCUMENT, redirectToDocument)
}
