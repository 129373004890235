import React, { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { PMedium, PSmall } from 'src/styles/commons'
import { extractBase64Content, generateId } from 'src/utils/commons'

import { InterUIBottomSheet, InterUIFileInput } from '@interco/inter-ui-react-lib'

import { Button } from '..'

import { AttachFileButton } from './AttachmentBS.styles'

interface AttachmentBSProps {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
  setBottomSheetError: Dispatch<SetStateAction<boolean>>
  setBottomSheetErrorMsg: Dispatch<SetStateAction<Error | undefined>>
  setShowCamera: Dispatch<SetStateAction<boolean>>
}

export const AttachmentBS: React.FC<AttachmentBSProps> = ({
  toggleState,
  setBottomSheetError,
  setBottomSheetErrorMsg,
  setShowCamera,
}) => {
  const TEN_MB_IN_BYTES = 10485760
  const COMPRESS = true

  const dispatch = useDispatch()

  const [toggle, setToggle] = toggleState

  const selectedFilesAttached = useSelector(FilesActions.getSelectedFilesAttached)

  const goToCamera = () => {
    setToggle(false)
    setShowCamera(true)
  }

  const onAttach = (e: ChangeEvent<HTMLInputElement>, base64: string | undefined) => {
    setToggle(false)
    if (e?.target?.files && e.target.files.length > 0) {
      const { name } = e.target.files[0]
      const extension = e.target.files[0].type

      const fileInfo = {
        id: generateId(),
        name,
        fileExtension: extension.split('/')[1],
        fileContent: extractBase64Content(base64) || '',
      }

      const attachedFiles = Object.assign([], selectedFilesAttached.files)
      const tempFiles = [...attachedFiles, fileInfo]

      dispatch(FilesActions.setSelectedFilesAttached({ files: tempFiles }))
    }
  }

  return (
    <InterUIBottomSheet
      title="Escolha como enviar o documento"
      toggle={toggle}
      onHide={() => setToggle(false)}
      data-testid="attachment-BS"
    >
      <PSmall scale={400} marginBottom="0">
        Você pode tirar fotos do documento ou buscar os arquivos no seu celular, se tiver imagens ou
        PDF do documento armazenados.
      </PSmall>
      <Button margin="32px 0 0" onClick={goToCamera}>
        Tirar foto
      </Button>
      <InterUIFileInput
        onAttach={onAttach}
        clearValue
        hasCompressor={COMPRESS}
        limitFileSize={COMPRESS}
        sizeLimitValue={TEN_MB_IN_BYTES}
        onError={(error) => {
          setToggle(false)
          setTimeout(() => {
            setBottomSheetError(true)
            setBottomSheetErrorMsg(error)
          }, 600)
        }}
      >
        <AttachFileButton>
          <PMedium margin="8px" style={{ fontWeight: 700 }}>
            Buscar arquivo no celular
          </PMedium>
        </AttachFileButton>
      </InterUIFileInput>
    </InterUIBottomSheet>
  )
}
