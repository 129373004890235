import { action } from 'typesafe-actions'
import { RootState } from 'src/store'
import { SagaWithHistory } from 'src/store/ducks/utils/providerSaga'

import { ISymptoms, SymptomsTypes } from './types'

const getSymptomsListRequest = (data?: SagaWithHistory) =>
  action(SymptomsTypes.GET_SYMPTOMS_LIST_REQUEST, data)

const getSymptomsList = (state: RootState) => state.symptoms.symptomsList

const setSymptomsList = (data: ISymptoms[]) => action(SymptomsTypes.SET_SYMPTOMS_LIST, data)

const getSelectedSymptoms = (state: RootState) => state.symptoms.selectedSymptoms

const setSelectedSymptoms = (data: ISymptoms[]) => action(SymptomsTypes.SET_SELECTED_SYMPTOMS, data)

const getSelectedOtherSymptoms = (state: RootState) => state.symptoms.selectedOtherSymptoms

const setSelectedOtherSymptoms = (data: string) =>
  action(SymptomsTypes.SET_OTHER_SELECTED_SYMPTOMS, data)

export const SymptomsActions = {
  getSymptomsListRequest,
  getSymptomsList,
  setSymptomsList,
  getSelectedSymptoms,
  setSelectedSymptoms,
  getSelectedOtherSymptoms,
  setSelectedOtherSymptoms,
}
