import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H1, PLarge, PMedium, PSmall, SuccessSign } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIBottomSheet, InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

import { ContentHelp, ContentTextValue } from './SubscriptionSummary.styles'

export const SubscriptionSummary: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()

  const prices = useSelector(SubscriptionActions.getPrices)
  const holder = useSelector(SubscriptionActions.getHolder)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  const iconHelp = (
    <Icons.Help
      width={16}
      height={16}
      color={theme.colors.primary.A500}
      onClick={() => setHelpBS(true)}
      data-testid="icon-help-bs"
    />
  )

  const [totalValue, setTotalValue] = useState(0)
  const [helpBS, setHelpBS] = useState(false)

  useEffect(() => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_COMPROVANTE_DC_SUCESSO.name, {
      valor: totalValue.toString(),
      utm_campaign: utmCampaign || 'SEM_CAMPANHA',
    })
  }, [totalValue])

  useEffect(() => {
    setTotalValue(Number(prices.price))
  }, [prices])

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    hideHomeBtn: false,
    hideBackNavigationBtn: true,
  })

  const goToHome = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_COMPROVANTE_T_OPCAO.name, {
      content_name: 'Home do Doutor Inter',
    })
    history.push(TypesRoutes.START)
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button variant="primary" margin="0 0 16px" onClick={goToHome}>
        Home do Doutor Inter
      </Button>

      {false && <Button variant="secondary">Compartilhar</Button>}
    </InterUIContainer>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="40px 24px 28px">
        <SuccessSign>{successIcon}</SuccessSign>
        <H1 margin="40px 0 8px">Sua contratação foi concluída com sucesso!</H1>
        <PMedium scale={400} marginBottom="0">
          Você já pode agendar consultas ou solicitar atendimento agora mesmo! Ah, não esqueça: a
          primeira consulta no pronto atendimento é por nossa conta!
        </PMedium>
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px">
        <PSmall textAlign="center" scale={400} marginBottom="4px">
          Valor total pago
        </PSmall>
        <H1 textAlign="center">{numberFormat(totalValue)} / mês</H1>
        <ContentHelp>
          <PSmall marginBottom="0" scale={400}>
            Entenda o pagamento recorrente
          </PSmall>
          {iconHelp}
        </ContentHelp>

        <InterUISeparator variant="small" />

        <PLarge margin="24px 0 0" bold>
          Titular
        </PLarge>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            {holder.name}
          </PSmall>
          <PSmall bold marginBottom="0px">
            {numberFormat(Number(prices.price))}
          </PSmall>
        </ContentTextValue>
      </InterUIContainer>

      <InterUISeparator />

      <InterUIBottomSheet
        data-testid="help-bottom-sheet"
        toggle={helpBS}
        onHide={() => setHelpBS(false)}
        title="Pagamento recorrente"
      >
        <PMedium scale={400} marginBottom="24px">
          Independente da forma de pagamento escolhida, a cobrança será feita mensalmente de acordo
          com o valor total da sua assinatura.
        </PMedium>

        <Button variant="primary" onClick={() => setHelpBS(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </InterUIContainer>
  )
}
