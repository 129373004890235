import React from 'react'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

import { LoadScheduleCard } from '../components/LoadScheduleCard/LoadScheduleCard'
import { SkeletonContent, SkeletonGrid } from '../Skeleton.styles'

export const LoadSchedule: React.FC = () => (
  <SkeletonContent data-testid="loading-schedule">
    <SkeletonGrid>
      <InterUISkeleton width="20%" height="32px" marginBottom="24px" />
      <InterUISkeleton width="40%" height="32px" marginBottom="24px" />
      <InterUISkeleton width="20%" height="32px" marginBottom="24px" />
      <InterUISkeleton width="20%" height="32px" marginBottom="24px" />
    </SkeletonGrid>
    <SkeletonContent>
      <LoadScheduleCard />
      <LoadScheduleCard />
      <LoadScheduleCard />
    </SkeletonContent>
  </SkeletonContent>
)
