import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ListBenefits } from 'src/components'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { firstName, invertDate } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import {
  InterUIBox,
  InterUIContainer,
  InterUIList,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const MySubscriptionDetails: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const subscription = useSelector(SubscriptionActions.getSubscription)
  const holder = useSelector(SubscriptionActions.getHolder)
  const isLoading = useSelector(LoadingActions.get)

  const payDay = new Date(invertDate(subscription.paymentDueDate)).getDate()

  useBasePage({
    navbarTitle: PageTitles.MY_SUBSCRIPTION_DATA,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const goToCancellation = () => {
    dispatch(
      SubscriptionActions.getCancellationFeeRequest({
        history,
        pathname: TypesRoutes.CANCELLATION_SUBSCRIPTION,
      }),
    )
  }

  const stickyFooter = (
    <Button onClick={goToCancellation} variant="primary" disabled={isLoading}>
      Cancelar minha assinatura
    </Button>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H2 marginBottom="8px">Olá, {firstName(holder.name)}!</H2>
      <PMedium scale={400} marginBottom="24px">
        Esses são os dados da sua assinatura:
      </PMedium>

      <InterUIBox margin="0 0 24px" padding="16px" direction="column" align="stretch">
        <InterUIList
          margin="0 0 16px"
          value={
            <PSmall id={subscription.name} bold marginBottom="0px">
              {subscription.name}
            </PSmall>
          }
          text={
            <PSmall scale={400} marginBottom="0px">
              Nome completo
            </PSmall>
          }
        />
        <InterUIList
          margin="0 0 16px"
          value={
            <PSmall id={subscription.paymentAmount} bold marginBottom="0px">
              {subscription.paymentAmount}
            </PSmall>
          }
          text={
            <PSmall scale={400} marginBottom="0px">
              Valor total da assinatura
            </PSmall>
          }
        />
        <InterUIList
          margin="0 0 16px"
          value={
            <PSmall id={subscription.paymentDueDate} bold marginBottom="0px">
              {subscription.paymentDueDate && `dia ${payDay}`}
            </PSmall>
          }
          text={
            <PSmall scale={400} marginBottom="0px">
              Data de cobrança
            </PSmall>
          }
        />
        <InterUIList
          value={
            <PSmall style={{ color: theme.colors.feedback.success.A500 }} bold marginBottom="0px">
              {subscription.customerStatus === 'ACTIVE' && 'Ativo'}
            </PSmall>
          }
          text={
            <PSmall scale={400} marginBottom="0px">
              Status da assinatura
            </PSmall>
          }
        />
      </InterUIBox>

      <InterUISeparator variant="small" />

      <H2 margin="24px 0 16px" bold>
        Você tem acesso aos benefícios
      </H2>

      <ListBenefits />
    </InterUIContainer>
  )
}
