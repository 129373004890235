import React, { useEffect, useLayoutEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'

import { Error } from './components/Error/Error'
import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ThemesEnum } from './enums/ThemesEnum'
import Routes from './routes'
import { ErrorActions } from './store/ducks/error/actions'
import { ThemeActions } from './store/ducks/theme/actions'

const App: React.FC = () => {
  const dispatch = useDispatch()

  InterUIFonts()

  const theme = useSelector(ThemeActions.getTheme)

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  const chooseTheme = () => {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: false,
    }

    BaseBridge.getAppInfo()
      .then((response) => {
        const isDarkMode = (response as unknown as { isDarkMode: boolean })?.isDarkMode

        isDarkMode
          ? dispatch(ThemeActions.setTheme(InterTheme(ThemesEnum.DARK)))
          : dispatch(ThemeActions.setTheme(InterTheme(ThemesEnum.LIGHT)))
      })
      .catch(() => dispatch(ErrorActions.show(errorDetails)))
  }

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <BrowserRouter>
          <Error />
          <Navbar />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )
  )
}

export default App
